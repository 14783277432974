<template>
  <div>
    <el-button class="route_style" @click="toVideoIndex()" type="warning" icon="el-icon-search" circle plain>
    </el-button>
    <el-autocomplete class="inline-input search_style" v-model="state" placeholder="请输入"
      :fetch-suggestions="querySearch" @select="handleSelect">
      <el-select class="select_style" v-model="select" slot="prepend" placeholder="请选择" @change="serach_item_change">
        <el-option label="影人" value="1"></el-option>
      </el-select>
    </el-autocomplete>

    <div class="videos" style="width: 96vw; margin: 0 auto; margin-top: 0vh">
      <svg id="videosByTime" height="100%"></svg>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import * as $ from "jQuery";

export default {
  data() {
    return {
      num: 0,
      tableData: [],
      maxYear: 2030,
      minYear: 1920,
      allData: [],
      figures: [],
      // 搜索框
      select: "1",
      state: '阮玲玉',
      window_width: window.screen.width,
    }
  },
  methods: {

    toVideoIndex() {
      this.$router.push({
        path: '/video_index'
      })
    },

    serach_item_change() {
    },

    getMaxMinYear(data) {
      for (let i = 0; i < this.tableData.length; i++) {
        data[i].year = data[i].year.substring(0, 4);
      }
      data.sort(function(a, b) { return Number(b.year) - Number(a.year) });
      this.maxYear = Number(data[0].year);
      this.minYear = Number(data[data.length-1].year);
    },

    handleSelect(figure) {
      let nowf;
      if (typeof(figure.value) == 'undefined') {
        nowf = figure;
      } else {
        nowf = figure.value;
      }
      
      this.tableData.length = 0;
      for (let i = 0; i < this.num; i++) {
        if (
          (this.allData[i].director && this.allData[i].director?.indexOf(nowf) != -1) ||
          (this.allData[i].actor && this.allData[i].actor?.indexOf(nowf) != -1) ||
          (this.allData[i].script && this.allData[i].script?.indexOf(nowf) != -1)
        ) {
          this.tableData.push(this.allData[i]);
        }
      }
      this.$set(this.tableData);
      if (this.tableData.length != 0)
        this.getMaxMinYear(this.tableData);
      this.initVideoCircle();
    },

    querySearch(queryString, cb) {
        var Allfigures = this.figures;
        let results = []
        for (var i = 0; i < Allfigures.length; i++) {
          if (Allfigures[i].includes(queryString.trim().toLowerCase())) {
            results.push({ value: Allfigures[i] });
          }
        }
        cb(results);
    },

    getInfo() {
      this.axios
        .get("https://api.movie.yingshinet.com/movies")
        .then((res) => {
          let work = res.data.m_data;
          this.num = res.data.m_count;

          for (var i = 0; i < this.num; i++) {
            this.allData[i] = {};
            this.allData[i].no = work[i].mid;
            this.allData[i].name = work[i].m_name;
            this.allData[i].pic = work[i].m_pic || 'https://assets.yingshinet.com/chronicles/pictures/MOVIE_27.jpg';
            this.allData[i].director = work[i].m_director;
            this.allData[i].actor = work[i].m_actor;
            // this.allData[i].year = parseInt(work[i].m_year.slice(0, 4));
            this.allData[i].year = work[i].m_year;
            this.allData[i].script = work[i].m_script;
            this.allData[i].movie_type = work[i].movie_type;
            if (work[i].douban_rank === null) {
              this.allData[i].rank = 0
            } else {
              this.allData[i].rank = work[i].douban_rank;
            }
          }
          this.$set(this.allData);
          this.tableData = this.allData.concat()
          this.$set(this.tableData);
          this.handleSelect('阮玲玉')
        })
        .catch((error) => {
          console.error(error);
        });
      
      // 获取人物名
      this.axios.get("https://api.movie.yingshinet.com/figures").then((response) => {
        for (var i = 0; i < response.data.f_data.length; i++) {
          this.figures.push(response.data.f_data[i].f_name);
        }
      });
    },

    initVideoCircle() {
      this.drawD3();
      this.$nextTick(() => {
        document.querySelectorAll(".img").forEach((item) => {
          item.addEventListener("click", () => {
            let id = item.id;
            this.$router.push("/video?id=" + id);
          });
        });
      });
    },

    drawD3() {
      let width = $(".videos").width() * 0.98;
      let height = $(".videos").height() * 0.98;
      let svg = d3.select("#videosByTime")
                  .attr("width", width)
                  .attr("height", height);
      // 每次调用前删除之前部分
      svg.selectAll("*").remove();

      //提示框部分
		  let tooltip = d3.select("body")
                      .append("div")
                      .attr("class", "tips")
                      .style("opacity", 0.0)
                      .style("position", "absolute")
                      .style("width", 20)
                      .style("height", "auto")
                      .style("border-style", "solid")
                      .style("border-width", "1px")
                      .style("background-color", "white")
                      .style("border-radius", "5px")
                      .style("font-family", "simsun")
                      .style("font-size", "14px")
                      .style("text-align", "center")
                      .style("line-height", "1.3em");

      // 豆瓣评分作为y轴的比例尺
      let xScale, yScale;
      if (this.window_width > 768) {
        xScale = d3.scaleLinear().range([25, width-25]).domain([this.minYear-10, this.maxYear+10]);
        yScale = d3.scalePow().exponent(4);
      } else {
        xScale = d3.scaleLinear().range([25, width-25]).domain([this.minYear-5, this.maxYear+5]);
        yScale = d3.scalePow().exponent(3.9);
      }
      let xAxis = svg.append('g')
                     .attr('class', 'xAxis')
                     .attr('transform', `translate(0, ${height - 40})`)
                     .attr('color', "#F0EBE1")
                     .call(d3.axisBottom(xScale).ticks(20).tickFormat(x => `${x.toFixed(0)}`));
      if (this.window_width <= 768) {
        xAxis.call(g => {
                g.selectAll("text")
                 .attr("transform", "rotate(-45)")
                 .style("text-anchor", "end");
             });
      }

      let lines = svg.append('g')
                      .attr("class", "lines")
                      .selectAll(".lines")
                      .data(this.tableData)
                      .enter()
                      .append("line")
                      .attr("x1", (d) => {
                        return xScale(d.year);
                      })
                      .attr("y1", height-40)
                      .attr("x2", (d) => {
                        return xScale(d.year);
                      })
                      .attr("y2", (d) => {
                        return height - (yScale(d.rank) * 0.066 + 86);
                      })
                      .attr("stroke", "#F0EBE1")
                      .attr("stroke-width", 0.6)
                      .attr("opacity", 0.8);

      let img_h = this.window_width > 768? 60 : 40;
      let img_w = this.window_width > 768? 60 : 40;
      let radius= this.window_width > 768? 23 : 15;
      let circles = svg.selectAll(".img")
                       .data(this.tableData)
                       .enter()
                       .append("circle")
                       .attr("class","img")
                       .attr("id", (d) => {
                          return d.no;
                       })
                       .attr("r",radius)
                       .attr("cx", (d, i) => {
                          return xScale(d.year);
                       })
                       .attr("cy", (d) => {
                          return height - (yScale(d.rank) * 0.066 + 86);
                       })
                       .style("stroke", "#F0EBE1")
                       .style("stroke-width", "1.0px")
                       .attr("fill", (d, i) => {
                          //创建圆形图片
                          var defs = svg.append("defs").attr("id", "imgdefs");
                          var catpattern = defs.append("pattern")
                                          .attr("id", "catpattern" + i)
                                          .attr("height", 1)
                                          .attr("width", 1);
                          catpattern.append("image")
                                    .attr("x", - (img_w / 2 - radius + 5.8))
                                    .attr("y", - (img_h / 2 - radius + 3.5))
                                    .attr("width", img_w + 11)
                                    .attr("height", img_h + 6)
                                    .attr("xlink:href", d.pic);
                          return "url(#catpattern" + i + ")";
                       })
                       .on("mouseover", (d,i) => {
                          tooltip.html(i.name + "</br>" + "上映时间：" + i.year + "年" + "</br>" + "导演：" + i.director + "</br>" + "豆瓣评分：" + (i.rank))
                            .style("left", d.pageX + "px")
                            .style("top", (d.pageY + 20) + "px")
                            .style("opacity", 1.0);
                          d3.select(this)
                            .style("cursor", "pointer");
                       })
                       .on("mousemove", (d) => {
                          tooltip.style("left", d.pageX + "px")
                                 .style("top", (d.pageY + 20) + "px");
                       })
                       .on("mouseout", (d) => {
                          tooltip.style("opacity", 0.0);
                       })
                       .on("click", (d) => {
                          tooltip.style("opacity", 0.0);
                       });
    }
  },
  mounted() {
    this.getInfo();
  }
}
</script>

<style scoped>
.route_style {
  position: absolute;
  left: 1.5vw;
  margin-left: 90%;
  transform: translateY(2vh);
  z-index: 1;
}
.search_style {
  z-index: 1;
  width: 18vw;
  border-radius: 1vw;
  margin-left: 72%;
  margin-top: 2vh;
}
.select_style {
  width: 6vw;
}
.videos {
  height: 52vw;
}

@media screen and (max-width: 768px) {
  .route_style {
    margin-left: 75%;
  }
  .search_style {
    width: 30vh;
    margin-left: 15%;
  }
  .select_style {
    width: 12vh;
  }
  .videos {
    height: 80vh;
  }
}
</style>