<template>
  <div style="width:100%; float: left; padding:0 ">
    <!-- <div class="el_card" slot="header" style="height: 60px">
      <el-input class="e-input" placeholder="搜索posts(暂时不可用)" v-model="post_search" style="width: 80%; margin: 10px 0px">
      </el-input>
    </div> -->
    <el-card
      style="height: 100%;"
      class="card_shadow">
      <!-- 标签列表 -->
      <div class="wrapper">
        <!-- 简介 -->
        <div class="text_color_current mouse_over_head wrapper-content" v-for="(terms, index) in all_terms_data">
          <div style="display:inline-block" class="content1" @click="termClick(terms.terms)">
            {{ terms.terms }}：
          </div>
          <div class="content2" style="display:inline-block" @click="termClick(terms.terms)">{{ terms.count }}</div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>import service from "../../utils/request";

export default {
  name: 'DirectorInfo',
  props: {
    'all_terms': Array,
    'spouse': String,
  },
  data() {
    return {
      all_terms_data: this.all_terms,
      terms: [],
      dialogEditVisible: false,
      //判断是否是影人信息
      dialogCheck: false,
      //非影人信息的编辑按钮
      nonpeopleEdit: false,
      edit_dir_name: '',
      edit_birth_time: '',
      edit_brief: '',
      edit_dir_sex: '',
      edit_direction: '',
      edit_spouse: '',
      fileList: [],
      limit_num: 1,
      img_file: [],
      make_sure: false,
      //是否是影人信息字段
      isfigure: '',
    };
  },
  watch: {
    all_terms: function (val) {
      this.all_terms_data = val
    }
  },
  methods: {
    // 标签点击
    termClick(term) {
      // this.$store.commit('set_current_term', term)
      // history.pushState(null, null, 'https://movie.yingshinet.com/#/post?=' + term)
      // history.pushState(null, null, 'http://localhost:8080/#/post?=' + term)
      this.$router.push('/post?name=' + term)
      // this.axios
      //   .post(this.path + "/termsearch", { term_name: term })
      //   .then((response) => {
      //     console.log(response)
      //     this.all_terms_search = response.data.all_terms_search;
      //   });
    },
    //非影人编辑
    edit_movie_click() {
      this.isfigure = 0
      this.dialogCheck = false
      this.nonpeopleEdit = true
      this.edit_dir_name = this.dir_name
      this.edit_birth_time = this.birth_time
      this.edit_brief = this.brief
      this.edit_direction = this.direction
      this.edit_spouse = this.spouse
    },
    //影人编辑
    edit_click() {
      this.isfigure = 1
      this.dialogCheck = false
      this.dialogEditVisible = true
      this.edit_dir_name = this.dir_name
      this.edit_birth_time = this.birth_time
      this.edit_dir_sex = this.dir_sex
      this.edit_brief = this.brief
      this.edit_direction = this.direction
      this.edit_spouse = this.spouse
    },
    //影人编辑
    edit_director() {
      this.make_sure = true
    },
    //影人编辑最终确认
    make_sure_click() {
      this.make_sure = false
      this.dialogEditVisible = false
      this.nonpeopleEdit = false
      //信息上传
      service({
        url: '/figures/update',
        method: 'post',
        data: {
          'fid': this.dir_id,
          'now_birth_time': this.edit_birth_time,
          'now_sex': this.edit_dir_sex,
          'now_f_name': this.edit_dir_name,
          'now_f_work': this.edit_direction,
          'now_f_brief': this.edit_brief,
          'now_spouse': this.edit_spouse,
          'isfigure': this.isfigure
        }
      }).then((response) => {
        if (response.status == '200') {
          this.dir_name = this.edit_dir_name
          this.brief = this.edit_brief
          this.direction = this.edit_direction
          this.birth_time = this.edit_birth_time
          this.spouse = this.edit_spouse
          this.$message({
            message: '基本信息修改成功！',
            type: 'success'
          });
        } else {
          this.$message({
            message: '基本信息修改好像出问题了！',
            type: 'warning'
          });
        }
      })
      //图片上传
      if (this.img_file.length == 1) {
        let file = this.img_file[0].raw
        let param = new FormData() //form对象
        let config = {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
        param.append('file', file)
        this.axios
          .post('https://api.movie.yingshinet.com/figures/pic/' + this.dir_id, param, config)
          .then((response) => {
            if (response.status == '200') {
              this.$message({
                message: '影人照片修改成功！',
                type: 'success',
              });
              location.reload();
            } else {
              this.$message({
                message: '影人照片好像出了点问题',
                type: 'warning'
              });
            }
          })
      }
    },
    //手动上传
    submitUpload() {
      this.$refs.upload.submit();
    },
    //上传图片钩子函数
    handleRemove(file, fileList) {
      this.img_file.length = 0
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleChange(file, fileList) {
      this.img_file.push(file)
      console.log(this.img_file, fileList);
    },
  },
  created() {
    for (var i = 0; i < this.all_terms_data.length; i++) {
      this.terms[i] = {};
      this.terms[i] = this.all_terms_data[i]
      // console.log(this.terms[i].terms)
    }
    this.$set(this.all_terms_data);
  }
};
</script>

<style>
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}

.text_color_current {
  font-size: 18px;
  color: #F0EBE1;
}

.mouse_over_head:hover {
  color: #b8860b;
  cursor: pointer;
}

.mouse_over_work:hover {
  cursor: pointer;
}

.title_style {
  list-style-type: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}

.el-dia .el-dialog__body {
  padding: 15px 40px 10px 40px;
}

.el-dia .el-form-item {
  margin-bottom: 10px;
}

.card_shadow {
  box-shadow: 3px 4px 1px -2px rgb(0 0 0 /16%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}

.wrapper {
  overflow-y: scroll;
  width: 100%;
  height: 77vh;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.wrapper-content {
  width: 20%;
  margin-bottom: 5px;
}

.content1 {
  float: left;
  width: 50%;
}

.content2 {
  float: left;
  width: 10%;
}
</style>
<style scoped>
.el-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(0, 0, 0, 0.5);
  border-width: 2px;
  border-style: solid;
}
</style>