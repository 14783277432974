<template>
  <div
    :class="this.$store.state.isCollapse ? 'container_collapse' : 'container'"
  >
  <el-container v-if="scenedata">
    <el-row>
      <el-col
        :xs="24"
        :sm="18"
        :md="17"
        :lg="24"
        :xl="18"
        style="padding-left: 1%; padding-right: 1%; padding-top: 1%"
      >
        <el-row>
        <div class="video-container">
          <div class="video1">
            <el-col id="video1" style="">
              <el-row style="margin-top: 1vh;">
                <el-col style="width: 43%">
                  <h1 style="display: inline-block; float: right; text-align: center; color: #F0EBE1;">{{moviename}}</h1>
                </el-col>
                <el-col style="width: 37%">
                  <el-button style="display: inline-block; margin-left: 5vw; margin-top: 1vh; text-align: center;" icon="el-icon-download"  @click="downloadSubtitle()"  type="warning" plain>点击下载字幕</el-button>
                </el-col>
              </el-row>
                <el-row>
                  <video-player
                    id="player"
                    class="video-player vjs-custom-skin"
                    ref="VideoPlayer"
                    :playsinline="true"
                    :options="playerOptions"
                    @play="onPlayerPlay($event)"
                    @pause="onPlayerPause($event)"
                    @ended="onPlayerEnded($event)"
                    @waiting="onPlayerWaiting($event)"
                    @playing="onPlayerPlaying($event)"
                    @loadeddata="onPlayerLoadeddata($event)"
                    @timeupdate="onPlayerTimeupdate($event)"
                    @canplay="onPlayerCanplay($event)"
                    @canplaythrough="onPlayerCanplaythrough($event)"
                    @statechanged="playerStateChanged($event)"
                    @ready="playerReadied"
                    controls
                    autoplay
                  ></video-player>
                </el-row>
            </el-col>
          </div>
          <div class="video2">
            <el-col id="video2" style="">

              <el-row style="margin-top: 1vh">
                <el-col style="width: 57%">
                  <h1 style="display: inline-block; float: right; text-align: center; color: #F0EBE1;">Cinenmetrics的使用方法与流程</h1>
                </el-col>
                <el-col style="width: 43%">
                  <el-button style="display: inline-block; margin-left: 5vw; margin-top: 1vh; text-align: center;" icon="el-icon-download"  @click="downloadList()" type="warning" plain>点击下载手册</el-button>
                </el-col>
              </el-row>
              <el-row style=";">
                <video-player
                  id="player2"
                  class="video-player vjs-custom-skin"
                  ref="VideoPlayer"
                  :playsinline="true"
                  :options="playerOptions1"
                  @play="onPlayerPlay($event)"
                  @pause="onPlayerPause($event)"
                  @ended="onPlayerEnded($event)"
                  @waiting="onPlayerWaiting($event)"
                  @playing="onPlayerPlaying($event)"
                  @loadeddata="onPlayerLoadeddata($event)"
                  @timeupdate="onPlayerTimeupdate($event)"
                  @canplay="onPlayerCanplay($event)"
                  @canplaythrough="onPlayerCanplaythrough($event)"
                  @statechanged="playerStateChanged($event)"
                  @ready="playerReadied"
                  controls
                  autoplay
                ></video-player>
              </el-row>


            </el-col>
          </div>
        </div>
          <!-- <el-row>
          <div id="svgContainer"></div>
          </el-row>-->
          <FilmVisualization />
        </el-row>
        <el-row>
            
            <br />
            <br />
            <div id="lineBox" style="width: 100%;margin-left: 0.1vw;"></div>
        </el-row>
        <!-- <el-row
            v-for="(data, i) in scenedata"
            :key="i"
            :gutter="5"
            style="margin-right: 5px"
          >
            <el-col
              :span="3"
              v-for="(addr, index) in data"
              :key="index"
              style="margin-bottom: 5px"
            >
              <img
                :src="data[index]"
                class="image"
                fit="cover"
                sytle="margin:5px;padding:10px"
              />
            </el-col>
        </el-row>-->
      </el-col>
      <el-col style="margin-left: 1vw;">
        <!-- <el-row> -->
        <el-row v-for="(data, i) in scenedata" :key="i" :gutter="5" style="margin: 0px">
          <el-col :span="1" v-for="(addr, index) in data" :key="index" style="margin-bottom: 0px;padding: 0px;">
            <img :src="data[index]"  class="image" style="margin: 0px;" fit="cover" />
          </el-col>  
        </el-row>
        
        <el-col
        :xs="24"
        :sm="18"
        :md="17"
        :lg="24"
        :xl="18"
        style="padding-left: 1%; padding-right: 1%; padding-top: 1%"
        >
          
        </el-col>
      </el-col>
    </el-row>
  </el-container>
  </div>
</template>
<script>
import BScroll from "better-scroll";
import * as d3 from "d3";
import VideoSearch from "../components/video/VideoSearch.vue";
import VideoEdit from "../components/video/VideoEdit.vue";
import FilmVisualization from "./FilmVisualization.vue";
import pyjs from "js-pinyin";
export default {
  components: {
    VideoSearch,
    VideoEdit,
    FilmVisualization
  },
  // name: 'Home',
  // inject:['reload'],
  data() {
    return {
      drawer: false,
      direction: "btt",
      colors: ["#b8860b", "#778899"],
      currentDate: new Date(),
      // directors: [],
      // movie: [],
      // moviedata: {},
      // videoid:1,
      // movie_id: 12,
      moviename: this.$route.query.moviename,
      scenedata: [],
      scenenum: 1,
      csvdata: "",
      circleShot: [],
      // tableData: [],
      // showData: [],
      src: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0, 3.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重z新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 类型
            // src:require( '../assets/XCZC.mp4' )// url地址
            src: "",
            // src: 'https://assets.yingshinet.com/chronicles/videos/SSH_FM.mp4'
          },
        ],
        // poster: "", // 封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
      playerOptions1: {
        playbackRates: [0.5, 1.0, 1.5, 2.0, 3.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 类型
            // src:require( '../assets/XCZC.mp4' )// url地址
            src: "",
            // src: 'https://assets.yingshinet.com/chronicles/videos/SSH_FM.mp4'
          },
        ],
        // poster: "", // 封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },

  methods: {
    downloadSubtitle(){
      this.axios 
      .post("http://127.0.0.1:5004/subtitle", {
        video_name: pyjs
          .getFullChars(this.$route.query.moviename)
          .toLowerCase(),
      })
      .then((response) => {
        window.location.href = response.data.subtitle_address;
        // console.log(response.data.video_address);
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    downloadList() {
      window.location.href =
        "https://assets.yingshinet.com/chronicles/Cinenmetrics的使用方法与流程.pdf";
    },
    // 播放回调
    onPlayerPlay(player) {
      console.log("player play!", player);
    },
    // 暂停回调
    onPlayerPause(player) {
      console.log("player pause!", player);
    },
    // 视频播完回调
    onPlayerEnded(player) {
      console.log(player);
    },
    // DOM元素上的readyState更改导致播放停止
    onPlayerWaiting(player) {
      console.log(player);
    },
    // 已开始播放回调
    onPlayerPlaying(player) {
      console.log(player);
    },
    // 当播放器在当前播放位置下载数据时触发
    onPlayerLoadeddata(player) {
      console.log("onPlayerLoadeddata", player);
    },
    // 当前播放位置发生变化时触发。
    onPlayerTimeupdate(player) {
      // console.log("onPlayerTimeupdate",player);
    },
    //媒体的readyState为HAVE_FUTURE_DATA或更高
    onPlayerCanplay(player) {
      // console.log('player Canplay!', player)
    },
    //媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
    onPlayerCanplaythrough(player) {
      // console.log('player Canplaythrough!', player)
    },
    //播放状态改变回调
    playerStateChanged(playerCurrentState) {
      // console.log("player current update state", playerCurrentState);
    },
    //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
    playerReadied(player, playtimes) {
      // console.log(player,"SS");
      // console.log("example player 1 readied", player);
      // console.log(player);
      player.currentTime(playtimes);
      // player.currentTime(150);
    },
    //关闭按钮
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },
    initSN() {
      var width =
        (window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth) * 0.96;
      var height =
        (window.innerHeight || 
          document.documentElement.clientHeight ||
          document.body.clientHeight) * 0.3;
      var color = d3.schemeCategory10;
      //设置播放点，续播
      let playBySeconds = (num) => {
        if (num && document.getElementById("player")) {
          // let myVideo = document.getElementById("player");
          let myVideo = this.$refs.VideoPlayer.player;
          this.playerReadied(myVideo, num);
        }
      }

      var fill = d3.schemeCategory10;
      //镜头长度分布（直方图）
      var shotheight = height * 1.8;
      d3.select('svg').selectAll('*').remove();
      var svg1 = d3
        .select("#barchartBox") //选择<body>
        .append("svg") //在<body>中添加<svg>
        .attr("width", width) //设定<svg>的宽度属性
        .attr("height", shotheight); //设定<svg>的高度属性
      d3.csv(this.csvdata).then((data) => {
        // console.log(data);
        var shot = new Array(data.length);
        for (var i = 0; i < data.length; i++) {
          shot[i] = { start: data[i].Start, length: data[i].Length / 4 };
          // console.log(shot[i].start);
        }
        //矩形所占的宽度（包括空白），单位为像素
        var rectStep = width / shot.length;

        //矩形所占的宽度（不包括空白），单位为像素
        var rectWidth = rectStep;
        var recthist = svg1
          .selectAll(".hist")
          .data(shot) //绑定数据
          .enter() //获取enter部分
          .append("rect") //添加rect元素，使其与绑定数组的长度一致
          .attr("class", "hist")
          .attr("x", function (d, i) {
            //设置矩形的x坐标
            return i * rectStep + 30;
          })
          .attr("y", function (d) {
            //设置矩形的y坐标
            return shotheight - d.length;
          })
          .attr("fill", function (i) {
            return color[i];
          })
          .attr("width", 3) //设置矩形的宽度
          .attr("height", function (d) {
            //设置矩形的高度
            return d.length;
          });

        //提示框部分
        var tooltip = d3
          .selectAll("body")
          .append("div")
          .attr("class", "tooltip")
          .style("opacity", 0.0)
          .style("position", "absolute");

        var img_h = 90; //50;
        var img_w = 90; //50;
        var radius = 25; //23;
        var rectStep = width / shot.length;
        //矩形所占的宽度（不包括空白），单位为像素
        var rectWidth = rectStep;
        var circles = svg1
          .selectAll(".img")
          .data(shot)
          .enter()
          .append("circle")
          .attr("class", "img")
          .attr("r", radius)
          .attr("cx", function (d, i) {
            return i * rectStep + 30;
          })
          .attr("cy", function (d) {
            //设置矩形的y坐标
            return shotheight - d.length;
          })
          .style("stroke", "DarkGray")
          .style("stroke-width", "1.0px")
          .attr("fill", (d, i) => {
            //创建圆形图片
            var istr = "";
            if (i < 9) {
              istr = "00" + (i + 1);
            } else {
              istr = "0" + (i + 1);
            }
            var defs = svg1.append("defs").attr("id", "imgdefs");
            var catpattern = defs
              .append("pattern")
              .attr("id", "catpattern" + i)
              .attr("height", 1)
              .attr("width", 1);
            catpattern
              .append("image")
              .attr("x", -(img_w / 2 - radius + 5.8))
              .attr("y", -(img_h / 2 - radius + 3.5))
              .attr("width", img_w + 11)
              .attr("height", img_h + 6)
              .attr('xlink:href', () => {
                // console.log(this.circleShot[i])
                return this.circleShot[i]
              })
            return "url(#catpattern" + i + ")";
          })
          .on("mouseover", function (e, d) {
            // console.log(e,d);
            tooltip
              .html(Math.floor((d.length / 3) * 100) / 100 + "秒")
              .style("left", e.pageX - 30 + "px")
              .style("top", e.pageY - 40 + "px")
              .style("opacity", 1.0);
            d3.select(this).style("cursor", "pointer");
            //  console.log(shot);
            //  console.log(i.start);
            playBySeconds(d.start / 24);
            //  console.log(shot[0].start);
          })
          .on("mousemove", function (e, d) {
            tooltip
              .style("left", e.pageX - 30 + "px")
              .style("top", e.pageY - 40 + "px");
          })
          .on("mouseout", function (d) {
            tooltip.style("opacity", 0.0);
          });
      });
      d3.select('#lineBox').select("svg").selectAll('*').remove();
      //镜头长度分布（时序）
      var svg = d3
        .select("#lineBox") //选择<body>
        .append("svg") //在<body>中添加<svg>
        .attr("width", width) //设定<svg>的宽度属性
        .attr("height", height); //设定<svg>的高度属性

      d3.csv(this.csvdata).then(function (data) {
        //console.log("csv数据"+data.length);
        var tdata = new Array(data.length);
        //console.log(tdata);
        let maxlength=0;
        let sumlength=0;
        let laststart={start:0};
        let barWidth = width * 0.9 / data.length;
        
        //console.log(laststart);
        for (var i = 0; i < tdata.length; i++) {
          tdata[i] = { start: data[i].Start, length: data[i].Length };
          maxlength=Math.max(maxlength,tdata[i].length)
          //console.log(maxlength);
          sumlength+=Number.parseInt(tdata[i].length)
          //console.log(sumlength);
          // const tmpstart=Math.max(laststart.start,tdata[i].start)
          if(Number.parseInt(tdata[i].start)>Number.parseInt(laststart.start)){
            laststart.start=Number.parseInt(tdata[i].start)
            //console.log(laststart.start)
            laststart.length=Number.parseInt(tdata[i].length)
            //console.log(laststart.length)
          }
        }
        // //矩形所占的宽度（包括空白），单位为像素
        // var rectStep = width / tdata.length;
        // //矩形所占的宽度（不包括空白），单位为像素
        // var rectWidth = rectStep;
        var yScale = d3.scaleLinear()
          .domain([0, maxlength]) // 数据的范围
          .range([height, 0]); // 画布的高度范围，从底部到顶部

        // 窗口大小变化时更新比例尺
        function updateScale() {
          yScale.range([height, 0]); // 更新比例尺的范围
          // 其他需要根据窗口大小变化而更新的代码
        }
        window.addEventListener("resize", function () {
            updateScale();
        })
        var recttime = svg
          .selectAll("htime")
          .data(tdata) //绑定数据
          .enter() //获取enter部分
          .append("rect") //添加rect元素，使其与绑定数组的长度一致
          .attr("class", ".htime")
          .attr("x", function (d, i) {
            //设置矩形的x坐标
            //console.log("d.start:"+d.start)
            //console.log("laststart.start:"+laststart.start)
            //console.log("laststart.length:"+laststart.length)
            //console.log("width:"+width)
            return width * i / data.length;
          })
          .attr("y", function (d) {
            //设置矩形的y坐标
            return  yScale(d.length); ;
          })
          .attr("fill", function (d, i) {
            
            return color[i % 10];
          })
          .attr("height", function (d) {
            //设置矩形的长度
            return height  - yScale(d.length);
          })
          .attr("width", barWidth);
          
      });
    }
   

  },
  created() {
    this.axios 
      .post("https://api.movie.yingshinet.com/videoplay", {
        video_name: this.$route.query.moviename,
      })
      .then((response) => {
        this.playerOptions.sources[0].src = response.data.video_address;
        // console.log(response.data.video_address);
      })
      .catch(function (error) {
        console.log(error);
      });
      //获取拉片介绍的视频
      this.axios
      .post("https://api.movie.yingshinet.com/videoplay", {
        video_name: 'cinemetrics',
      })
      .then((response) => {
        //console.log("video address:")
        // console.log(response.data.video_address);
        this.playerOptions1.sources[0].src = response.data.video_address;
        //console.log(this.playerOptions1.sources[0].src)
      })
      .catch(function (error) {
        console.log(error);
      });
    this.axios
      .post("https://api.movie.yingshinet.com/histogram", {
        video_name: pyjs
          .getFullChars(this.$route.query.moviename)
          .toLowerCase(),
      })
      .then((response) => {
        this.csvdata = response.data.csv_address;
        console.log("csv data:"+this.csvdata);
      })
    this.axios
      .post("https://api.movie.yingshinet.com/scenes", {
        video_name: pyjs
          .getFullChars(this.$route.query.moviename)
          .toLowerCase(),
      })
      .then((response) => {
        // console.log(response.data);
        this.scenenum = response.data.scenes_count;
        // console.log(response.data.scenes_data[0].image_file_address[0]);
        for (let i = 0; i < response.data.scenes_count; i++) {
          this.scenedata.push(response.data.scenes_data[i].image_file_address);
          //console.log(response.data.scenes_data[i].image_file_address);
          // console.log(response.data.scenes_data[i].image_file_address);
          //console.log(this.scenedata);
          for (let j in response.data.scenes_data[i].image_file_address) {
            if (response.data.scenes_data[i].image_file_address[j].indexOf("-01.jpg") != -1) {
              // console.log(response.data.scenes_data[i].image_file_address[j])
              this.circleShot.push(response.data.scenes_data[i].image_file_address[j]);
              this.circleShot.sort(function (a, b) { return a.substr(-10, 3) - b.substr(-10, 3) })
            }
          }
        }
        // console.log(this.scenedata)
        // console.log(this.circleShot)
        this.initSN()
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  mounted() {

  },
};
</script>
<style scoped>
.home {
  width: 80%;
  margin: 0 0;
  text-align: center;
}

.el-container {
  height: 100%;
  padding: 0;
  margin: 0;
  width: 100%;
}

.el-aside {
  background-color: #f3f3f3;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 1%;
  padding-right: 1%;
  text-align: left;
  height: 100vh;
}
.wrapper {
  height: 50vh;
  overflow: hidden;
}

.el-main {
  background-color: #f8f8ff;
  color: #333;
  text-align: center;
  line-height: 160px;
}

.el-row {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 1vh;
}
.el-col {
  border-radius: 4px;
  margin-bottom: 2vh;
}
.card {
  width: 100%;
  height: 150px;
  margin-top: 5px;
}
.bg-purple {
  background: #e9eef3;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f4f7ff;
}
.time {
  font-size: 13px;
  color: #999;
}
.right {
  margin-right: 0px;
}
.bottom {
  margin-top: 0px;
  line-height: 12px;
  padding-top: 5px;
  /* padding: 14px; */
}

.button {
  float: right;
  border-radius: 15%;
}

.image {
  width: 100%;
  height: 100%;
  display: block;
}
.input {
  margin-top: -10%;
  margin-bottom: 2%;
  padding-top: 0px;
  width: 100%;
  margin-left: 0%;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.mouse_hover:hover {
  cursor: pointer;
}

::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
#video1 {
  width: 49%;
}

#video2 {
  width: 49%;
  margin-left: 1%;
}

.video-container {
  display: flow-root;
  justify-content: space-between;
  flex-wrap: wrap;
}

.video1, .video2 {
  width: 65%;
}

/* Add more responsive styles as needed for different screen sizes */
@media (max-width: 768px) {
  .video-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

  
  #video1 {
    width: 198%;
    margin-left: 0.5vw;
  }
  #video2 {
    width: 98%; 
    margin-left: 0.5vw;
  }
  
}

</style>