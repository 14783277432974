<template>
  <div >
    <div
      class="container-border"
      style="height: 65vh; width: 80vw; margin-top: 30px; margin-left:auto; margin-right:auto; margin-bottom:0px;"
    >
      <h3 style=" text-align: center; color: #F0EBE1;">按职业导航</h3>
      <svg id="barchart" class="barchart-border"></svg>
      <svg id="barchart2" class="barchart-border"></svg>
      <svg id="barchart3" class="barchart-border"></svg>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import * as $ from "jQuery";
export default {
  data() {
    return {
      //dataset记录的是名称
      dataset: [],
      //count 记录的是数量
      count: [],
      //演员数据
      dataset_actors:[],
      count_actors:[],
      //编剧数据
      dataset_writer:[],
      count_writer:[],
    };
  },
  methods: {
    DrawBarChart() {
      // 每次绘图时清空svg
      let svg = d3.select("#barchart").selectAll("*").remove();
      //创建序数比例尺和包括10中颜色的输出范围
      var color = d3.schemeCategory10;
      let w = $(".container-border").width();
      let h = $(".container-border").height()-300;
      //创建svg设定尺寸与偏移
      svg = d3
        .select("#barchart")
        .attr("width", w) //设定<svg>的宽度属性
        .attr("height", h)
        .append("g"); 
      //设定<svg>的高度属性
      //矩形所占的宽度（包括空白），单位为像素
      var rectStep = w / this.dataset.length;
      //矩形所占的宽度（不包括空白），单位为像素
      var rectWidth = rectStep - 1;
      //创建svg设定尺寸与偏移
      const dataset = this.dataset
      const count = this.count
      svg
        .selectAll("rect")
        .data(count) //绑定数据
        .enter() //获取enter部分
        .append("a")
        .attr("xlint:href", function (d, i) {
          return "/#/post?name=" + dataset[i];
        })
        .append("rect") //添加rect元素，使其与绑定数组的长度一致
        .attr("x", function (d, i) {
          //设置矩形的x坐标
          return i * rectStep;
        })
        .attr("y", function (d) {
          //设置矩形的y坐标
          return h - d;
        })
        .attr("width", rectWidth) //设置矩形的宽度
        .attr("height", function(d){	//设置矩形的高度
							return d;
						})
        .attr("fill", function (d, i) {
          return color[i % 10];
        });
      var text = svg
        .selectAll("text")
        .data(count) //绑定数据
        .enter() //获取enter部分
        .append("a")
        .attr("xlint:href", function (d, i) {
          return "/#/post?name=" + dataset[i];
        })
        .append("text") //添加rect元素，使其与绑定数组的长度一致
        .attr("x", (d, i) =>
          i % 2 == 0 ? i * rectStep + 4 : i * rectStep + 20
        )
        .attr("y", (d) => h - d * 2)
        .attr("dx", "3em")
        .attr("dy", "0.5em")
        .attr("font-size", rectWidth * 0.25)
        .attr("fill", function (d, i) {
          return color[i % 10];
        })
        .attr(
          "transform",
          (d, i) =>
            `rotate(-90,${i * rectStep + rectStep / 2},${h - d * 2})`
        )
        .text((d, i) => dataset[i]); //.substring(0,2)

      // 每次绘图时清空svg
      let svg1 = d3.select("#barchart2").selectAll("*").remove();
      //创建序数比例尺和包括10中颜色的输出范围
      //创建svg设定尺寸与偏移
      svg1 = d3
        .select("#barchart2")
        .attr("width", w) //设定<svg>的宽度属性
        .attr("height", h)
        .append("g"); 
      //设定<svg>的高度属性
      //矩形所占的宽度（包括空白），单位为像素
      var rectStep1 = w / this.dataset_actors.length;
      //矩形所占的宽度（不包括空白），单位为像素
      var rectWidth1 = rectStep1 - 1;
      //创建svg设定尺寸与偏移
      const dataset1 = this.dataset_actors
      const count1 = this.count_actors
      svg1
        .selectAll("rect")
        .data(count1) //绑定数据
        .enter() //获取enter部分
        .append("a")
        .attr("xlint:href", function (d, i) {
          return "/#/post?name=" + dataset1[i];
        })
        .append("rect") //添加rect元素，使其与绑定数组的长度一致
        .attr("x", function (d, i) {
          //设置矩形的x坐标
          return i * rectStep1;
        })
        .attr("y", function (d) {
          //设置矩形的y坐标
          return h - d;
        })
        .attr("width", rectWidth1) //设置矩形的宽度
        .attr("height", function(d){	//设置矩形的高度
							return d;
						})
        .attr("fill", function (d, i) {
          return color[i % 10];
        });
      var text1 = svg1
        .selectAll("text")
        .data(count1) //绑定数据
        .enter() //获取enter部分
        .append("a")
        .attr("xlint:href", function (d, i) {
          return "/#/post?name=" + dataset1[i];
        })
        .append("text") //添加rect元素，使其与绑定数组的长度一致
        .attr("x", (d, i) =>
          i % 2 == 0 ? i * rectStep1 + 4 : i * rectStep1 + 20
        )
        .attr("y", (d) => h - d * 2)
        .attr("dx", "3em")
        .attr("dy", "0.5em")
        .attr("font-size", rectWidth1 * 0.25)
        .attr("fill", function (d, i) {
          return color[i % 10];
        })
        .attr(
          "transform",
          (d, i) =>
            `rotate(-90,${i * rectStep1 + rectStep1 / 2},${h - d * 2})`
        )
        .text((d, i) => dataset1[i]); //.substring(0,2)

      // 每次绘图时清空svg
      let svg2 = d3.select("#barchart3").selectAll("*").remove();
      //创建序数比例尺和包括10中颜色的输出范围
      //创建svg设定尺寸与偏移
      svg2 = d3
        .select("#barchart3")
        .attr("width", w) //设定<svg>的宽度属性
        .attr("height", h)
        .append("g"); 
      //设定<svg>的高度属性
      //矩形所占的宽度（包括空白），单位为像素
      var rectStep2 = w / this.dataset_writer.length;
      //矩形所占的宽度（不包括空白），单位为像素
      var rectWidth2 = rectStep2 - 1;
      //创建svg设定尺寸与偏移
      const dataset2 = this.dataset_writer
      const count2 = this.count_writer
      svg2
        .selectAll("rect")
        .data(count2) //绑定数据
        .enter() //获取enter部分
        .append("a")
        .attr("xlint:href", function (d, i) {
          return "/#/post?name=" + dataset2[i];
        })
        .append("rect") //添加rect元素，使其与绑定数组的长度一致
        .attr("x", function (d, i) {
          //设置矩形的x坐标
          return i * rectStep2;
        })
        .attr("y", function (d) {
          //设置矩形的y坐标
          return h - d;
        })
        .attr("width", rectWidth2) //设置矩形的宽度
        .attr("height", function(d){	//设置矩形的高度
							return d;
						})
        .attr("fill", function (d, i) {
          return color[i % 10];
        });
      var text2 = svg2
        .selectAll("text")
        .data(count2) //绑定数据
        .enter() //获取enter部分
        .append("a")
        .attr("xlint:href", function (d, i) {
          return "/#/post?name=" + dataset2[i];
        })
        .append("text") //添加rect元素，使其与绑定数组的长度一致
        .attr("x", (d, i) =>
          i % 2 == 0 ? i * rectStep2 + 4 : i * rectStep2 + 20
        )
        .attr("y", (d) => h - d * 2)
        .attr("dx", "3em")
        .attr("dy", "0.5em")
        .attr("font-size", rectWidth2 * 0.25)
        .attr("fill", function (d, i) {
          return color[i % 10];
        })
        .attr(
          "transform",
          (d, i) =>
            `rotate(-90,${i * rectStep2 + rectStep2 / 2},${h - d * 2})`
        )
        .text((d, i) => dataset2[i]); //.substring(0,2)
    },
  },
  created() {
    // 获取直方图的数据
    this.axios
      .post("https://api.movie.yingshinet.com/wMLSearch", {
        wanted: "字开头的导演",
      })
      .then((res) => {
        const director_data = res.data.searched_titles;
        this.dataset = new Array(director_data.length);
        this.count = new Array(director_data.length);
        var i = 0;
        var str;
        for (var i = 0; i < director_data.length; i++) {
          this.dataset[i] = director_data[i].titles;
          this.count[i] = director_data[i].contents;
        }
      });
      //获取 字开头的演员数据
      this.axios
      .post("https://api.movie.yingshinet.com/wMLSearch", {
        wanted: "字开头的演员",
      })
      .then((res) => {
        const director_data = res.data.searched_titles;
        this.dataset_actors = new Array(director_data.length);
        this.count_actors = new Array(director_data.length);
        var i = 0;
        var str;
        for (var i = 0; i < director_data.length; i++) {
          this.dataset_actors[i] = director_data[i].titles;
          this.count_actors[i] = director_data[i].contents;
        }
      });
      //获取 字开头的编剧数据
      this.axios
      .post("https://api.movie.yingshinet.com/wMLSearch", {
        wanted: "字开头的编剧",
      })
      .then((res) => {
        const director_data = res.data.searched_titles;
        this.dataset_writer = new Array(director_data.length);
        this.count_writer = new Array(director_data.length);
        var i = 0;
        var str;
        for (var i = 0; i < director_data.length; i++) {
          this.dataset_writer[i] = director_data[i].titles;
          this.count_writer[i] = director_data[i].contents;
          // str = director_data[i].contents;
          // this.count[i] = Math.sqrt(str);
        }
        this.DrawBarChart();
      });
  },
};
</script> 
<style scoped>
.container-border {
  overflow: scroll;
  padding: 0;
}
.barchart-border{
  overflow: scroll;
  padding: 0;
  margin:0px 0px 50px 0px;
}
.container-border h3 {
  padding: 0px;
  font-size: 1.2vw;
}
</style>