<template>
  <div :class="!this.$store.state.isCollapse ?'video_index-pc':'video_index-mobile'" >
    <!-- <div> -->
    <el-row justify="center" type="flex" align="middle" style="padding-top:4%;padding-bottom:2%;">
      <el-col :xs="0" :sm="0" :md="0" :lg="6" :xl="6">
        <div style="width:25%;background:#3C3C3C"></div>
      </el-col>
      <el-tooltip class="item" effect="dark" placement="bottom">
        <div slot="content">
          点击查看
          <br />年代影像图
        </div>
        <el-button icon="el-icon-s-data" style="margin-right:10px" @click="toGraphByYear()"></el-button>
      </el-tooltip>
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <video-search style="margin:0% auto" v-on:searchResults="searchResults"></video-search>
      </el-col>
      <el-col :xs="0" :sm="0" :md="0" :lg="6" :xl="6">
        <div style="width:25%;background:#3C3C3C"></div>
      </el-col>
    </el-row>
    <!-- </div> -->
    <div style="background:#3C3C3C;">
      <el-row :gutter="5" style="padding-top:1%;">
        <el-col :xs="6" :sm="6" :md="4" :lg="2" :xl="2" style="text-align:center;border-radius: 6px"
          :style="{ backgroundColor: !type_isSelect ? '#E6E6E6' : '' }">
          <p class="selectfont" @click="type_fun(-1)">全部类型</p>
        </el-col>
        <el-col :xs="6" :sm="6" :md="4" :lg="2" :xl="2" style="text-align:center;border-radius: 6px"
          :style="{ backgroundColor: type_arr[i] ? '#E6E6E6' : '' }" v-for="(data, i) in type" :key="i">
          <p class="selectfont" @click="type_fun(i)">{{ data }}</p>
        </el-col>
      </el-row>

      <hr style="border:none;height:-2px;color:white" />
      <el-row :gutter="5" style="margin-top:0%;">
        <el-col :xs="6" :sm="6" :md="4" :lg="2" :xl="2" style="text-align:center;border-radius: 6px"
          :style="{ backgroundColor: !year_isSelect ? '#E6E6E6' : '' }">
          <p class="selectfont" @click="year_fun(-1)">全部年代</p>
        </el-col>
        <el-col :xs="6" :sm="6" :md="4" :lg="2" :xl="2" style="text-align:center;border-radius: 6px"
          :style="{ backgroundColor: year_arr[i] ? '#E6E6E6' : '' }" v-for="(data, i) in year" :key="i">
          <p class="selectfont" @click="year_fun(i)">{{ data }}</p>
        </el-col>
      </el-row>
    </div>
    <div style="padding:5px 25px;margin-top:10px;border-radius:15px;" :style="{ backgroundColor: isCard ? 'rgba(255, 255, 255, 0.1)' : '' }">
      <el-row>
        <el-col :span="22"></el-col>
        <el-button-group style="float:right;">
          <el-button icon="el-icon-menu" @click="is_card(1)"></el-button>
          <el-button icon="el-icon-s-fold" @click="is_card(0)"></el-button>
        </el-button-group>
      </el-row>

      <div v-if="isCard">
        <el-row :gutter="20" style="padding-top:10px">
          <el-col :span=3 v-if="$store.state.login_state && currentPage == 1" class="mouse_hover">
            <el-card class="card" shadow="hover" :body-style="{ padding: '0px' }">
              <el-image src="/static/add.jpg" @click="drawer = true" class="image" fit="cover" />
              <div class="bottom2 clearfix">
                <div style="font-size: 15px;color: #999;text-align:center">添加影片</div>
              </div>
            </el-card>
          </el-col>
          <el-col :xs="12" :sm="6" :md="4" :lg="3" :xl="3"
            v-for="(data, i) in (isSearch ? result.slice((currentPage - 1) * 40, currentPage * 40) : tableData.slice((currentPage - 1) * 40, currentPage * 40))"
            :key="i" class="mouse_hover">
            <el-card :class="!$store.state.isCollapse ?'card':'card-mobile'" shadow="hover" :body-style="{ padding: '0px' }"><!--改动-->
              <!-- <router-link :to="'/video?id='+data.no"> -->
              <el-image :src="data.pic" :class="!$store.state.isCollapse ?'image':'image-mobile'" fit="cover" @click="convertmovie(data.no)" />
              <el-button v-show="data.video_path != null" class="iconClass" icon="el-icon-video-camera-solid"></el-button>
              <!-- //class="image"  -->
              <!-- </router-link> -->
              <div :class="!$store.state.isCollapse ? 'bottom2' : 'bottom1'">
                <div style="font-size: 15px;color: #999;text-align:center">{{ data.name }}</div>
              </div>
            </el-card>
          </el-col>
          <!-- <el-col v-if="$store.state.login_state&&currentPage==Math.ceil(isSearch ? result.length/48: tableData.length/48)" :span="3" class="mouse_hover">
           -->
        </el-row>
      </div>
    </div>

    <el-row :gutter="20" v-if="!isCard" style="padding-top:30px">
      <!-- <br/> -->
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="8"
        v-for="(data, i) in (isSearch ? result.slice((currentPage - 1) * 10, currentPage * 10) : tableData.slice((currentPage - 1) * 10, currentPage * 10))"
        :key="i" @click.native="convertmovie(data.no)">
        <el-card shadow="hover" :body-style="{ padding: '0px' }"
          style="height:200px;overflow-y:auto;margin-top:-20px;margin-bottom:40px;padding-top:10px;padding-left:10px;padding-bottom:10px;border-radius:10px;">
          <el-col :xs="10" :sm="12" :md="12" :lg="9" :xl="9">
            <img :src="data.pic" style="width:120px;height:150px;border-radius:5px;margin-top:15px;" />
          </el-col>
          <el-col :xs="14" :sm="12" :md="12" :lg="15" :xl="15">
            <span class="mouse_over"
              style="height: auto; font-size: 20px; color:#B8860B; line-height: 50px; text-align:center;font-weight:bold;">《{{
                data.name }}》</span>
            <br />
            <span
              style="height: auto; font-size: 15px; color:gray;text-align: left;line-height:30px;font-weight:bold;">导演：</span>
            <span class="mouse_over"
              style="height: auto; font-size: 15px; color:gray; line-height: 30px; text-align:center;"
              v-html="data.director"></span>
            <br />
            <span
              style="height: auto; font-size: 15px; color:gray;text-align: left;line-height:30px;font-weight:bold;">首映时间：</span>
            <span style="height: auto; font-size: 15px; color:gray;text-align: left;line-height:30px">{{ data.year
            }}年</span>
            <br />
            <span
              style="height: auto; font-size: 15px; color:gray;text-align: left;line-height:30px;font-weight:bold;">主演：</span>
            <span style="height: auto; font-size: 15px; color:gray;text-align: left;line-height:30px"
              v-html="data.actor"></span>
            <br />
            <span
              style="height: auto; font-size: 15px; color:gray;text-align: left;line-height:30px;font-weight:bold;">编剧：</span>
            <span style="height: auto; font-size: 15px; color:gray;text-align: left;line-height:30px"
              v-html="data.script"></span>
          </el-col>
        </el-card>
      </el-col>
    </el-row>

    <el-drawer title="请输入影片信息" size="100%" :visible.sync="drawer" :direction="direction" :before-close="handleClose">
      <video-add></video-add>
    </el-drawer>

    <el-row type="flex" justify="space-around" style="padding-top:2%;padding-bottom:2%;">
      <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
        <el-pagination style="text-align:center" background layout="prev, pager, next"
          @current-change="handleCurrentChange" @prev-click="handlePrevClick" @next-click="handleNextClick"
          :current-page="currentPage" :page-size="isCard ? 40 : 10"
          :total="isSearch ? result.length : tableData.length"></el-pagination>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import VideoSearch from "../components/video/VideoSearch.vue"
import VideoAdd from "../components/video/VideoAdd.vue"
export default {
  components: {
    VideoSearch,
    VideoAdd
  },
  data() {
    return {
      currentPage: 1,
      isCard: true,
      drawer: false,
      direction: 'btt',
      tableData: [],
      // year_select:[],
      type_select: -1,
      year_select: -1,
      type_isSelect: false,
      year_isSelect: false,
      type: ["剧情", "喜剧", "家庭", "爱情", "短片", "戏曲", "纪录片", "传记", "历史", "战争", '古装'],
      // ,"动画","悬疑","恐怖","犯罪","奇幻","冒险","灾难","武侠"

      year: ['2020年代', "2010年代", "2000年代", "90年代", "80年代", "70年代", "60年代", "50年代", "40年代", "30年代"],
      //type_arr:[false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false],
      type_arr: [],
      year_arr: [],
      result: [],
      isSearch: false,
    }
  },
  methods: {
    toGraphByYear() {
      this.$router.push({
        path: '/video_index_graph'
      })
    },
    getInfo() {

      const path = "https://api.movie.yingshinet.com/movies";
      this.axios
        .get(path)
        .then((res) => {
          this.work = res.data.m_data;
          this.num = res.data.m_count;
          this.getData();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getData() {
      for (var i = 0; i < this.num; i++) {
        this.tableData[i] = {};
        this.tableData[i].no = this.work[i].mid;
        this.tableData[i].name = this.work[i].m_name;
        this.tableData[i].pic = this.work[i].m_pic || 'https://assets.yingshinet.com/chronicles/pictures/MOVIE_27.jpg';
        this.tableData[i].director = this.work[i].m_director;
        this.tableData[i].actor = this.work[i].m_actor;
        this.tableData[i].year = this.work[i].m_year;
        this.tableData[i].script = this.work[i].m_script;
        this.tableData[i].movie_type = this.work[i].movie_type;
        this.tableData[i].intro = this.work[i].m_intro || "简介" + i + "待补充";
        // this.tableData[i].video_path = this.work[i].m_video || 'https://assets.yingshinet.com/chronicles/videos/TL_FM.mp4';
        this.tableData[i].video_path = this.work[i].m_video;
      }
      this.$set(this.sortYear(this.tableData));
    },
    is_card(i) {
      if (i == 1) {
        this.isCard = true;
      }
      else {
        this.isCard = false;
      }
    },
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;

    },
    handlePrevClick: function () {
      this.currentPage = currentPage - 1;
    },
    handleNextClick: function () {
      this.currentPage = currentPage + 1;
    },
    //按年份和月份时间顺序排序
    sortYear(obj) {
      return obj.sort(function (a, b) {
        let x = a["year"];
        let y = b["year"];
        if (x < y) return -1;
        else return 1;
      });
    },

    type_fun(num) {
      if (num !== -1) {
        var arr_index = new Array();
        for (var i = 0; i < this.type_arr.length; i++) {
          arr_index.push(i)
        }
        let status = !this.type_arr[num]
        this.type_arr.splice(num, 1, status)
        arr_index.splice(num, 1)
        for (var i = 0; i < arr_index.length; i++) {
          let s = false
          this.type_arr.splice(arr_index[i], 1, s)
        }
        let index = this.type_arr.indexOf(true);

        this.type_select = index
        if (index != -1) {
          this.type_isSelect = true;
          this.isSearch = true;
        }
        else {
          this.type_isSelect = false;
          this.isSearch = true;
        }
        var data = []
        for (var i = 0; i < this.tableData.length; i++) {
          if (this.tableData[i].movie_type.indexOf(this.type[num]) != -1) {
            if (!this.year_isSelect) {
              data.push(this.tableData[i])
            }
            else {
              if (this.tableData[i].year >= 2020 - (10 * this.year_select) && this.tableData[i].year < 2030 - (10 * this.year_select)) {
                data.push(this.tableData[i])
              }
            }

          }
          this.result = data
        }
      }

      else {
        if (this.year_isSelect) {
          var data = []
          for (var i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].year >= 2020 - (10 * this.year_select) && this.tableData[i].year < 2030 - (10 * this.year_select)) {
              data.push(this.tableData[i])
            }
          }
          this.result = data
          this.isSearch = true;
        }
        else {
          this.isSearch = false;
        }
        this.type_isSelect = !this.type_isSelect;
        this.type_arr.splice(0, this.type_arr.length, false);


      }

    },
    year_fun(num) {
      if (num !== -1) {

        var arr_index = new Array();
        for (var i = 0; i < this.year_arr.length; i++) {
          arr_index.push(i)
        }
        let status = !this.year_arr[num]
        this.year_arr.splice(num, 1, status)
        arr_index.splice(num, 1)
        for (var i = 0; i < arr_index.length; i++) {
          let s = false
          this.year_arr.splice(arr_index[i], 1, s)
        }
        let index = this.year_arr.indexOf(true);

        this.year_select = index
        if (index != -1) {
          this.year_isSelect = true;
          this.isSearch = true;
        }
        else {
          this.year_isSelect = false;
          this.isSearch = true;
        }

        var data = []
        for (var i = 0; i < this.tableData.length; i++) {
          if (this.tableData[i].year >= 2020 - (10 * num) && this.tableData[i].year < 2030 - (10 * num)) {
            if (!this.type_isSelect) {
              data.push(this.tableData[i])
            }
            else {
              if (this.tableData[i].movie_type.indexOf(this.type[this.type_select]) != -1) {
                data.push(this.tableData[i])
              }
            }

          }
          this.isSearch = true;
          this.result = data
        }


      }
      else {
        if (this.type_isSelect) {
          var data = []
          for (var i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].movie_type.indexOf(this.type[this.type_select]) != -1) {
              data.push(this.tableData[i])
            }
          }
          this.result = data
        }
        else {
          this.isSearch = false;
        }
        this.year_isSelect = !this.year_isSelect;
        this.year_arr.splice(0, this.year_arr.length, false);
      }
    },
    convertmovie(id) {
      this.$router.push({
        path: '/video',
        query: {
          id: id,
        },
      })
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    searchResults: function (Value) {
      // childValue就是子组件传过来的值
      this.result = Value
      if (this.result.length !== 0) {
        this.isSearch = true;
      }
      else {
        this.$alert('换个关键词试试', '找不到您想搜索的内容', {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'info',
              message: `action: ${action}`
            });
          }
        });
      }

    }

  },
  created() {
    for (var i = 0; i < this.type.length; i++) {
      this.type_arr.push(false)
    }
    for (var i = 0; i < this.year.length; i++) {
      this.year_arr.push(false)
    }
    this.getInfo();
  },
}
</script>
<style scoped>
.selectfont {
  color: #7f7c82;
  font-size: 15px;
  margin-top: 10px;
  /* font-weight:bold */
}

.iconClass {
  padding: 0px;
  /* background: none; */
  border: none;
  position: relative;
  font-size: 30px;
  color: black;
}

.mouse_hover:hover {
  cursor: pointer;
}

.image {
  width: 120px;
  height: 150px;
  display: block;
  position: absolute;
}
.image-mobile {
  width: 160px;
  height: 200px;
  display: block;
  position: absolute;
}

.card {
  width: 120px;
  height: 170px;
  /* margin-top:10px; */
  margin-bottom: 20px;
  /* background:#DFD8CA; */
}
.card-mobile {
  width: 160px;
  height: 220px;
  /* margin-top:10px; */
  margin-bottom: 20px;
  /* background:#DFD8CA; */
}

.bottom1 {
  top: 200px;
  position: relative;
  /* line-height: 12px; */
  /* padding-top: 5px; */
  /* background:#DFD8CA; */
  /* padding: 14px; */
}

.bottom2 {
  top: 150px;
  position: relative;
  /* line-height: 12px; */
  /* padding-top: 5px; */
  /* background:#DFD8CA; */
  /* padding: 14px; */
}

/* .clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
} */
.mouse_hover:hover {
  cursor: pointer;
}
.video_index-pc {
  padding-left:8%;
  padding-right:8%;
  background:#3C3C3C;
}

.video_index-mobile {
  padding-left:4%;
  padding-right:4%;
  background:#3C3C3C;
}
</style>