<template>
  <div style="padding: 5px">
    <div class="el_card" slot="header" style="height: 60px">
      <el-input
        class="e-input"
        placeholder="搜索标题"
        v-model="title_search"
        style="width: 60%; margin: 10px 0 10px 200px"
      ></el-input>
      <el-button @click="titleSearch" type="warning" plain>搜索</el-button>
    </div>

    <el-card
      style="height: 600px; width: 100%;">
    <div>
      <el-row>
        <el-col
          :span="4"
          v-for="(title, index) in search_data"
          :key="index"
          class="text_item"
        >
          <span class="mouse_over" @click="titleClick(title)">{{ title }}</span>
        </el-col>
      </el-row>
    </div>
  </el-card>
  </div>
</template>
  
  <script>
import service from "../utils/request";
export default {
  data() {
    return {
      //翻页
      currentPage: 1, //初始页
      pagesize: 20, //    每页的数据
      search_data: [],
      alltags: [],
      alltagname: [],
      title_search: "",
      director_sorted: [],
      tableData_length: null,
      directors: [],
      all_titles: [],
      url: "",
      path: "https://api.movie.yingshinet.com",
      isSearch: false,
      color: "#b8860b",
    };
  },

  methods: {
    titleSearch() {
      this.search_data = [];
      this.axios
        .post("https://api.movie.yingshinet.com/posttitle", {
          title_name: this.title_search.trim().toLowerCase(),
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.alltags = response.data.searched_titles;
            for (let i = 0; i < this.alltags.length; i++) {
              this.alltagname[i] = this.alltags[i].titles;
            }
            console.log("搜索");
            console.log(this.alltagname);
            this.search_data = this.alltagname;
          }
          else{
            this.search_data.push("暂无数据")
          }
        });
    },
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange(size) {
      this.pagesize = size;
      //console.log(this.pagesize)
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      if (this.isSearch) {
        // && this.tableData.length!=0
        //如果是搜索功能的话，是一次性返回全部数据，需要每次换页的时候分页显示
        this.director_sorted = this.list.slice(
          (this.currentPage - 1) * this.pagesize,
          this.currentPage * this.pagesize
        );
        // 获取查询的结果，把数组长度赋值给分页组件中的total
        //this.tableData_length = list.length;
        this.$set(this.director_sorted);
      } else {
        const path = "https://api.movie.yingshinet.com/figures_world_Festival";
        this.axios
          .post(path, { divide: 1, page: currentPage, count: 50 })
          .then((res) => {
            this.tableData_length = res.data.all_figures_count;
            this.directors = [];
            for (var i = 0; i < res.data.cur_figures_count; i++) {
              this.directors.push(res.data.figures[i].f_name);
            }
            this.director_sorted = [];
            this.director_sorted = this.directors;
            this.$set(this.director_sorted);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    //按字母顺序排序
    sortChinese(obj) {
      obj.sort(function (item1, item2) {
        return item1.localeCompare(item2, "zh-CN"); // localeCompare为string内置函数
      });
    },
      titleClick(title) {
      const index = this.alltagname.indexOf(title)
      // console.log(index)
      this.$router.push('/title?name=' + title)
    },

  },
    mounted() {
    //this.titleSearch()
  },

};
</script>
  
  <style scoped>
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
</style>
  
  <style>
.text_item {
  font-size: 15px;
  margin-bottom: 12px;
  margin-left: 15px;
  color: #F0EBE1;
}

.mouse_over:hover {
  color: #b8860b;
  cursor: pointer;
}

.el-card .el-card__header {
  padding: 8px 20px;
}

.e-input {
  text-align: left;
  border-radius: 5px;
  color: #606266;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  padding-left: 8px;
  outline: none;
}

.elebutton .el-backtop {
  right: 36px;
  bottom: 30px;
  width: 50px;
  height: 50px;
  font-size: 28px;
}

.back_image {
  background-image: url("/static/back1.jpg");
  background-size: cover;
}
</style>

<style scoped>
.el-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(0, 0, 0, 0.5);
  border-width: 2px;
  border-style: solid;
}
</style>
  