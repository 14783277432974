<template>
  <div>
    <el-table
      :data="this.isSearch ? searchData : tableData"
      :key="sort_button"
      style="width: 90%; margin: 20px auto"
      :row-style="{ height: '80px' }"
      class="table"
    >
      <el-table-column
        align="center"
        label="编号"
        prop="random"
        min-width="5%"
      ></el-table-column>
      <el-table-column
        align="center"
        label="影人"
        prop="figure"
        min-width="10%"
      ></el-table-column>
      <el-table-column
        align="center"
        label="作者"
        prop="writer"
        min-width="15%"
      ></el-table-column>
      <el-table-column
        align="center"
        label="标题"
        prop="title"
        min-width="25%"
      ></el-table-column>
      <el-table-column
        align="center"
        label="来源"
        prop="source"
        min-width="25%"
      ></el-table-column>
      <el-table-column
        align="center"
        label="类型"
        prop="paper_type"
        min-width="15%"
      ></el-table-column>
      <!-- <el-table-column align="center" label="封面" min-width="20%">
        <template slot-scope="scope">
          <el-image class="image_style" v-if="scope.row.pic != null" :src="scope.row.pic"></el-image>
          <div v-if="scope.row.pic == null">暂无封面</div>
        </template>
      </el-table-column> -->
      <el-table-column align="center" min-width="10%">
        <template slot="header" slot-scope="scope">
          <el-input
            v-model="search_content"
            @keyup.enter.native="searchHandler"
            size="mini"
            placeholder="搜索论文名"
          />
        </template>
        <!-- <template slot-scope="scope">
          <el-button size="mini" v-if="$store.state.login_state" type="warning"
            @click="handleEdit(scope.$index, scope.row)">修改</el-button>
        </template> -->
      </el-table-column>
    </el-table>

    <el-pagination
      style="text-align: center; margin: 5vh 0"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pagesize"
      layout="total, prev, pager, next"
      :total="tableData_length"
    ></el-pagination>

    <!-- 添加按钮 -->
    <span class="elebutton">
      <el-button
        style="color: #b8860b; box-shadow: 3px 3px 10px #aaaaaa"
        class="el-backtop"
        icon="el-icon-plus"
        @click="dialog = true"
        circle
        v-if="this.$store.state.login_state"
      ></el-button>
    </span>
    <!-- 新添加论文页面 -->
    <el-dialog class="el-dia" title="上传论文文件" :visible.sync="dialog">
      <el-form>
        <el-form-item label="影人" width="50px" style="margin-top: 15px">
          <el-input v-model="figure" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="论文类型" width="50px" style="margin-top: 15px">
          <el-select v-model="paper_type" autocomplete="off" name="">
            <el-option value="影人创作文章及照片">影人创作文章及照片</el-option>
            <el-option value="他人评论影人文章">他人评论影人文章</el-option>
            <el-option value="他人创作影人照片">他人创作影人照片</el-option>
          </el-select>
        </el-form-item>
        <el-form-item >
          <el-upload
            action="alert"
            style="margin-top: 20px"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-change="handleChange"
            :auto-upload="false"
          >
            <div class="el-upload__text" style="color:#409EFF"><em>点击上传</em></div>
            <template #tip>
              <div class="el-upload__tip">文件格式限制为csv, xls, xlsx文件</div>
            </template>
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="text" @click="downloadList">请先下载示例</el-button>
          <el-button
            style="padding: 12px 20px; font-size: 14px"
            type="success"
            @click="ListSubmit()"
          >
            上传
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 修改著作页面 -->
    <el-dialog
      class="el-dia"
      title="著作编辑"
      :visible.sync="dialogEditVisible"
      fullscreen
    >
      <el-dialog
        title="提示"
        :visible.sync="make_sure"
        width="30%"
        append-to-body
      >
        <span>确定要修改信息？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="make_sure = false">取 消</el-button>
          <el-button type="warning" @click="make_sure_click">确 定</el-button>
        </span>
      </el-dialog>
      <el-upload
        class="upload-demo"
        ref="upload"
        list-type="picture"
        action
        :limit="limit_num"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :on-change="handleChange"
        :auto-upload="false"
        style="margin: 15px 0"
      >
        <el-button slot="trigger" size="medium" type="warning"
          >点击上传著作封面图片</el-button
        >
        <div slot="tip" class="el-upload__tip" style="margin-left: 3px">
          支持上传jpg/jpeg/png文件，且不超过500kb
        </div>
      </el-upload>
      <el-form>
        <el-form-item label="著作" width="100px" style="margin-top: 15px">
          <el-input v-model="now_book_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="作者" width="100px" style="margin-top: 15px">
          <el-input v-model="now_writer" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="出版社" width="100px" style="margin-top: 15px">
          <el-input v-model="now_publisher" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="发表时间" width="100px" style="margin-top: 15px">
          <el-input v-model="now_pub_year" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="著作类型" width="100px" style="margin-top: 15px">
          <select v-model="now_book_type" autocomplete="off" name="">
            <option value="book">专著</option>
            <option value="journal">期刊</option>
          </select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogEditVisible = false">取 消</el-button>
        <el-button type="warning" @click="edit_director()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import service from "../utils/request";
export default {
  data() {
    return {
      path: "https://api.movie.yingshinet.com/books/pic/",
      work: [],
      tableData: [],
      //随机数的数组
      randomNum: [],
      scope: [],
      num: 0,
      search_content: "",
      dialog: false,
      dialogEditVisible: false,
      //新添加著作参数
      limit_num: 1,
      paper_type: "",
      writer: "",
      publisher: "",
      figure: "",
      paper_file: [],
      new_pic_id: "",
      make_sure_add: false,
      //修改著作参数
      pic_id: "",
      id: "",
      now_book_name: "",
      now_book_type: "",
      now_writer: "",
      now_publisher: "",
      now_pub_year: "",
      make_sure: false,
      //翻页
      currentPage: 1, //初始页
      pagesize: 10, //    每页的数据
      tableData_length: null,
      //first search
      searchData: [],
      first_search: false,
      sort_button: false,
      isSearch: false,
      all_count: 0,
      list: [],
    };
  },
  watch: {
    search_content: {
      handler: function () {
        if (this.search_content == "") {
          this.isSearch = false;
          this.tableData_length = this.all_count;
        }
      },
    },
  },
  methods: {
    //下载示例
    downloadList() {
      window.location.href =
        "https://assets.yingshinet.com/chronicles/paper_example.xlsx";
    },
    // 搜索功能
    searchHandler() {
      this.isSearch = true;
      const path = "https://api.movie.yingshinet.com/books/select";
      this.content = {
        book_name: this.search_content,
      };
      this.axios
        .post(path, this.content)
        .then((res) => {
          this.searchData = [];
          for (var i = 0; i < res.data.count; i++) {
            this.searchData[i] = {};
            this.searchData[i].no = res.data.data[i].bid;
            this.searchData[i].book_name = res.data.data[i].book_name;
            this.searchData[i].writer = res.data.data[i].writer;
            this.searchData[i].book_type = res.data.data[i].book_type;
            this.searchData[i].pub_city = res.data.data[i].pub_city;
            this.searchData[i].pub_year = res.data.data[i].pub_year;
            this.searchData[i].translator = res.data.data[i].translator;
            this.searchData[i].publisher = res.data.data[i].publisher;
            this.searchData[i].director = res.data.data[i].director;
            this.tableData[i].random =
              i + 1 + (this.currentPage - 1) * this.pagesize;
            this.searchData[i].publisher = res.data.data[i].publisher;
            if (res.data.data[i].book_pic != null) {
              //console.log(res.data.data[i].image)
              this.searchData[i].book_pic = res.data.data[i].book_pic;
            } else {
              this.searchData[i].book_pic = null;
            }
          }
          this.list = this.searchData;
          this.searchData = this.list.slice(
            (this.currentPage - 1) * this.pagesize,
            this.currentPage * this.pagesize
          );
          // 获取查询的结果，把数组长度赋值给分页组件中的total
          this.tableData_length = this.list.length;
          this.$set(this.searchData);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    sort_rise() {
      this.tableData.reverse();
      this.sort_button = false;
    },
    sort_drop() {
      this.tableData.reverse();
      this.sort_button = true;
    },
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange(size) {
      this.pagesize = size;
    },
    // handleCurrentChange(currentPage) {
    //   this.currentPage = currentPage;
    // },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      if (this.isSearch) {
        // && this.tableData.length!=0
        //如果是搜索功能的话，是一次性返回全部数据，需要每次换页的时候分页显示
        this.searchData = this.list.slice(
          (this.currentPage - 1) * this.pagesize,
          this.currentPage * this.pagesize
        );
        // 获取查询的结果，把数组长度赋值给分页组件中的total
        //this.tableData_length = list.length;
        this.$set(this.searchData);
      } else {
        const path = "https://api.movie.yingshinet.com/paper";
        this.axios
          .post(path, { divide: 1, page: currentPage, count: 10 })
          .then((res) => {
            this.work = res.data.all_paper;
            this.num = res.data.cur_subtitle_count;
            this.tableData_length = res.data.all_subtitle_count;
            this.all_count = res.data.all_subtitle_count;
            this.tableData.length = this.tableData_length;
            this.tableData = [];
            this.getData();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    // 显示确认添加弹窗
    add_director() {
      this.make_sure_add = true;
    },
    //批量上传年谱列表
    ListSubmit() {
      if (this.paper_file.length == 1) {
        let file = this.paper_file[0].raw;
        let pic_param = new FormData(); //form对象
        let config = {
          headers: { "Content-Type": "multipart/form-data" },
        };
        pic_param.append("file", file);
        this.axios
          .post(
            "https://api.movie.yingshinet.com/paper/insertfile/" +
              this.figure +
              "/" +
              this.$store.state.user_name +
              "/" +
              this.paper_type,
            pic_param,
            config
          )
          .then((response) => {
            if (response.status == "200") {
              this.$message({
                message: "论文文件上传成功！",
                type: "success",
              });
              location.reload();
            } else {
              this.$message({
                message: "论文文件上传出了点问题",
                type: "warning",
              });
            }
          });
      }
    },
    //上传图片钩子函数
    handleRemove(file, fileList) {
      this.paper_file.length = 0;
      //console.log(file, fileList);
    },
    handlePreview(file) {
      //console.log(file);
    },
    handleChange(file, fileList) {
      this.paper_file.push(file);
    },
    getData() {
      for (var i = 0; i < this.num; i++) {
        this.tableData[i] = {};
        this.tableData[i].no = this.work[i].pid;
        this.tableData[i].figure = this.work[i].figure;
        this.tableData[i].writer = this.work[i].writer;
        this.tableData[i].title = this.work[i].content;
        this.tableData[i].source = this.work[i].source;
        this.tableData[i].random =
          i + 1 + (this.currentPage - 1) * this.pagesize;
        this.tableData[i].paper_type = this.work[i].paper_type;
      }
      this.$set(this.tableData);
    },
    compare(attr) {
      return function (a, b) {
        var val1 = a[attr];
        var val2 = b[attr];
        return val1 - val2;
      };
    },
    handleEdit(index, row) {
      console.log(row);
      console.log(index);
      this.pic_id = row.no;
      this.id = row.no;
      this.now_book_name = row.name;
      this.now_writer = row.writer;
      this.now_pub_year = row.year;
      this.now_publisher = row.publisher;
      this.dialogEditVisible = true;
      this.now_book_type = row.book_type;
    },
    //著作编辑
    edit_director() {
      this.make_sure = true;
    },
    make_sure_click() {
      this.make_sure = false;
      this.dialogEditVisible = false;
      //信息上传
      service({
        url: "/books/update",
        method: "post",
        data: {
          id: this.id,
          now_book_name: this.now_book_name,
          now_writer: this.now_writer,
          now_pub_year: this.now_pub_year,
          now_publisher: this.now_publisher,
          now_book_type: this.now_book_type,
        },
      }).then((response) => {
        if (response.status == "200") {
          (this.book_name = this.now_book_name),
            (this.book_type = this.now_book_type),
            (this.writer = this.now_writer),
            (this.pub_year = this.now_pub_year),
            (this.publisher = this.now_publisher);
          this.$message({
            message: "基本信息修改成功！",
            type: "success",
          });
        } else {
          this.$message({
            message: "基本信息修改好像出问题了！",
            type: "warning",
          });
        }
        // 图片上传
        if (this.image_file.length == 1) {
          let file = this.image_file[0].raw;
          let pic_param = new FormData(); //form对象
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          pic_param.append("file", file);
          this.axios
            .post(
              "https://api.movie.yingshinet.com/books/pic/" + this.pic_id,
              pic_param,
              config
            )
            .then((response) => {
              if (response.status == "200") {
                this.$message({
                  message: "著作封面图片修改成功！",
                  type: "success",
                });
                location.reload();
              } else {
                this.$message({
                  message: "著作封面图片好像出了点问题",
                  type: "warning",
                });
              }
            });
        }
      });
    },
  },
  created() {
    // this.getInfo();
    this.handleCurrentChange(1);
  },
};
</script>
<style scoped>
::v-deep .el-button:focus,
.el-button:hover {
  color: #e2a253 !important;
  border-color: #e2a253 !important;
  background-color: #fff6ec !important;
}

.image_style {
  width: 40px;
  height: 55px;
  border-radius: 3px;
  object-fit: fill;
  vertical-align: middle;
}

.image_style:hover {
  z-index: 10;
  transform: scale(3, 3);
  transition: 0.5s transform;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 8px 8px 20px #aaaaaa;
}
:deep(.table .el-table__header th.el-table__cell) {
  background-color: #606266 !important;
  color: #F0EBE1 !important;
}

:deep(.table .el-table__body td.el-table__cell) {
  background-color: #606266 !important;
  color: #F0EBE1 !important;
}

:deep(.table .el-table__body tr:hover > td.el-table__cell) {
  background-color: #606266cf !important;
}

/* 空状态 */
:deep(.table .el-table__empty-block) {
  background-color: #606266 !important;
}

:deep(.table .el-table__empty-text) {
  color: #F0EBE1 !important;
}

/* 底下的分页条 */
:deep(.el-pagination__total),
:deep(.el-pagination button:disabled),
:deep(.el-pager li.btn-quicknext),
:deep(.el-pager li.btn-quickprev),
:deep(.el-pagination__jump) {
  color: #F0EBE1;
}

:deep(.el-pagination button:disabled),
:deep(.el-pager li),
:deep(.el-pagination .btn-next),
:deep(.el-pagination .btn-prev) {
  background-color: #606266;
  color: #F0EBE1;
}

:deep(.el-pager li:hover) {
  color: #e2a253;
}

:deep(.el-pager li.active) {
  color: #e2a253;
}
</style>