<template>
  <div style="padding: 5px">
    <div align="center" style="margin-top: 10px">
      <div style="width: fit-content">
        <span
          style="
            font-size: 60px;
            color: #F0EBE1;
            font-family: STsong;
            font-weight: bold;
          "
          align="center"
          >{{ this.post_name }}</span
        >
        <div style="height: 6px; background-color: #e2a253"></div>
        <div
          style="height: 2px; background-color: #e2a253; margin-top: 2px"
        ></div>
        <div
          style="
            font-size: 22px;
            color: #F0EBE1;
            font-family: STsong;
            font-weight: bold;
          "
        >
          {{ this.brief }}
        </div>
      </div>
    </div>

    <el-row :gutter="8" style="margin-top: 20px">
      <el-col :span="18">
        <!-- 标签详情页子组件 -->
        <post-info
          v-bind:entity_direction="first_content"
          v-bind:current_id="current_id"
        ></post-info>
      </el-col>
      <!-- 右布局 -->
      <el-col :span="6">
        <div>
          <!-- 影人列表 -->
          <el-card
            style="
              box-shadow: 3px 4px 1px -2px rgb(0 0 0 /16%),
                0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
              height: 77vh;
              background-color: rgba(255, 255, 255, 0.1);
              color: #F0EBE1;
            "
          >
            <div class="el_card" slot="header" style="height: 60px">
              <el-input
                class="e-input"
                placeholder="搜索posts"
                v-model="post_search"
                style="width: 80%; margin: 10px 0px"
              ></el-input>
            </div>
            <div style="overflow-y: auto">
              <el-row>
                <el-col
                  :span="7"
                  v-for="(title, index) in search_data"
                  :key="index"
                  class="text_item"
                >
                  <span class="mouse_over" @click="titleClick(title)">{{
                    title
                  }}</span>
                </el-col>
              </el-row>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <!-- <span class="elebutton">
      <el-button style="color: #b8860b; box-shadow: 3px 3px 10px #aaaaaa" class="el-backtop" icon="el-icon-plus"
        @click="dialog = true" circle v-if="this.$store.state.login_state"></el-button>
    </span> -->
    <!-- 编辑页面 -->
    <el-dialog
      class="el-dia"
      title="请添加影人信息："
      :visible.sync="dialog"
      width="100%"
      height="100%"
      fullscreen
    >
      <el-dialog
        title="提示"
        :visible.sync="make_sure_add"
        width="50%"
        append-to-body
      >
        <span>确定要添加信息？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="make_sure_add = false">取 消</el-button>
          <el-button type="warning" @click="make_sure_addclick"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <el-upload
        class="upload-demo"
        ref="upload"
        list-type="picture"
        action="alert"
        :limit="limit_num"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :on-change="handleChange"
        :auto-upload="false"
        style="margin: 15px 0"
      >
        <el-button slot="trigger" size="medium" type="warning"
          >点击上传影人图片</el-button
        >
        <div slot="tip" class="el-upload__tip" style="margin-left: 3px">
          支持上传jpg/jpeg/png文件，且不超过500kb
        </div>
      </el-upload>
      <el-form>
        <el-form-item label="影人" width="100px" style="margin-top: 5px">
          <el-input v-model="director_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="诞辰"
          width="100px"
          style="margin-top: 5px; width: 30%; display: inline-block"
        >
          <el-date-picker
            v-model="director_brith_time"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy年MM月dd日"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>

        <el-form-item
          label="配偶"
          width="100px"
          style="margin: 5px 0 0 30px; width: 30%; display: inline-block"
        >
          <el-input v-model="director_spouse" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="性别"
          width="100px"
          style="margin: 5px 0 0 30px; width: 20%; display: inline-block"
        >
          <el-radio-group v-model="director_sex">
            <el-radio label="男"></el-radio>
            <el-radio label="女"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="简介" width="100px" style="margin-top: 5px">
          <el-input v-model="director_brief" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="详述" width="100px" style="margin-top: 5px">
          <el-input
            type="textarea"
            :rows="8"
            v-model="dir_direction"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="warning" @click="add_director()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { findIndex } from "@better-scroll/shared-utils";
import PostInfo from "../components/director/post_info.vue";
import service from "../utils/request";
export default {
  components: {
    PostInfo,
  },
  data() {
    return {
      //这个标签下的全部posts
      term_posts: [],
      //第一个content
      first_content: "",
      director_sorted: [],
      //所有的titles
      all_posts_titles: [],
      //所有的contents
      all_posts_contents: [],
      //所有的ID
      all_IDs: [],
      //当前的ID
      current_id: "",
      director_sex: "",
      post_name: "",
      brief: "",
      spouse: "",
      post_search: "",
      direction: "",
      movie_pics: [],
      movie_names: [],
      limit_num: 1,
      url: "",
      essays: [],
      dir_id: 0,
      srcList: [],
      path: "https://api.movie.yingshinet.com",
      image_file: [],
      dialog: false,
      director_name: "",
      director_brith_time: "",
      director_brief: "",
      director_spouse: "",
      dir_direction: "",
      dir_sex: "",
      make_sure_add: false,
      new_pic_id: "",
      color: "#b8860b",
    };
  },
  computed: {
    // 搜索
    search_data: {
      get() {
        if (!this.post_search.trim()) {
          return this.all_posts_titles;
        }
        return this.all_posts_titles.filter(
          (data) =>
            data &&
            data.toLowerCase().includes(this.post_search.trim().toLowerCase())
        );
      },
    },
  },
  watch: {
    $route(to, from) {
      console.log("路由变化");
      //监听路由是否变化
      if (to.query.id != from.query.id) {
        this.axios
          .post("https://api.movie.yingshinet.com/termsearch", {
            term_name: to.query.name,
          })
          .then((response) => {
            // this.moviedata = response.data.all_terms_search;
            // this.getInfo(this.moviedata.m_director);
            console.log(response.data);
          });
      }
    },
  },
  methods: {
    //按字母顺序排序
    sortChinese(obj) {
      obj.sort(function (item1, item2) {
        return item1.localeCompare(item2, "zh-CN"); // localeCompare为string内置函数
      });
    },
    // 显示确认添加弹窗
    add_director() {
      console.log(this.director_brith_time);
      this.make_sure_add = true;
    },
    // 确认添加
    make_sure_addclick() {
      this.make_sure_add = false;
      this.dialog = false;

      //信息上传
      service({
        url: "/figures/insert",
        method: "post",
        data: {
          f_name: this.director_name,
          birth_time: this.director_brith_time,
          f_work: this.dir_direction,
          f_brief: this.director_brief,
          sex: this.director_sex,
          spouse: this.director_spouse,
        },
      }).then((response) => {
        if (response.data.msg == "insert success") {
          this.new_pic_id = response.data.id;
          this.$message({
            message: "添加成功！",
            type: "success",
          });
          // 图片上传
          if (this.image_file.length == 1) {
            let file = this.image_file[0].raw;
            let pic_param = new FormData(); //form对象
            let config = {
              headers: { "Content-Type": "multipart/form-data" },
            };
            pic_param.append("file", file);
            this.axios
              .post(
                "https://api.movie.yingshinet.com/figures/pic/" +
                  this.new_pic_id,
                pic_param,
                config
              )
              .then((response) => {
                if (response.data.msg == "success") {
                  this.$message({
                    message: "影人照片上传成功！",
                    type: "success",
                  });
                  location.reload();
                } else {
                  this.$message({
                    message: "影人照片好像出了点问题",
                    type: "warning",
                  });
                }
              });
          }
        } else {
          this.$message({
            message: "添加有点问题！",
            type: "warning",
          });
        }
      });
    },
    //上传图片钩子函数
    handleRemove(file, fileList) {
      this.image_file.length = 0;
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleChange(file, fileList) {
      this.image_file.push(file);
    },
    // title点击
    titleClick(title) {
      const index = this.all_posts_titles.indexOf(title);
      // console.log(index)
      this.first_content = this.all_posts_contents[index];
      this.post_name = this.all_posts_titles[index];
      this.current_id = this.all_IDs[index];
    },
   
  },
  created() {
    this.axios
      .post("https://api.movie.yingshinet.com/termsearch", {
        term_name: this.$route.query.name,
      })
      .then((response) => {
        this.term_posts = response.data.all_terms_search;
        this.first_content = this.term_posts[0].contents;
        this.post_name = this.term_posts[0].titles;
        this.all_posts_titles = []
        var j = 0; 
        for (var i = 0; i < this.term_posts.length; i++) {
          // 判断：如果有重复的title，就取最长的content
          const existindex = this.all_posts_titles.indexOf(
            this.term_posts[i].titles
          );
          if (existindex != -1) {
            //存在重复的title，那么判断content
            const existlen = this.all_posts_contents[existindex].length;
            const newlen = this.term_posts[i].contents.length;
            if (existlen < newlen) {
              this.all_posts_contents[existindex] = this.term_posts[i].contents;
              this.all_IDs[existindex] = this.term_posts[i].ID;
            }
          } else {
            this.all_posts_titles[j] = this.term_posts[i].titles;
            this.all_posts_contents[j] = this.term_posts[i].contents;
            this.all_IDs[j] = this.term_posts[i].ID;
            j = j + 1;
          }
        }
        this.current_id = this.all_IDs[0];
      });
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}
</style>

<style>
.text_item {
  font-size: 15px;
  margin-bottom: 12px;
  margin-right: 12px;
}

.mouse_over:hover {
  color: #b8860b;
  cursor: pointer;
}

.el-card .el-card__header {
  padding: 8px 20px;
}

.e-input {
  text-align: left;
  border-radius: 5px;
  color: #606266;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  padding-left: 8px;
  outline: none;
}

.elebutton .el-backtop {
  right: 36px;
  bottom: 30px;
  width: 50px;
  height: 50px;
  font-size: 28px;
}

.back_image {
  background-image: url("/static/back1.jpg");
  background-size: cover;
}
</style>
