<template>
  <div>
    <!-- 纵向年谱列表 -->
    <div
      :class="
        !this.$store.state.isCollapse ? 'chronicleList' : 'chronicleList-mobile'
      "
    >
      <!-- 年谱列表搜索框 -->
      <el-card
        :class="
          !this.$store.state.isCollapse ? 'listSearch' : 'listSearch-mobile'
        "
      >
        <!-- 年谱名字随选择的影人director变化 -->
        <!-- <span style="font-size: 1.4vw"> {{ director }}年谱</span> -->
        <img
          :src="currentFigureImage"
          :class="
            !this.$store.state.isCollapse
              ? 'director_img'
              : 'director_img-mobile'
          "
        />
        <el-select
          :class="
            !this.$store.state.isCollapse
              ? 'director_select'
              : 'director_select-mobile'
          "
          v-model="director_select"
          filterable
          placeholder="选择影人"
        >
          <el-option
            v-for="(director, index) in directors"
            :key="index"
            :label="director"
            :value="director"
          >
          </el-option>
        </el-select>
        <el-input
          class="keywordSearchInput"
          v-model="keywordSearch"
          placeholder="年谱内容搜索"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            id="search"
            @click="searchEvents"
          ></el-button>
        </el-input>
        <span class="elebutton">
          <el-button
            style="color: #b8860b; box-shadow: 3px 3px 10px #aaaaaa"
            class="el-backtop"
            icon="el-icon-plus"
            @click="dialog = true"
            circle
            v-if="this.$store.state.login_state"
          ></el-button>
        </span>
        <el-dialog
          title="添加新的事件：时间、地点、人物、事件，四个最重要的元数据。"
          width="90%"
          height="100%"
          :visible.sync="dialog"
          style="font-size: 1.4vw; text-align: left"
          class="base_color"
        >
          <div style="margin: 20px">
            <el-form>
              <el-form-item label="日期" :label-width="formLabelWidth">
                <el-input style="width: 20%" v-model="c_year"></el-input>年
                <el-input style="width: 20%" v-model="c_month"></el-input>月
                <el-input style="width: 20%" v-model="c_date"></el-input>日
              </el-form-item>
              <span
                style="
                  font-size: 10px;
                  color: firebrick;
                  margin-left: 50px;
                  margin-bottom: 0px;
                "
                >提示：如果没有准确日期，请输入当年的0月或0日，如：1900年0月0日，1900年1月0日</span
              >
              <el-form-item
                style="margin-top: 20px"
                label="影人"
                :label-width="formLabelWidth"
              >
                <el-select
                  v-model="director_select"
                  filterable
                  placeholder="选择影人"
                >
                  <el-option
                    v-for="(director, index) in directors"
                    :key="index"
                    :label="director"
                    :value="director"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                style="margin: 20px 0"
                label="内容 "
                :label-width="formLabelWidth"
              >
                <el-input
                  v-model="event_content"
                  type="textarea"
                  :autosize="{ minRows: 6, maxRows: 16 }"
                ></el-input>
              </el-form-item>
              <el-form-item
                style="margin: 20px 0"
                label="来源"
                :label-width="formLabelWidth"
              >
                <el-input
                  v-model="event_source"
                  type="textarea"
                  :autosize="{ minRows: 3, maxRows: 8 }"
                ></el-input>
                <span
                  style="font-size: 10px; color: firebrick; margin-bottom: 0px"
                  >提示：保证学术严谨性，请您注明词条的文献出处。</span
                >
              </el-form-item>
              <el-form-item>
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  list-type="picture"
                  action="alert"
                  :limit="limit_num"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :on-change="handleChange"
                  :auto-upload="false"
                  style="margin: 15px 0"
                >
                  <el-button type="warning">点击上传事件图片</el-button>
                  <span
                    style="
                      font-size: 10px;
                      color: firebrick;
                      margin-bottom: 0px;
                    "
                  >
                    支持上传jpg/jpeg/png文件，且不超过1mb
                  </span>
                </el-upload>
              </el-form-item>
            </el-form>
            <div style="width: 100%">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <el-button
                  style="
                    transition: 0.1s;
                    font-weight: 500;
                    padding: 12px 20px;
                    font-size: 14px;
                    border-radius: 4p;
                  "
                  @click="cancelForm"
                  >取 消</el-button
                >
                <el-button
                  style="
                    transition: 0.1s;
                    font-weight: 500;
                    padding: 12px 20px;
                    font-size: 14px;
                    border-radius: 4p;
                  "
                  type="warning"
                  @click="handleClose"
                  :loading="loading"
                  >{{ loading ? "提交中 ..." : "确 定" }}</el-button
                >
              </div>
            </div>
          </div>
        </el-dialog>
      </el-card>
      <!-- 时间轴列表 -->
      <el-tabs
        v-model="activeOrder"
        class="timelineorder"
        @tab-click="handleTabClick"
      >
        <el-tab-pane label="时间顺序" name="timeorder">
          <!-- <Chronicle-timeline :director="director" :directors="directors" v-if="tab_pane_order.timeorder"></Chronicle-timeline> -->
          <el-timeline class="timeline infinite-list">
            <!-- 时间列表的每一项 -->
            <el-timeline-item
              v-for="(eventItem, index) in replaceArr"
              :key="eventItem.cid"
            >
              <el-card>
                <div>
                  <!-- 内容 -->
                  <span
                    class="itemdata"
                    v-html="allData[index].c_date + '，' + eventItem.content"
                  ></span>

                  <span class="itemfont"
                    ><div style="font-size: 1.05vw">
                      【创建人】: {{ allData[index].c_create_man }}&nbsp;
                    </div>
                    <div style="font-size: 1.05vw">
                      【来源】: {{ allData[index].c_source }}
                    </div>
                    <div v-if="itemLength <= 1" style="font-size: 1.05vw">
                      【说明】: 本词条来自【{{
                        allData[index].c_figure_origin
                      }}】的年谱
                    </div>
                    <div
                      v-show="allData[index].c_pic != null"
                      style="
                        float: right;
                        margin-right: 0.5vw;
                        font-size: 1vw;
                        margin-top: 0.2vw;
                        cursor: pointer;
                      "
                      @click="showImage(allData[index].c_pic)"
                    >
                      <el-image
                        :src="allData[index].c_pic"
                        style="width: 4vw"
                      />
                    </div>
                  </span>
                  <el-dialog
                    :visible.sync="isshowimage"
                    style="width: 80%; margin: 20px auto 0 auto"
                  >
                    <img :src="imgsrc" style="width: 80%" />
                  </el-dialog>
                  <span
                    v-if="
                      $store.state.auth == 1 ||
                      ($store.state.auth == 2 &&
                        allData[index].c_create_man == $store.state.user_name)
                    "
                    style="
                      float: right;
                      margin-right: 0vw;
                      font-size: 1.2vw;
                      margin-top: 0.2vw;
                    "
                    class="el-icon-delete mouse_over_head"
                    @click="deleteEventItem(allData[index])"
                  ></span>
                  <span
                    v-if="
                      $store.state.login_state &&
                      allData[index].c_create_man == $store.state.user_name
                    "
                    style="
                      float: right;
                      margin-right: 0.5vw;
                      font-size: 1vw;
                      margin-top: 0.2vw;
                    "
                    class="el-icon-edit mouse_over_head"
                    @click="editEventIem(allData[index])"
                  ></span>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </el-tab-pane>
        <el-tab-pane label="修改顺序" name="updateorder">
          <Chronicle-update
            style="width: auto"
            :director="director_select"
            :directors="directors"
            v-if="tab_pane_order.updateorder"
          ></Chronicle-update>
        </el-tab-pane>
        <el-tab-pane
          label="我的修改"
          name="userupdateorder"
          v-if="$store.state.login_state"
        >
          <Chronicle-user
            style="width: auto"
            :director="director_select"
            :directors="directors"
            v-if="tab_pane_order.userupdateorder"
          ></Chronicle-user>
        </el-tab-pane>
        <el-tab-pane label="社交网络" name="socialnetwork">
          <Chronicle-net
            style="width: auto;"
            :director="director_select"
            :directors="directors"
            v-if="tab_pane_order.socialnetwork">
          </Chronicle-net>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 删除确认框 -->
    <el-dialog title="确认删除" :visible.sync="delete_dialogEditVisible">
      <div>是否确认删除该条年谱数据？</div>
      <div slot="footer" class="dialog-footer">
        <el-button
          style="
            transition: 0.1s;
            font-weight: 250;
            padding: 8px 12px;
            font-size: 7px;
            border-radius: 4p;
          "
          @click="delete_dialogEditVisible = false"
          >取 消</el-button
        >
        <el-button
          style="
            transition: 0.1s;
            font-weight: 250;
            padding: 8px 12px;
            font-size: 7px;
            border-radius: 4p;
          "
          type="warning"
          @click="confirm_delete()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 编辑页面遮罩层 -->
    <el-dialog
      class="el-dia"
      width="90%"
      height="100%"
      title="年谱编辑：时间、地点、人物、事件，四个最重要的元数据。"
      :visible.sync="dialogEditVisible"
    >
      <!-- 影人信息表单 -->
      <el-form>
        <el-form-item label="日期" width="10vw">
          <el-input style="width: 20%" v-model="update_year"></el-input>年
          <el-input style="width: 20%" v-model="update_month"></el-input>月
          <el-input style="width: 20%" v-model="update_date"></el-input>日
          <span
            style="
              font-size: 10px;
              color: firebrick;
              margin-left: 20px;
              margin-bottom: 0px;
            "
            >提示：如果没有准确日期，请输入当年的0月或0日，如：1900年0月0日，1900年1月0日</span
          >
        </el-form-item>
        <!-- 原文不能修改做参考 -->
        <el-form-item label="原文" width="10vw">
          <el-popover
            placement="bottom-end"
            width="190"
            trigger="hover"
            content="请将修改结果填入下方输入框"
            style="font-size: 1vw"
          >
            <el-input
              type="textarea"
              :rows="6"
              v-model="eventItemContent"
              autocomplete="off"
              :readonly="true"
              :disabled="true"
              slot="reference"
            >
              ></el-input
            >
          </el-popover>
        </el-form-item>
        <el-form-item label="修改为" width="10vw">
          <el-input
            type="textarea"
            :rows="6"
            v-model="tmpEventItemContent"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="来源" width="10vw">
          <el-input
            type="textarea"
            :rows="6"
            v-model="new_source"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-upload
            class="upload-demo"
            ref="upload"
            list-type="picture"
            action="alert"
            :limit="limit_num"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-change="handleChange"
            :auto-upload="false"
            style="margin: 15px 0"
          >
            <el-button type="warning">点击上传事件图片</el-button>
            <span style="font-size: 10px; color: firebrick; margin-bottom: 0px">
              支持上传jpg/jpeg/png文件，且不超过1mb
            </span>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          style="
            transition: 0.1s;
            font-weight: 500;
            padding: 12px 20px;
            font-size: 14px;
            border-radius: 4p;
          "
          @click="dialogEditVisible = false"
          >取 消</el-button
        >
        <el-button
          style="
            transition: 0.1s;
            font-weight: 500;
            padding: 12px 20px;
            font-size: 14px;
            border-radius: 4p;
          "
          type="warning"
          @click="updateclick"
          >确 定</el-button
        >
      </div>

      <!-- 编辑页面确认弹窗 -->
      <el-dialog
        title="提示"
        :visible.sync="make_sure"
        width="30%"
        append-to-body
      >
        <span>确定要修改信息？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="make_sure = false">取 消</el-button>
          <el-button type="warning" @click="make_sure_click">确 定</el-button>
        </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import service from "../../utils/request";
import Chronicle from "../../views/Chronicle.vue";
import Chronicle_barchart from "./chronicle_barchart.vue";
import ChronicleUpdate from "./chronicle_update.vue";
import ChronicleUser from "./chronicle_user.vue";
import ChronicleNet from "./chronicle_net.vue";

export default {
  components: {
    Chronicle,
    Chronicle_barchart,
    ChronicleUpdate,
    ChronicleUser,
    ChronicleNet,
  },
  name: "chronicle_list",
  inject: ["reload"],
  data() {
    return {
      // 编辑模版
      //遮罩层是否显示
      delete_dialogEditVisible: false,
      dialogEditVisible: false,
      make_sure: false,
      colors: ["#b8860b", "#778899"],
      directors: [
        //40位影人名单
      ],
      dirctors_pic: [
        //40位影人照片
      ],
      allData: [], //所有年谱数据，数组['','','',...]
      allData_by_update: [], //用户修改数据
      userData: [],
      keywordSearch: "",
      MonthSearch: "",
      YearSearch: "",
      path: "https://api.movie.yingshinet.com/chronicles",
      currentFigureImage: "",
      path_currency: "https://api.movie.yingshinet.com",
      dialog: false,
      loading: false,
      edit_event_item: "",
      eventItemContent: "",
      tmpEventItemContent: "",
      movie: [],
      image_file: [],
      othermovie: [],
      otheritemLength: 0,
      itemLength: 0,
      updatedata_length: 0,
      imgsrc: "",
      userdata_length: 0,
      formLabelWidth: "50px",
      date_value: new Date(1937, 0, 1),
      date_value_two: "",
      event_content: "",
      event_source: "",
      updata_date_value: "",
      isshowimage: false,
      chronicle_id: "",
      update_date_string: "",
      date_value_three: "",
      update_year: "",
      update_month: "",
      update_date: "",
      update_source: "",
      tmpupdate_source: "",
      director_select: "",
      director: "",
      tmpdirector: "", //添加事件时产生bug,矫正vuex中的director
      c_year: "",
      c_month: "",
      c_date: "",
      str: "",
      limit_num: 1,
      new_source: "",
      delete_item: "",
      //timeline order
      activeOrder: "timeorder",
      tab_pane_order: {
        timeorder: true,
        updateorder: false,
        userupdateorder: false,
        socialnetwork: false,
      },
    };
  },
  watch: {
    "$store.state.keywordSearch": {
      handler(val, oldVal) {
        this.keywordSearch = val;
      },
    },
    "$store.state.MonthSearch": {
      handler(val, oldVal) {
        this.MonthSearch = val;
        this.searchEvents();
      },
    },
    "$store.state.YearSearch": {
      handler(val, oldVal) {
        this.YearSearch = val;
        this.searchEvents();
      },
    },
    "$store.state.current_figure": {
      handler(val, oldVal) {
        this.searchEvents();
      },
    },
    keywordSearch: {
      handler(val, oldVal) {
        this.searchEvents();
      },
    },
    MonthSearch: {
      handler(val, oldVal) {
        this.searchEvents();
      },
    },
    director_select: {
      handler(val, oldVal) {
        const number = this.directors.indexOf(val);
        if (number != -1) {
          this.currentFigureImage = this.dirctors_pic[number];
        }
        this.$store.commit("set_current_figure", this.director_select);
        this.$store.commit("set_current_figure_pic", this.currentFigureImage);
        this.$store.commit("setkeywordSearch", "");
        this.$store.commit("setMonthSearch", "");
        this.searchEvents();
      },
    },
  },
  methods: {
    showImage(pic) {
      this.isshowimage = true;
      this.imgsrc = pic;
    },
    handleTabClick(tab) {
      this.activeOrder = tab.name;
      switch (this.activeOrder) {
        case "timeorder":
          this.switchtab("timeorder");
          break;
        case "updateorder":
          this.switchtab("updateorder");
          break;
        case "userupdateorder":
          this.switchtab("userupdateorder");
          break;
        case "socialnetwork":
          this.switchtab("socialnetwork");
          break;
      }
    },
    switchtab(tab) {
      for (let i in this.tab_pane_order) {
        if (i === tab) {
          this.tab_pane_order[i] = true;
        } else {
          this.tab_pane_order[i] = false;
        }
      }
    },
    //按年份和月份时间顺序排序
    sortYear(obj) {
      return obj.sort(function (a, b) {
        let x = a["c_year"];
        let y = b["c_year"];
        if (x < y) return -1;
        else if (x > y) return 1;
        else {
          let c = a["c_month"];
          let d = b["c_month"];
          return c < d ? -1 : c > d ? 1 : 0;
        }
      });
    },
    async handleClose(done) {
      if (
        this.director_select != "" &&
        this.date_value != null &&
        this.event_content.trim() != "" &&
        this.event_source.trim() != ""
      ) {
        if (this.loading) {
          return;
        }
        this.$confirm("确定要添加事件吗？")
          .then((_) => {
            const tmpdirector = this.$store.state.current_figure;
            // const tmpmonth=this.$store.state.month
            this.loading = true;
            this.data_string =
              this.c_year + "年" + this.c_month + "月" + this.c_date + "日";
            this.director_select = service({
              url: "/chronicles/insert",
              method: "post",
              data: {
                // c_year: this.date_value_two.getFullYear(),
                // c_month: this.date_value_two.getMonth() + 1,
                c_year: this.c_year,
                c_month: this.c_month,
                c_date: this.data_string,
                //传影人参数
                c_figure: this.director_select,
                c_source: this.event_source,
                c_content: this.event_content,
              },
            }).then((response) => {
              if (response.data.msg == "Success") {
                this.new_pic_id = response.data.id;
                if (this.image_file.length == 1) {
                  let file = this.image_file[0].raw;
                  let pic_param = new FormData(); //form对象
                  let config = {
                    headers: { "Content-Type": "multipart/form-data" },
                  };
                  pic_param.append("file", file);
                  this.axios
                    .post(
                      "https://api.movie.yingshinet.com/chronicles/pic/" +
                        this.new_pic_id,
                      pic_param,
                      config
                    )
                    .then((response) => {
                      if (response.data.msg == "success") {
                        this.$message({
                          message: "影人照片上传成功！",
                          type: "success",
                        });
                        location.reload();
                      } else {
                        this.$message({
                          message: "影人照片好像出了点问题",
                          type: "warning",
                        });
                      }
                    });
                } else {
                  this.$message({
                    message: "添加有点问题！",
                    type: "warning",
                  });
                }
                this.$store.commit("set_current_figure", tmpdirector);
                this.director = tmpdirector;
                this.director_select = tmpdirector;
                setTimeout(() => {
                  this.$message({
                    message: "添加成功！",
                    type: "success",
                  });
                }, 1000);
              } else {
                this.$message({
                  message: "添加失败！",
                  type: "warning",
                });
              }
            });
            this.timer = setTimeout(() => {
              // 动画关闭需要一定的时间
              setTimeout(() => {
                this.loading = false;
                this.dialog = false;
                // alert(`${this.$store.state.current_figure}`);
                this.getEvents();
              }, 400);
            }, 2000);
          })
          .catch((_) => {
            this.dialog = false;
          });
      } else {
        this.$message({
          message: "请输入完整信息！",
          type: "warning",
        });
      }
    },
    // 删除某条年谱
    confirm_delete() {
      this.axios.post(this.path + "/delete", {
        cid: this.delete_item.c_id,
        auth: this.$store.state.auth,
        name: this.$store.state.user_name,
      });
      this.delete_dialogEditVisible = false;
      this.reload();
      // this.$router.go(0)
    },
    deleteEventItem(eventItem) {
      this.delete_dialogEditVisible = true;
      this.delete_item = eventItem;
    },
    // 修改具体年谱的某一条
    editEventIem(eventItem) {
      this.chronicle_id = eventItem.c_id;
      this.eventItemContent = eventItem.content;
      this.tmpEventItemContent = eventItem.content;
      // this.update_date_string = eventItem.c_date;
      this.update_year = eventItem.c_year;
      this.update_month = eventItem.c_month;
      this.new_source = eventItem.c_source;
      this.str = eventItem.c_date.split("月")[1];
      this.str = this.str.split("日")[0];
      this.update_date = this.str;
      this.dialogEditVisible = true;
      },

      updateclick() {
          this.make_sure = true;
      },
    //编辑最终确认
      make_sure_click () {
          console.log("最终确认");
      //this.make_sure = false;
      this.dialogEditVisible = false;
      this.update_date_string =
        this.update_year +
        "年" +
        this.update_month +
        "月" +
        this.update_date +
        "日";
      //逻辑
      service({
        url: "/chronicles/update",
        method: "post",
        data: {
          cid: this.chronicle_id,
          c_content: this.tmpEventItemContent,
          c_date: this.update_date_string,
          c_year: this.update_year,
          c_month: this.update_month,
          c_figure: this.director,
          c_refer: "",
          c_source: this.new_source,
        },
      })
        .then((response) => {
          if (response.data.msg == "Success") {
            this.new_pic_id = this.chronicle_id;
            if (this.image_file.length == 1) {
              let file = this.image_file[0].raw;
              let pic_param = new FormData(); //form对象
              let config = {
                headers: { "Content-Type": "multipart/form-data" },
              };
                pic_param.append("file", file);
                console.log(this.new_pic_id,pic_param)
              this.axios
                .post(
                  "https://api.movie.yingshinet.com/chronicles/pic/" +
                    this.new_pic_id,
                  pic_param,
                  config
                )
                .then((response) => {
                  if (response.data.msg == "success") {
                    this.$message({
                      message: "影人照片上传成功！",
                      type: "success",
                    });
                    location.reload();
                  } else {
                    this.$message({
                      message: "影人照片好像出了点问题",
                      type: "warning",
                    });
                  }
                });
            }
            this.$message({
              message: "修改成功！",
              type: "success",
            });
          } else {
            this.$message({
              message: "修改失败！",
              type: "warning",
            });
          }
          location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      clearTimeout(this.timer);
    },
    //上传图片钩子函数
    handleRemove(file, fileList) {
      this.image_file.length = 0;
    },
    handlePreview(file) {},
    handleChange(file, fileList) {
      this.image_file.push(file);
    },
    getEvents() {
      const keyword = this.keywordSearch.trim();
      const keyJson = {};
      if (keyword) {
        keyJson.searchData = keyword;
      }
      keyJson.figure = this.director;
      this.axios.post(this.path + "/keyword", keyJson).then((response) => {
        this.movies = response.data.searchShow;
        //年谱数据movies.c_content
        this.itemLength = this.movies.length;
        this.allData = [];
        for (var i = 0; i < this.itemLength; i++) {
          //获取年谱所有数据
          this.allData[i] = {};
          this.allData[i].content = this.movies[i].c_content;
          this.allData[i].c_year = this.movies[i].c_year;
          this.allData[i].c_month = this.movies[i].c_month;
          this.allData[i].c_figure_origin = this.movies[i].c_figure;
          this.allData[i].c_id = this.movies[i].cid;
          if (this.movies[i].c_pic != null) {
            this.allData[i].c_pic =
              "https://assets.yingshinet.com/chronicles/pictures/" +
              this.movies[i].c_pic;
          }
          if (this.movies[i].c_month == 0) {
            this.allData[i].c_date = this.movies[i].c_date.substring(
              0,
              this.movies[i].c_date.length - 4
            );
          } else if (
            (this.movies[i].c_date.substr(-2, 1) == 0) &
            isNaN(Number(this.movies[i].c_date.substr(-3, 1), 10))
          ) {
            this.allData[i].c_date = this.movies[i].c_date.substring(
              0,
              this.movies[i].c_date.length - 2
            );
          } else {
            this.allData[i].c_date = this.movies[i].c_date;
          }
          this.allData[i].c_create_man = this.movies[i].c_create_man;
          this.allData[i].c_source = this.movies[i].c_source;
          this.allData[i].c_update_man = this.movies[i].c_update_man;
          this.allData[i].c_update_time = this.movies[i].c_update_time;
        }
          this.allData = this.sortYear(this.allData);
        if (this.itemLength <= 1) {
          this.axios
            .post(this.path + "/select", { content_figure: this.director })
            .then((response) => {
              this.othermovies = response.data.c_data;
              this.otheritemLength = this.othermovies.length;
              for (
                var i = this.itemLength;
                i < this.itemLength + this.otheritemLength;
                i++
              ) {
                //获取年谱所有数据
                this.allData[i] = {};
                this.allData[i].content = this.othermovies[i].c_content;
                this.allData[i].c_year = this.othermovies[i].c_year;
                this.allData[i].c_figure_origin = this.othermovies[i].c_figure;
                this.allData[i].c_month = this.othermovies[i].c_month;
                this.allData[i].c_id = this.othermovies[i].cid;
                if (this.othermovies[i].c_pic != null) {
                  this.allData[i].c_pic =
                    "https://assets.yingshinet.com/chronicles/pictures/" +
                    this.movies[i].c_pic;
                }
                if (this.othermovies[i].c_month == 0) {
                  this.allData[i].c_date = this.othermovies[i].c_date.substring(
                    0,
                    this.othermovies[i].c_date.length - 4
                  );
                } else if (
                  (this.othermovies[i].c_date.substr(-2, 1) == 0) &
                  isNaN(Number(this.othermovies[i].c_date.substr(-3, 1), 10))
                ) {
                  this.allData[i].c_date = this.othermovies[i].c_date.substring(
                    0,
                    this.othermovies[i].c_date.length - 2
                  );
                } else {
                  this.allData[i].c_date = this.othermovies[i].c_date;
                }
                this.allData[i].c_create_man = this.othermovies[i].c_create_man;
                this.allData[i].c_source = this.othermovies[i].c_source;
                this.allData[i].c_update_man = this.othermovies[i].c_update_man;
                this.allData[i].c_update_time =
                  this.othermovies[i].c_update_time;
              }
              this.allData = this.sortYear(this.allData);
            })
            .catch((e) => {
              console.log(e);
            });
        }
      });
      this.$set(this.allData);
    },
    searchItem() {
      // console.log("searchItem");
      // const keyword = this.director_search.trim();
      const keyword = this.keywordSearch.trim();
      const month = this.MonthSearch.trim();
      const year = this.YearSearch.trim();

      const keyJson = {
        // searchData: "山东",
        // year: 1937,
        // figure: "费穆",
      };
      if (keyword) {
        keyJson.searchData = keyword;
      }
      if (month) {
        keyJson.month = month;
      }
      if (year) {
        keyJson.year = year;
      }
      keyJson.figure = this.$store.state.current_figure;
      this.axios;
      this.axios.post(this.path + "/keyword", keyJson).then((response) => {
        this.movies = response.data.searchShow;
        //年谱数据movies.c_content
        this.itemLength = this.movies.length;
        this.allData = [];
        for (var i = 0; i < this.itemLength; i++) {
          //获取年谱所有数据
          this.allData[i] = {};
          this.allData[i].content = this.movies[i].c_content;
          this.allData[i].c_figure_origin = this.movies[i].c_figure;
          this.allData[i].c_year = this.movies[i].c_year;
          this.allData[i].c_month = this.movies[i].c_month;
          this.allData[i].c_id = this.movies[i].cid;
          if (this.movies[i].c_pic != null) {
            this.allData[i].c_pic =
              "https://assets.yingshinet.com/chronicles/pictures/" +
              this.movies[i].c_pic;
          }
          // 去除0月/0日
          if (this.movies[i].c_month == 0) {
            this.allData[i].c_date = this.movies[i].c_date.substring(
              0,
              this.movies[i].c_date.length - 4
            );
          } else if (
            (this.movies[i].c_date.substr(-2, 1) == 0) &
            isNaN(Number(this.movies[i].c_date.substr(-3, 1), 10))
          ) {
            this.allData[i].c_date = this.movies[i].c_date.substring(
              0,
              this.movies[i].c_date.length - 2
            );
          } else {
            this.allData[i].c_date = this.movies[i].c_date;
          }
          this.allData[i].c_create_man = this.movies[i].c_create_man;
          this.allData[i].c_source = this.movies[i].c_source;
          this.allData[i].c_update_man = this.movies[i].c_update_man;
          this.allData[i].c_update_time = this.movies[i].c_update_time;
        }
        this.allData = this.sortYear(this.allData);
        if (this.itemLength <= 1) {
          this.axios
            .post(this.path + "/select", {
              content_figure: this.$store.state.current_figure,
            })
            .then((response) => {
              this.othermovies = response.data.c_data;
              this.otheritemLength = this.othermovies.length;
              console.log("获取额外的数据");
              for (
                var i = this.itemLength;
                i < this.itemLength + this.otheritemLength;
                i++
              ) {
                //获取年谱所有数据
                this.allData[i] = {};
                this.allData[i].content = this.othermovies[i].c_content;
                this.allData[i].c_figure_origin = this.othermovies[i].c_figure;
                this.allData[i].c_year = this.othermovies[i].c_year;
                this.allData[i].c_month = this.othermovies[i].c_month;
                this.allData[i].c_id = this.othermovies[i].cid;
                if (this.othermovies[i].c_pic != null) {
                  this.allData[i].c_pic =
                    "https://assets.yingshinet.com/chronicles/pictures/" +
                    this.movies[i].c_pic;
                }
                if (this.othermovies[i].c_month == 0) {
                  this.allData[i].c_date = this.othermovies[i].c_date.substring(
                    0,
                    this.othermovies[i].c_date.length - 4
                  );
                } else if (
                  (this.othermovies[i].c_date.substr(-2, 1) == 0) &
                  isNaN(Number(this.othermovies[i].c_date.substr(-3, 1), 10))
                ) {
                  this.allData[i].c_date = this.othermovies[i].c_date.substring(
                    0,
                    this.othermovies[i].c_date.length - 2
                  );
                } else {
                  this.allData[i].c_date = this.othermovies[i].c_date;
                }
                this.allData[i].c_create_man = this.othermovies[i].c_create_man;
                this.allData[i].c_source = this.othermovies[i].c_source;
                this.allData[i].c_update_man = this.othermovies[i].c_update_man;
                this.allData[i].c_update_time =
                  this.othermovies[i].c_update_time;
              }
              this.allData = this.sortYear(this.allData);
            });
        }
      });
      this.$set(this.allData);
    },
    debounce(fn, wait = 1000) {
      let timer;
      return function () {
        let context = this;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          fn.apply(context);
        }, wait);
      };
    },

    searchEvents() {
      let keyword = this.keywordSearch;
      this.$store.commit("setkeywordSearch", keyword);
      this.debounce(this.searchItem, 300)();
    },
  },
  computed: {
    // 替换内容中的关键词为高亮形式;影人\影片实体超链接计算
    replaceArr() {
      const arr = JSON.parse(JSON.stringify(this.allData));
      const colors = this.colors;
      const keywords = this.movie;
      const keywords2 = this.directors;
      keywords.forEach((keywordItem, keywordIndex) => {
        // 匹配关键字正则
        const replaceReg = new RegExp("《" + keywordItem.m_name + "》", "g");
        // 高亮替换v-html值
        // index_movie = this.movie.index(keywordItem)

        const replaceString = `<a  href="/#/video?id=${keywordItem.mid}"><font color=${colors[0]}>《${keywordItem.m_name}》</font></a>`;
        for (let index = 0; index < arr.length; index++) {
          if (arr[index].content && arr[index].content.length) {
            // 开始替换
            arr[index].content = arr[index].content.replace(
              replaceReg,
              replaceString
            );
          }
        }
      });
      keywords2.forEach((keywordItem, keywordIndex) => {
        // 匹配关键字正则
        const replaceReg = new RegExp(keywordItem, "g");
        // 高亮替换v-html值
        const replaceString = `<a  href="/#/directors?name=${keywordItem}"><font color=${colors[0]}>${keywordItem}</font></a>`;
        for (let index = 0; index < arr.length; index++) {
          if (
            // arr[index].content.search('color=') === -1 &&
            arr[index].content &&
            arr[index].content.length
          ) {
            // 开始替换
            arr[index].content = arr[index].content.replace(
              replaceReg,
              replaceString
            );
          }
        }
      });
      return arr;
    },
  },
  created() {
    this.director = this.$store.state.current_figure;

    // 获取影片名
    this.axios.get(this.path_currency + "/movies").then((response) => {
      this.movie = [];
      for (var i = 0; i < response.data.m_data.length; i++) {
        const movie = {};
        movie.m_name = response.data.m_data[i].m_name;
        movie.mid = response.data.m_data[i].mid;
        this.movie.push(movie);
      }
    });
    //获取人物名
    this.axios.get(this.path_currency + "/figures").then((response) => {
      for (var i = 0; i < response.data.f_data.length; i++) {
        this.directors.push(response.data.f_data[i].f_name);
        this.dirctors_pic.push(response.data.f_data[i].f_pic);
      }
    });
    this.axios
      .post(this.path_currency + "/figures/select", { f_name: this.director })
      .then((response) => {
        this.currentFigureImage = response.data.f_data[0].f_pic;
      });
  },
  mounted() {
    this.currentFigureImage = this.$store.state.current_figure_pic;
    this.director_select = this.director;
    this.getEvents();
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 1.1vw;
}

::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}

.chronicle_e_input {
  border-radius: 0.3vw;
  border: 0.07vw solid #b8860b;
  color: #606266;
  height: 5vw;
  line-height: 5vw;
  padding-left: 0.3vw;
  outline: none;
}

.el-col .chronicleList > .el-card {
  margin: 0.5vw;
}

.chronicleList .listSearch {
  width: 31vw;
  position: static;
  margin: 1vw 0 0.8vw 0;
  text-align: center;
  line-height: 1vw;
}

.listSearch .el-card__body {
  padding: 0.2vw;
}

.chronicleList-mobile .listSearch-mobile {
  position: static;
  width: 100%;
  margin: 1vw 0 0.8vw 0;
  text-align: center;
  line-height: 1vw;
}

.listSearch-mobile .el-card__body {
  padding: 0.2vw;
}

.el-timeline {
  width: 100%;
  padding-inline-start: 0vw;
}

.chronicleList .timeline {
  width: auto;
  height: 77vh;
  overflow: scroll;
}

.el-timeline .el-timeline-item {
  padding-bottom: 0vw;
}

.infinite-list .yearformcard {
  height: 8vw;
  overflow: scroll;
}

.yearformcard .el-card__body {
  padding: 0.8vw;
}

.yearformcard p {
  margin: 0vw;
}

.mouse_over_head:hover {
  color: #b8860b;
  cursor: pointer;
}

.elebutton .el-backtop {
  right: 36px;
  bottom: 30px;
  width: 50px;
  height: 50px;
  font-size: 28px;
}

/* 创建人颜色 */
.itemfont {
  color: #a1a3a8;
}

/* 搜索框 */
.keywordSearchInput {
  width: 60%;
  margin-left: 1vw;
}

.keywordSearchInput #search {
  padding: 12px 20px;
  border-radius: 0%;
}
:deep(.el-input-group__append) {
  background-color: #ffc78e;
}
.keywordSearchInput #search-box {
  width: 13.9%;
  /* height: 10%; */
  margin-top: 0px;
  margin-left: 65.4%;
  padding-bottom: 20px;
}

.director_select {
  display: inline-block;
  width: 6vw;
  height: 6vh;
  margin-left: 2vw;
}

.director_select-mobile {
  display: inline-block;
  width: 20vw;
  height: 6vh;
  margin-left: 10vw;
  margin-top: 3vw;
}
.el-card {
  background-color: #606266;
  border: 1px solid #a1a3a8;
}
.itemdata {
  color: #F0EBE1;
}
</style>
<style>
.chronicleList {
  height: 83vh;
  width: 33vw;
  position: relative;
  margin-left: 0vw;
}

.chronicleList .el-card__body {
  padding: 0.5vw;
}

.chronicleList .el-timeline-item__tail,
.el-timeline-item__node {
  margin-left: 0.5vw;
}

.chronicleList .el-timeline-item__wrapper {
  padding-left: 1.3vw;
}

.chronicleList a {
  text-decoration: none;
}

.chronicleList-mobile {
  height: auto;
  width: 99vw;
  position: relative;
  margin-left: 0vw;
}

.chronicleList-mobile .el-card__body {
  padding: 0.5vw;
}

.chronicleList-mobile .el-timeline-item__tail,
.el-timeline-item__node {
  margin-left: 0.5vw;
}

.chronicleList-mobile .el-timeline-item__wrapper {
  padding-left: 1.3vw;
}

.chronicleList-mobile a {
  text-decoration: none;
}

.director_img {
  width: 3vw !important;
  height: 6vh !important;
  left: 1vw !important;
  top: 0.8vh;
  position: absolute;
  border-radius: 50%;
}

.director_img-mobile {
  width: 12vw !important;
  height: 6vh !important;
  left: 1vw !important;
  top: 0.8vh;
  position: absolute;
  border-radius: 50%;
}

.el-tabs__item:hover {
  color: #b8860b !important;
}
.el-tabs__item.is-active {
  color: #b8860b !important;
}
.el-tabs__active-bar {
  background: #b8860b !important;
  /* width: 30px; */
}
.el-tabs__item {
  color: #F0EBE1 !important;
}
</style>
