<template>
  <div>
    <el-table :data="table_data" :key="sort_button" style="width: 90%; margin: 20px auto" :row-style="{ height: '80px' }"
      class="table">
      <el-table-column align="center" label="编号" prop="id" min-width="15%"></el-table-column>
      <el-table-column align="center" label="名称" prop="company_name" min-width="20%"></el-table-column>
      <el-table-column align="center" label="创始人" prop="creator" min-width="15%"></el-table-column>
      <el-table-column align="center" label="负责人" prop="manager" min-width="15%"></el-table-column>
      <el-table-column align="center" label="创建时间" prop="create_time" min-width="12%">
      </el-table-column>


      <el-table-column align="center" min-width="15%">
        <template slot="header" slot-scope="scope">
          <el-input v-model="company_search" size="mini" placeholder="搜索企业名" />
        </template>

        <template slot-scope="scope">
          <el-button size="mini" v-if="$store.state.login_state" type="warning"
            @click="handleEdit(scope.$index, scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination style="text-align:center; margin:5vh 0" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[5, 10, 20, 40]"
      :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="tableData_length"></el-pagination>

    <!-- 添加按钮 -->
    <span class="elebutton">
      <el-button style="color: #b8860b; box-shadow: 3px 3px 10px #aaaaaa" class="el-backtop" icon="el-icon-plus"
        @click="dialog = true" circle v-if="this.$store.state.login_state"></el-button>
    </span>
    <!-- 新添加著作页面 -->
    <el-dialog class="el-dia" title="请添加企业信息：" :visible.sync="dialog" width="100%" height="100%" fullscreen>
      <el-dialog title="提示" :visible.sync="make_sure_add" width="100%" append-to-body>
        <span>确定要添加信息？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="make_sure_add = false">取 消</el-button>
          <el-button type="warning" @click="make_sure_addclick">确 定</el-button>
        </span>
      </el-dialog>

      <el-upload class="upload-demo" ref="upload" list-type="picture" action="alert" :limit="limit_num"
        :on-preview="handlePreview" :on-remove="handleRemove" :on-change="handleChange" :auto-upload="false"
        style="margin: 15px 0">
      </el-upload>
      <el-form>
        <el-form-item label="企业" width="100px" style="margin-top: 15px">
          <el-input v-model="company_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="创始者" width="100px" style="margin-top: 15px">
          <el-input v-model="creator" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="负责人" width="100px" style="margin-top: 15px">
          <el-input v-model="manager" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="创建时间" width="100px" style="margin-top: 15px">
          <el-input v-model="create_time" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="warning" @click="add_director()">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改企业页面 -->
    <el-dialog class="el-dia" title="企业编辑" :visible.sync="dialogEditVisible" fullscreen>
      <el-dialog title="提示" :visible.sync="make_sure" width="30%" append-to-body>
        <span>确定要修改信息？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="make_sure = false">取 消</el-button>
          <el-button type="warning" @click="make_sure_click">确 定</el-button>
        </span>
      </el-dialog>
      <el-upload class="upload-demo" ref="upload" list-type="picture" action :limit="limit_num"
        :on-preview="handlePreview" :on-remove="handleRemove" :on-change="handleChange" :auto-upload="false"
        style="margin: 15px 0">
      </el-upload>


      <el-form>
        <el-form-item label="制片公司" width="100px" style="margin-top: 15px">
          <el-input v-model="now_company_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="创始人" width="100px" style="margin-top: 15px">
          <el-input v-model="now_creator" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="负责人" width="100px" style="margin-top: 15px">
          <el-input v-model="now_manager" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="创始时间" width="100px" style="margin-top: 15px">
          <el-input v-model="now_create_time" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>


      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogEditVisible = false">取 消</el-button>
        <el-button type="warning" @click="edit_director()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import service from "../utils/request";
export default {
  data() {
    return {
      path: "https://api.movie.yingshinet.com/all_company",
      work: [],
      tableData: [],
      scope: [],
      num: 0,
      company_search: "",
      dialog: false,
      dialogEditVisible: false,
      //新添加著作参数
      limit_num: 1,
      company_name: "",
      creator: "",
      manager: "",
      create_time: "",
      make_sure_add: false,
      //修改著作参数
      id: "",
      now_company_name: "",
      now_creator: "",
      now_manager: "",
      now_create_time: "",
      make_sure: false,
      //翻页
      currentPage: 1, //初始页
      pagesize: 10, //    每页的数据
      tableData_length: "",
      //first search
      first_search: false,
      sort_button: false,
    };
  },
  computed: {
    table_data() {
      let company_search = this.company_search;
      if (company_search) {
        //搜索功能
        if (!this.first_search) {
          this.currentPage = 1;
        }
        this.first_search = true;
        // 执行搜索操作之前需要将currentPage进行初始化，否则可能出现查不到数据的情况
        //this.currentPage=1
        let list = this.tableData.filter(
          (data) =>
            !company_search ||
            data.company_name.toLowerCase().includes(company_search.toLowerCase())
        );
        let fenye = list.slice(
          (this.currentPage - 1) * this.pagesize,
          this.currentPage * this.pagesize
        );
        // 获取查询的结果，把数组长度赋值给分页组件中的total
        this.tableData_length = list.length;

        return list, fenye;
      }
      // 分页功能
      else {
        this.first_search = false;
        //所有数据的长度  赋值给分页组件中的total
        this.tableData_length = this.tableData.length;
        let fenye = this.tableData.slice(
          (this.currentPage - 1) * this.pagesize,
          this.currentPage * this.pagesize
        );

        return fenye;
      }

    },
  },
  methods: {
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange(size) {
      this.pagesize = size;
      //console.log(this.pagesize)
    },
    handleCurrentChange(currentPage) {
      //console.log(this.currentPage)
      this.currentPage = currentPage;
    },
    // 显示确认添加弹窗
    add_director() {
      this.make_sure_add = true;
    },
    // 确认添加
    make_sure_addclick() {
      //信息上传
      this.dialog = false;
      this.make_sure_add = false;
      //信息上传
      service({
        url: "https://api.movie.yingshinet.com/company/insert",
        method: "post",
        data: {
          company_name: this.company_name,
          creator: this.creator,
          manager: this.manager,
          create_time: this.create_time,
        },
      }).then((response) => {
        if (response.status == "200") {
          this.$message({
            message: "企业信息添加成功！",
            type: "success",
          });
        } else {
          this.$message({
            message: "企业信息添加好像出问题了！",
            type: "warning",
          });
        }
        location.reload();
      });
    },
    //上传图片钩子函数
    handleRemove(file, fileList) {
      this.image_file.length = 0;
      //console.log(file, fileList);
    },
    handlePreview(file) {
      //console.log(file);
    },
    handleChange(file, fileList) {
      this.image_file.push(file);
    },
    getInfo() {
      const path = "https://api.movie.yingshinet.com/all_company";
      this.axios
        .get(path)
        .then((res) => {
          this.work = res.data;
          this.num = res.data.length;
          this.getData();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getData() {
      for (var i = 0; i < this.num; i++) {
        this.tableData[i] = {};
        this.tableData[i].id = this.work[i].id;
        this.tableData[i].company_name = this.work[i].company_name;
        this.tableData[i].creator = this.work[i].creator;
        this.tableData[i].manager = this.work[i].manager;
        this.tableData[i].create_time = this.work[i].create_time;
        this.tableData_length = this.tableData.length;
        this.$set(this.tableData);
      }
    },
    handleEdit(index, row) {
      this.id = row.id;
      this.now_company_name = row.company_name;
      this.now_creator = row.creator;
      this.now_manager = row.manager;
      this.now_create_time = row.create_time;
      this.dialogEditVisible = true;
      console.log(this);
    },
    //著作编辑
    edit_director() {
      this.make_sure = true;
    },
    make_sure_click() {

      this.make_sure = false;
      this.dialogEditVisible = false;

      //信息上传
      service({
        url: "https://api.movie.yingshinet.com/update_company",
        method: "post",
        data: {
          company_id: this.id,
          company_name: this.now_company_name,
          creator: this.now_creator,
          manager: this.now_manager,
          create_time: this.now_create_time,
        },
      }).then((response) => {
        if (response.status == "200") {
          (this.company_name = this.now_company_name),
            (this.creator = this.now_creator),
            (this.manager = this.now_manager),
            (this.create_time = this.now_create_time)
          this.$message({
            message: "基本信息修改成功！",
            type: "success",
          });
        } else {
          this.$message({
            message: "基本信息修改好像出问题了！",
            type: "warning",
          });
        }
        location.reload();
      });

    },
  },
  created() {
    this.getInfo();
  },
};
</script>
<style scoped>
::v-deep .el-button:focus,
.el-button:hover {
  color: #e2a253 !important;
  border-color: #e2a253 !important;
  background-color: #fff6ec !important;
}

:deep(.table .el-table__header th.el-table__cell) {
  background-color: #606266 !important;
  color: #F0EBE1 !important;
}

:deep(.table .el-table__body td.el-table__cell) {
  background-color: #606266 !important;
  color: #F0EBE1 !important;
}

:deep(.table .el-table__body tr:hover > td.el-table__cell) {
  background-color: #606266cf !important;
}

/* 空状态 */
:deep(.table .el-table__empty-block) {
  background-color: #606266 !important;
}

:deep(.table .el-table__empty-text) {
  color: #F0EBE1 !important;
}

/* 底下的分页条 */
:deep(.el-pagination__total),
:deep(.el-pagination button:disabled),
:deep(.el-pager li.btn-quicknext),
:deep(.el-pager li.btn-quickprev),
:deep(.el-pagination__jump) {
  color: #F0EBE1;
}

:deep(.el-pagination button:disabled),
:deep(.el-pager li),
:deep(.el-pagination .btn-next),
:deep(.el-pagination .btn-prev) {
  background-color: #606266;
  color: #F0EBE1;
}

:deep(.el-pager li:hover) {
  color: #e2a253;
}

:deep(.el-pager li.active) {
  color: #e2a253;
}
</style>
