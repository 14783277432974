<template>
    <div>
        <div class="hist">
            <div class="csv-container">
                <el-upload class="upload-demo" multiple drag action="#" :auto-upload="false"
                    :on-change="handleMultipleCsvFileChange" :on-remove="handleCsvRemove" :file-list="multipleCsvList">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                        将多个CSV文件拖到此处，或<em>点击上传</em>
                    </div>
                </el-upload>

                <div class="file-requirements">
                    <p>必须上传的文件：<strong>colors.csv, shotlen.csv</strong></p>
                    <p>可选上传的文件：<strong>objects.csv, shotscale.csv</strong></p>
                </div>

                <div class="button-container">
                    <el-button @click="mergeMultipleCsvFiles" type="warning" plain>合并CSV文件</el-button>
                </div>

                <div v-if="csvData && csvData.length">
                    <div class="select-container">
                        <div style="margin-bottom:10px;margin-top:10px;color: #F0EBE1;">选择列名：</div>
                        <el-select v-model="selectedColumn" @change="updateCsvInputData" class="select-input"
                            placeholder="选择列名" size="small">
                            <el-option v-for="(header, index) in csvHeaders" :key="index" :label="header" :value="header">
                                {{ header }}
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div v-else></div>
                <el-table :data="csvData" v-if="csvData && csvData.length" border height="300" style="width: 200px">
                    <el-table-column v-for="header in csvHeaders" :key="header" :label="header"
                        v-if="header === selectedColumn">
                        <template v-slot="scope">
                            <el-input v-model="scope.row[header]" size="mini" @input="updateCsvInputData" />
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="picture-container">
                <el-upload class="upload-demo" drag action="#" multiple list-type="picture-card" :auto-upload="false"
                    :on-change="handlePictureChange" :file-list="imageList" :on-preview="handlePictureCardPreview"
                    :on-remove="handlePictureRemove">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将图片拖到此处，或<em>点击上传</em></div>
                </el-upload>

                <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="Preview Image" />
                </el-dialog>
            </div>

            <div class="chart-container">
                <el-tabs v-model="activeName" class="demo-tabs">
                    <el-tab-pane label="电影拉片可视化" name="first">
                        <div class="tab-pane-hist">
                            <el-slider v-if="csvData && csvData.length" v-model="xScaleChange" vertical height="200px"
                                style="margin-top: 15vh;" :min="minSliderValue" :max="maxSliderValue">
                            </el-slider>
                            <svg id="histogram" width="800" height="400"></svg>
                            <el-button v-show="csvData && csvData.length" id="resetButton"
                                style="margin: 10px;">复位</el-button>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="电影故事线可视化" name="second">
                        <div class="tab-pane-story">
                            <div class="image-container">
                                <img v-for="image in imageList" :src="image.url" width="50px"
                                    @click="handleImageDrag(image)" />
                            </div>
                            <el-button @click="generateStoryLineChart" type="warning" plain>生成故事线</el-button>
                            <el-button @click="addYAxisLabel" type="warning" plain>添加y轴标签</el-button>
                            <el-button @click="moveImages('up')" type="warning" plain>整体上移图片</el-button>
                            <el-button @click="moveImages('down')" type="warning" plain>整体下移图片</el-button>
                            <el-button @click="resetImages" type="warning" plain>重置图片</el-button>
                            <div id="storychart"></div>
                            <input type="text" value="无" id="storyinput" />
                        </div>
                    </el-tab-pane>
                </el-tabs>

            </div>
        </div>
    </div>
</template>
  
<script>
import * as d3 from 'd3';

export default {
    name: 'HistChart',
    data() {
        return {
            activeName: "first",
            inputData: '',
            csvData: null,
            csvHeaders: [],
            selectedColumn: '',
            imageList: [],
            dialogImageUrl: '',
            dialogVisible: false,
            xScaleChange: 10,
            minSliderValue: 1,
            maxSliderValue: 30,
            auth: window.sessionStorage.getItem("auth"),
            multipleCsvList: [],
            mergedData: null,
            stitchedImage: null,
            storyxScale: null,
            storyyAxisLabels: ['标签1', '标签2', '标签3'],
            imagePositions: []
        };
    },
    watch: {
        xScaleChange(newval, old) {
            this.generateHistChart();
        }
    },
    methods: {
        handleMultipleCsvFileChange(file) {
            if (this.auth != 1 && this.auth != 2) {
                this.multipleCsvList = [];
                this.$message.error('抱歉，您非管理员，暂无上传权限');
                return; // 没有权限时直接返回
            }

            const fileType = file.name.split('.')[0].toLowerCase();
            const reader = new FileReader();
            reader.onload = (e) => {
                const csv = e.target.result;
                const parsedData = d3.csvParse(csv);
                const removeLeadingZeros = (frameId) => String(parseInt(frameId, 10));
                if (fileType === 'colors') {
                    this.colorsData = parsedData.map(row => ({
                        ...row,
                        FrameId: removeLeadingZeros(row.FrameId),
                    }));
                } else if (fileType === 'objects') {
                    this.objectsData = parsedData.map(row => ({
                        ...row,
                        FrameId: removeLeadingZeros(row.FrameId),
                    }));
                } else if (fileType === 'shotlen') {
                    this.shotlenData = parsedData.map(row => ({
                        ...row,
                        FrameId: removeLeadingZeros(row.start),
                        length: parseInt(row.length, 10) || 0,
                    }));
                } else if (fileType === 'shotscale') {
                    this.shotscaleData = parsedData.map(row => ({
                        ...row,
                        FrameId: removeLeadingZeros(row.FrameId),
                    }));
                }
            };
            reader.readAsText(file.raw);
        },
        mergeMultipleCsvFiles() {
            if (!this.colorsData || !this.shotlenData) {
                this.$message.error('请上传所有必须的CSV文件');
                return;
            }

            // 合并csv
            this.mergedData = this.colorsData.map(colorRow => {
                const FrameId = colorRow.FrameId;
                const shotlenRow = this.shotlenData.find(row => row.FrameId === FrameId) || {};
                const objectsRow = this.objectsData ? this.objectsData.find(row => row.FrameId === FrameId) || {} : {};
                const shotscaleRow = this.shotscaleData ? this.shotscaleData.find(row => row.FrameId === FrameId) || {} : {};

                return {
                    ...colorRow,
                    ...shotlenRow,
                    ...objectsRow,
                    ...shotscaleRow,
                };
            });

            this.csvData = this.mergedData;
            this.csvHeaders = Object.keys(this.mergedData[0]);
            this.selectedColumn = this.csvHeaders[0];
            this.updateCsvInputData();
        },
        updateCsvInputData() {
            const columnIndex = this.csvHeaders.indexOf(this.selectedColumn);
            if (columnIndex >= 0) {
                this.inputData = this.csvData.map(row => Object.values(row)[columnIndex]).join('\n');
            }
            this.generateHistChart();
        },
        handleCsvRemove(file, fileList) {
            this.csvData = null;
            const svg = d3.select('#histogram');
            svg.selectAll('*').remove();
        },
        handlePictureChange(file, fileList) {
            if (this.auth != 1 && this.auth != 2) {
                this.$message.error('抱歉，您非管理员，暂无上传权限');
                this.imageList = [];
                return; // 没有权限时直接返回
            }

            const maxWidth = 480;
            const maxHeight = 480;
            const img = new Image();
            img.src = file.url;
            img.onload = () => {
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round(height * (maxWidth / width));
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round(width * (maxHeight / height));
                        height = maxHeight;
                    }
                }

                // 创建画布并绘制调整后的图片
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                // 将画布内容转换为 Blob 对象并替换原始文件
                canvas.toBlob(blob => {
                    file.raw = new File([blob], file.name, { type: file.type });
                    // 生成本地预览URL
                    file.url = URL.createObjectURL(file.raw);

                    // 如果有 CSV 数据，生成柱状图
                    if (this.csvData) {
                        this.generateHistChart();
                    }
                }, file.type);
            };
            this.imageList = fileList;
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handlePictureRemove(file, fileList) {
            this.imageList = fileList;
        },
        generateHistChart() {
            const heightData = this.parseInput(this.inputData);
            const csvData = this.csvData.map(row => Object.values(row));

            const svg = d3.select('#histogram');
            svg.selectAll('*').remove();

            const margin = { top: 20, right: 20, bottom: 30, left: 40 };
            const width = +svg.attr('width') - margin.left - margin.right;
            const height = +svg.attr('height') - margin.top - margin.bottom;
            const g = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

            const x = d3.scaleBand().range([0, width]).padding(0.1);
            const y = d3.scaleLinear().range([height, 0]);

            x.domain(heightData.map((d, i) => i));
            y.domain([0, d3.max(heightData) + 100]);

            const xWidth = this.xScaleChange;

            const rgbToHex = (r, g, b) => {
                return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
            };

            const getColor = (data, index) => {
                const r = parseInt(data[index * 3]);
                const g = parseInt(data[index * 3 + 1]);
                const b = parseInt(data[index * 3 + 2]);
                return rgbToHex(r, g, b);
            };

            const imageList = this.imageList; // 在外部保存this.imageList的引用
            const that = this;//将 Vue 组件实例保存到一个变量
            const imageNames = imageList.map(item => item.name);
            const maxNumber = Math.max(...imageNames.map(name => parseInt(name.match(/\d+/)[0], 10)));
            const maxDigits = maxNumber.toString().length;//确定图片编号位数

            g.selectAll('.bar')
                .data(heightData)
                .enter().append('g')
                .attr('class', 'bar')
                .attr('transform', (d, i) => `translate(${i * (xWidth + 2)},${y(d)})`)
                .each(function (d, i) {
                    const barHeight = height - y(d);
                    const segmentHeight = barHeight / 5;
                    const colors = csvData[i].slice(1, 16); // 提取 Color0 to Color14

                    //添加颜色条形图
                    d3.select(this).selectAll('rect')
                        .data(d3.range(5))
                        .enter().append('rect')
                        .attr('x', 0)
                        .attr('y', (segment, index) => index * segmentHeight)
                        .attr('width', xWidth)
                        .attr('height', segmentHeight)
                        .style('fill', (segment, index) => getColor(colors, index));

                    const fileName = `frame${csvData[i][0].toString().padStart(maxDigits, '0')}.png`;
                    // 查找匹配的图片URL
                    const imageItem = imageList && imageList.find(item => item.name == fileName);
                    const imageSize = xWidth * 2; // 设置图片大小为圆形直径的一定比例
                    const clipPathId = `circleClip-${i}`;// 动态创建clipPath id

                    if (imageItem) {
                        svg.append('defs').append('clipPath')
                            .attr('id', clipPathId)
                            .append('circle')
                            .attr('cx', xWidth / 2)
                            .attr('cy', -xWidth / 2)
                            .attr('r', xWidth / 2);

                        d3.select(this).append('image')
                            .attr('class', 'bar-image')
                            .attr('xlink:href', imageItem.url)
                            .attr('x', -imageSize / 4)
                            .attr('y', -imageSize * 3 / 4)
                            .attr('width', imageSize)
                            .attr('height', imageSize)
                            .attr('clip-path', `url(#${clipPathId})`)
                            .style('opacity', 1).on('click', function () {
                                that.handlePictureCardPreview(imageItem);
                            });;

                        // 添加鼠标悬停事件
                        d3.select(this)
                            .on('mouseover', function (event, d) {
                                const frameNumber = csvData[i][0];
                                d3.select(this).append('text')
                                    .attr('class', 'frame-label')
                                    .attr('x', xWidth / 2)
                                    .attr('y', barHeight + 20)
                                    .text(`第${frameNumber}帧`)
                                    .style('text-anchor', 'middle');
                                // 放大图片并调整clipPath
                                d3.select(this).select('.bar-image')
                                    .transition()
                                    .duration(200)
                                    .attr('width', imageSize * 3)
                                    .attr('height', imageSize * 3)
                                    .attr('x', -imageSize / 4 * 3)
                                    .attr('y', -imageSize * 3 / 4 * 3);

                                d3.select(`#${clipPathId} circle`)
                                    .transition()
                                    .duration(200)
                                    .attr('cy', -xWidth / 2 * 3)
                                    .attr('r', xWidth / 2 * 3);

                                // 将当前元素提升到最上层
                                this.parentNode.appendChild(this);
                            })
                            .on('mouseout', function () {
                                d3.select(this).select('.frame-label').remove();
                                // 恢复图片大小并调整clipPath
                                d3.select(this).select('.bar-image')
                                    .transition()
                                    .duration(200)
                                    .attr('width', imageSize)
                                    .attr('height', imageSize)
                                    .attr('x', -imageSize / 4)
                                    .attr('y', -imageSize * 3 / 4);

                                d3.select(`#${clipPathId} circle`)
                                    .transition()
                                    .duration(200)
                                    .attr('cy', -xWidth / 2)
                                    .attr('r', xWidth / 2);
                            });
                    }
                });

            // 缩放和拖动
            const maxIndex = heightData.length * (xWidth + 2) + 50;
            const maxYValue = d3.max(heightData) + 20;
            const zoom = d3.zoom()
                .scaleExtent([1, 5])
                .translateExtent([[-margin.left, -(maxYValue + margin.top)], [maxIndex + margin.left, maxYValue + margin.top]])
                .on('zoom', zoomed);

            svg.call(zoom);

            // 添加复位按钮功能
            d3.select('#resetButton').on('click', () => {
                svg.transition().duration(200).call(
                    zoom.transform,
                    d3.zoomIdentity.translate(margin.left, margin.top)
                );
            });

            function zoomed(event) {
                const { transform } = event;
                g.attr('transform', transform);
                g.attr('stroke-width', 1 / transform.k);
            }
        },
        parseInput(inputData) {
            return inputData.split(/\s+/).map(d => parseInt(d));
        },
        setupDragBehavior(imgElement, xPosition) {
            const svg = d3.select('#storychart svg g');
            const drag = d3.drag()
                .on('start', (event) => {
                    imgElement.raise().attr('stroke', 'black');
                })
                .on('drag', (event) => {
                    imgElement
                        .attr('x', xPosition)
                        .attr('y', event.y);
                })
                .on('end', (event) => {
                    const svgRect = svg.node().getBoundingClientRect();
                    const svgTop = svgRect.top + window.scrollY;

                    // 获取所有 y 轴标签的位置相对于 SVG
                    const yPositions = svg.selectAll('.tick text').nodes().map(d => {
                        const tickRect = d.getBoundingClientRect();
                        return tickRect.top + window.scrollY - svgTop;
                    });

                    // 找到最近的 y 轴位置
                    const closestY = yPositions.reduce((prev, curr) => {
                        return (Math.abs(curr - event.y) < Math.abs(prev - event.y) ? curr : prev);
                    });

                    imgElement
                        .attr('x', xPosition)
                        .attr('y', closestY - 20)
                        .attr('stroke', null);

                    // 更新图像的 y 坐标
                    const imagePosition = this.imagePositions.find(pos => pos.x === xPosition);
                    imagePosition.y = closestY - 10;

                    // 绘制连接线
                    this.drawConnectingLines();
                });

            imgElement.call(drag);
        },
        handleImageDrag(image) {
            const svgtemp = d3.select('#storychart svg');
            if (svgtemp.empty()) {
                this.$message.error('请点击生成故事线');
                return;
            }
            const svg = d3.select('#storychart svg g');
            const imgElement = svg.append('image')
                .attr('href', image.url)
                .attr('width', 50)
                .attr('height', 50);

            // 计算图像的初始横坐标位置
            const frameNumber = parseInt(image.name.match(/(\d+)/)[0]);
            const xPosition = this.storyxScale(frameNumber);

            // 设置图像初始位置
            imgElement.attr('x', xPosition);
            imgElement.attr('y', 0);

            // 保存图像的位置信息
            if (!this.imagePositions) {
                this.imagePositions = [];
            }
            this.imagePositions.push({ imgElement, x: xPosition, y: 10 });
            this.drawConnectingLines();

            this.setupDragBehavior(imgElement, xPosition);
        },
        generateStoryLineChart() {
            if (!this.imageList || this.imageList.length === 0) {
                this.$message.error('请上传图片文件');
                return;
            }
            const { imageList } = this;

            const svgtemp = d3.select('#storychart');

            // 获取最大帧数
            const maxFrame = Math.max(
                ...imageList.map((image) => parseInt(image.name.match(/(\d+)/)[0]))
            );

            const margin = { top: 20, right: 30, bottom: 50, left: 40 };
            const width = 1000 - margin.left - margin.right;
            const height = 120 - margin.top - margin.bottom;

            // 保存当前图表中图片的位置和拖动状态
            const currentImages = svgtemp.selectAll('image').nodes().map(image => {
                const img = d3.select(image);
                return {
                    url: img.attr('href'),
                    x: parseFloat(img.attr('x')),
                    y: parseFloat(img.attr('y')),
                    width: parseFloat(img.attr('width')),
                    height: parseFloat(img.attr('height'))
                };
            });

            svgtemp.selectAll('*').remove();

            const svg = d3
                .select('#storychart')
                .append('svg')
                .attr('width', width + margin.left + margin.right)
                .attr('height', height * this.storyyAxisLabels.length + margin.top + margin.bottom)
                .append('g')
                .attr('transform', `translate(${margin.left},${margin.top})`);

            // 定义x轴比例尺
            const xScale = d3
                .scaleLinear()
                .domain([0, maxFrame])
                .range([0, width - 200]);
            this.storyxScale = xScale;

            // 定义x轴
            const xAxis = d3.axisBottom(xScale)
                .tickFormat((d) => this.formatTime(d));

            // 添加x轴到图表
            svg.append('g')
                .attr('transform', `translate(0,${height * this.storyyAxisLabels.length})`)
                .call(xAxis);

            // 定义y轴比例尺
            const yScale = d3.scaleBand()
                .domain(this.storyyAxisLabels)
                .range([height * this.storyyAxisLabels.length, 0])
                .padding(0.1);

            // 添加y轴到图表
            svg.append("g")
                .call(d3.axisLeft(yScale))
                .call(g => {
                    g.selectAll("text")
                        .on("click", (e, a) => {
                            let element = e.target;
                            let box = element.getBoundingClientRect();
                            let index = this.storyyAxisLabels.indexOf(a);
                            d3.select("#storyinput")
                                .style("display", "inline")
                                .style("left", "-27px")
                                .style("top", box.y + window.scrollY - 3 * height + 7 + "px")
                                .on("blur", (e) => {
                                    let newValue = e.target.value;
                                    if (newValue === "") {
                                        this.storyyAxisLabels.splice(index, 1);
                                        d3.select(element).remove();
                                        this.generateStoryLineChart();
                                    } else {
                                        d3.select(element).text(newValue);
                                        this.storyyAxisLabels[index] = newValue;
                                    }
                                    d3.select(e.target).style("display", "none");
                                })
                            document.getElementById("storyinput").focus();
                            document.getElementById("storyinput").value = d3.select(element).text();

                        });

                });

            // 将保存的图片重新添加到图表中并绑定拖动功能
            currentImages.forEach(img => {
                const imgElement = svg.append('image')
                    .attr('href', img.url)
                    .attr('x', img.x)
                    .attr('width', img.width)
                    .attr('height', img.height);
                if (!isNaN(img.y)) {
                    imgElement.attr('y', img.y);
                }
                else {
                    imgElement.attr('y', 0);
                }

                this.drawConnectingLines();

                this.setupDragBehavior(imgElement, img.x);
            });
        },
        drawConnectingLines() {
            const svg = d3.select('#storychart svg g');

            // 移除现有的连接线
            svg.selectAll('#connecting-line').remove();

            // 对图像位置按 x 进行排序
            const sortedPositions = this.imagePositions.sort((a, b) => a.x - b.x);

            const lineGenerator = d3.line()
                .x(d => d.x)
                .y(d => d.y);

            // 生成路径数据
            const pathData = lineGenerator(sortedPositions);

            // 绘制新的连接线
            svg.append('path')
                .attr('id', 'connecting-line')
                .attr('d', pathData)
                .attr('fill', 'none')
                .attr('stroke', 'red')
                .attr('stroke-width', 0.5)
                .lower();
        },
        formatTime(frames) {
            //一秒24帧
            const totalSeconds = Math.floor(frames / 24);
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;
            return `${hours.toString().padStart(2, '0')}:${minutes
                .toString()
                .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        },
        resetImages() {
            if (!this.imageList || this.imageList.length === 0) {
                this.$message.error('请上传图片文件');
                return;
            }
            const svg = d3.select('#storychart svg g');
            svg.selectAll('image').remove();
            svg.selectAll('#connecting-line').remove();
            this.imagePositions = [];
        },
        addYAxisLabel() {
            if (!this.imageList || this.imageList.length === 0) {
                this.$message.error('请上传图片文件');
                return;
            }
            const newLabel = '新标签';
            if (!this.storyyAxisLabels.includes(newLabel)) {
                this.storyyAxisLabels.push(newLabel);
                this.generateStoryLineChart();
            }
        },
        moveImages(direction) {
            if (!this.imageList || this.imageList.length === 0) {
                this.$message.error('请上传图片文件');
                return;
            }
            const moveDistance = 50;
            const svg = d3.select('#storychart svg g');

            const images = svg.selectAll('image');

            images.each((d, i, nodes) => {
                const imgElement = d3.select(nodes[i]);
                const currentX = parseFloat(imgElement.attr('x'));
                const currentY = parseFloat(imgElement.attr('y'));
                const newY = direction === 'up' ? currentY - moveDistance : currentY + moveDistance;
                imgElement.attr('y', newY);

                const imagePosition = this.imagePositions.find(pos => pos.x === currentX);
                if (imagePosition) {
                    imagePosition.y = newY + 10;
                }
            });

            this.drawConnectingLines();
        }
    },
    mounted() {
        this.$nextTick(() => {
            // 获取父容器
            const container = this.$el.querySelector('.picture-container').querySelector('.upload-demo');

            // 获取需要互换位置的元素
            const uploadDemo = container.querySelector('.el-upload');
            const uploadList = container.querySelector('.el-upload-list');

            // 将 uploadDemo 移动到 uploadList 之前
            if (uploadDemo && uploadList) {
                container.insertBefore(uploadDemo, uploadList);
            }
        });
    }
};
</script>
  
<style scoped>
.hist {
    display: flex;
    justify-content: space-between;
}

.csv-container {
    flex: 1;
    margin: 20px;
    max-width: 220px;
}

.picture-container {
    flex: 2;
    margin: 20px;
    max-width: 220px;
}

.chart-container {
    flex: 3;
    display: flex;
    justify-content: center;
    position: relative;
}

.select-container {
    display: block;
    margin-bottom: 10px;
}

::v-deep .el-upload-dragger:hover {
    border-color: #e2a253 !important;
}

::v-deep .el-upload-dragger.is-dragover {
    border-color: #e2a253 !important;
    background-color: #fdf6ec !important;
}

::v-deep .el-upload-dragger:not(:hover):not(.is-dragover) {
    border-color: #eeeeee !important;
}

::v-deep .el-upload__text {
    color: #F0EBE1 !important;
}
::v-deep .el-upload__text em {
    color: #e2a253 !important;
}

::v-deep .upload-demo .el-upload-dragger {
    width: 220px !important;
    height: 150px !important;
    background-color: #606266;
}


::v-deep .el-select .el-input__inner:focus,
::v-deep .el-select .el-input__inner.is-focus,
::v-deep .el-select .el-input__inner.is-active {
    border-color: #e2a253 !important;
}

::v-deep .el-upload--picture-card {
    all: unset;
}

::v-deep .el-upload-list--picture-card {
    z-index: 0;
    max-width: 220px;
    max-height: 500px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

::v-deep .el-upload--picture-card {
    z-index: 1;
    position: relative;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
    height: 35px;
    width: auto;
}

/* ::v-deep .el-button:focus,
.el-button:hover {
    color: #e2a253;
    border-color: #e2a253;
    background-color: #fdf6ec;
} */

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-requirements {
    margin-bottom: 10px;
    color: #F0EBE1;
    font-size: 14px;
    text-align: center;
}

::v-deep .el-input__inner:focus {
    border-color: #e2a253;
}

::v-deep .el-table__row:hover {
    background-color: #fdf6ec;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
    background-color: #fdf6ec;
}

.tab-pane-hist {
    display: flex;
    align-items: center;
    margin-top: 5vh;
    margin-right: 2vh;
}

.tab-pane-story {
    align-items: center;
    margin-top: 5vh;
    margin-right: 1vh;
}

.image-container {
    position: relative;
    width: 100%;
    height: auto;
}

.demo-tabs {
    width: 1000px;
}

#storyinput {
    border: none;
    width: 60px;
    font-size: 11px;
    height: 14px;
    position: absolute;
    text-align: right;
    padding: 0;
    display: none;
}

#storyinput:focus {
    outline: none
}
::v-deep .el-upload-list__item-name {
    color: #F0EBE1;
}
</style>
  