<template>
<div style="color: #F0EBE1;">
<center><h3>电影著作年份直方图</h3></center>
<el-scrollbar>
    <svg id="hist" height="50vh" width="100vw"></svg>
</el-scrollbar>
</div>
</template>

<script>
import * as d3 from "d3";
import { copyFileSync } from "fs";
import * as $ from "jQuery";
import { createDecipheriv } from 'crypto';

export default {
  data() {
    return {
      work: [],
      tableData: [],
      tableData_length: "",
      path1:"https://api.movie.yingshinet.com/books/year",
      path2:"https://api.movie.yingshinet.com/books/author",
      selected_count:"",
      selected_year:"",
    };
  },
  methods:
  {

     drawhist(){
		    let width=(window.innerWidth||document.documentElement.clientWidth||document.body.clientWidth);
		    let height=(window.innerHeight||document.documentElement.clientHeight||document.body.clientHeight)*0.45;
            let color = d3.schemeCategory10;
                  let yScale=d3.scaleLinear()
                   .domain([0,260])
                   .range([15,height*0.8]);

             let svg = d3.select("#hist")			//选择<body>
					.attr("width", width)	//设定<svg>的宽度属性
					.attr("height", height);//设定<svg>的高度属性
		//矩形所占的宽度（包括空白），单位为像素
		let rectStep =width/this.tableData_length;
		//矩形所占的宽度（不包括空白），单位为像素
		let rectWidth =rectStep-1;
		let rect = svg.selectAll("rect")
						.data(this.tableData)		//绑定数据
						.enter()			//获取enter部分
                        .append("a")
                        .attr("xlint:href",function(d,i){
                                    return "/#/Content_book?wanted=year"+d.year;
                         })
						.append("rect")	    //添加rect元素，使其与绑定数组的长度一致
                        .attr("class","rect1")
						.attr("x", function(d,i){		//设置矩形的x坐标
							return (i+1) * rectStep;
						})
						.attr("y", function(d){		//设置矩形的y坐标
							return height-yScale(d.count);
						})
						.attr("fill", function(d,i){
							return color[i%10];
						})
						.attr("width",rectWidth)		//设置矩形的宽度
						.attr("height",function(d){	//设置矩形的高度
							return yScale(d.count);
						});

	    let text = svg.selectAll("text")
						.data(this.tableData)		//绑定数据
						.enter()			//获取enter部分
                        .append("a")
						.attr("xlint:href",function(d,i){
                                    return "/#/Content_book?wanted=year"+d.year;
                         })
						.append("text")	    //添加rect元素，使其与绑定数组的长度一致
						.attr("x", function(d,i){		//设置矩形的x坐标
							return (i+2) * rectStep;
						})
						.attr("y", function(d){		//设置矩形的y坐标
							return height-yScale(d.count);
						})
						.attr("dx", "0.21em")
						.attr("font-size",rectWidth*0.8)
						.attr("fill", function(d,i){
							return color[i%10];
						})
						.attr("transform",(d,i)=>`rotate(-90,${(i+1) * rectStep+rectStep/2},${height-yScale(d.count)})`)
						.text((d,i)=>d.year);  //.substring(0,2)
       },
   
    getInfo()
    {
         this.axios
        .post(this.path1)
        .then((res) => {
          for (var i = 1; i < res.data.count; i++) {
            this.tableData[i-1] = {};
            this.tableData[i-1].year = res.data.data[i].year;
            this.tableData[i-1].count = res.data.data[i].count;
          }
          this.tableData_length=res.data.count-1;
          this.drawhist();
          
          })
    }
        },
   
created()
{
this.getInfo();
}
  } ;
  </script>

 <style>
		    .tooltip{
				 font-family: simsun;
				 font-size: 14px;
				 width: 120;
				 height: auto;
				 position: absolute;
				 text-align: center;
				 border-style: solid;
				 border-width: 1px;
				 background-color: white;
				 border-radius: 5px;
			}
.el-scrollbar__bar.is-horizontal {
    height: 5px!important;
    left: 2px;
}
	</style>