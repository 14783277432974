<template>
    <div style="margin-left:5%;margin-right:5%">
        <el-container>
            <el-header style="height:15%">
                <ArticleSearch
                    style="width:100%;margin-top:20px"
                    v-on:searchResults="searchResults"
                ></ArticleSearch>
                <el-row :gutter="5" style="margin-top:1%;">
                    <!-- <el-row :gutter="5" style="margin-left:5%"> -->
                    <el-col
                        :span="1"
                        style="height:40px;width:80px;padding-left:10px;border-radius: 6px"
                        :style="{ backgroundColor: !year_isSelect ? '#FFC78E' : '' }"
                    >
                        <p class="selectfont" :class="{ 'active': !year_isSelect }" @click="year_fun(-1)">全部年代</p>
                    </el-col>
                    <el-col
                        :span="1"
                        style="height:40px;width:80px;padding-left:10px;border-radius: 6px"
                        :style="{ backgroundColor: year_arr[i] ? '#FFC78E' : '' }"
                        v-for="(data,i) in year"
                        :key="i"
                    >
                        <p class="selectfont" :class="{ 'active': year_arr[i] }" @click="year_fun(i)">{{ data }}</p>
                    </el-col>
                </el-row>
            </el-header>

            <el-pagination
                background
                layout="prev, pager, next"
                :total="article_count"
                :page-size="page_size"
                align="center"
                style="margin-top:20px"
                @current-change="page_change"
                :current-page.sync="currentPage"
            ></el-pagination>

            <el-main>
                <el-container direction="vertical">
                    <!-- <video-search v-on:searchResults="searchResults"></video-search> -->
                    <el-row :gutter="20">
                        <el-col
                            :span="3"
                            v-for="(data,i) in (isSearch ? result : tableData)"
                            :key="i"
                            class="mouse_hover"
                        >
                            <el-card class="card" shadow="hover" :body-style="{ padding: '0px' }">
                                <el-image
                                    :src="data.thumbnail[0]"
                                    class="image"
                                    fit="cover"
                                    @click="convertessay(data.id)"
                                />
                                <div class="bottom clearfix">
                                    <div
                                        style="font-size: 15px;color: #999;text-align:center"
                                    >{{ data.title.slice(0, 7) }}</div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </el-container>
            </el-main>
        </el-container>
    </div>
</template>
<script>
import ArticleSearch from "../components/literature/ArticleSearch.vue"
export default {
    components: {
        ArticleSearch,
    },
    data() {
        return {
            drawer: false,
            direction: 'btt',
            showData: [],
            year_select: -1,
            year_isSelect: false,
            year: ['2020年代', "2010年代", "2000年代", "90年代", "80年代", "70年代", "60年代", "50年代", "40年代", "30年代"],
            year_arr: [],
            result: [],
            isSearch: false,
            directorurl: {},
            tableData: [],
            article_count: null,
            page_size: 20,
            currentPage: 1,
            select_state: "0",
            year_num: null,
        }
    },
    computed: {
        dataShow: function () {
            let start = this.currentPage * this.pageSize;
            let end = Math.min((this.currentPage + 1) * this.pageSize, this.tableData.length)
            return this.tableData.slice(start, end)
        },
        pageNum: function () {
            return Math.ceil(this.tableData.length / this.pageSize) || 1;
        }
    },
    methods: {
        page_change(page) {
            this.currentPage = page
            if (this.select_state == "0") {
                this.axios.post("https://api.movie.yingshinet.com/articles/select?page=" + page + "&offset=" + this.page_size + "&divide_page=1", { label: this.$store.state.current_figure })
                    .then((res) => {
                        this.work = res.data.m_data;
                        this.article_count = res.data.all_item_count
                        this.num = res.data.m_count;
                        this.tableData = []
                        for (var i = 0; i < this.num; i++) {
                            this.tableData[i] = {};
                            this.tableData[i].id = this.work[i].id;
                            this.tableData[i].title = this.work[i].title;
                            this.tableData[i].year = this.work[i].year;
                            this.tableData[i].thumbnail = this.work[i].thumbnail
                        }
                        if (this.num == 0) {
                            this.tableData[0] = {}
                            this.tableData[i].title = "尚无数据";
                            this.tableData[i].thumbnail = [require("../../public/static/pdf_icon.png")]
                        }
                        this.$set(this.tableData);
                    });
            } else {
                this.axios.post("https://api.movie.yingshinet.com/articles/select?page=" + page + "&offset=" + this.page_size + "&divide_page=1", { label: this.$store.state.current_figure, year: this.year_num })
                    .then((res) => {
                        this.work = res.data.m_data;
                        this.article_count = res.data.all_item_count
                        this.num = res.data.m_count;
                        this.tableData = []
                        for (var i = 0; i < this.num; i++) {
                            this.tableData[i] = {};
                            this.tableData[i].id = this.work[i].id;
                            this.tableData[i].title = this.work[i].title;
                            this.tableData[i].year = this.work[i].year;
                            this.tableData[i].thumbnail = this.work[i].thumbnail
                        }
                        if (this.num == 0) {
                            this.tableData[0] = {}
                            this.tableData[i].title = "尚无数据";
                            this.tableData[i].thumbnail = [require("../../public/static/pdf_icon.png")]
                        }
                        this.$set(this.tableData);
                    });
            }
        },
        year_fun(num) {
            if (num !== -1) {
                this.year_num = 202 - num
                this.select_state = "1"
                // var arr_index = new Array();

                for (var i = 0; i < this.year_arr.length; i++) {
                    // arr_index.push(i)

                    this.year_arr[i] = false
                }
                this.year_arr[num] = true
                // let status = !this.year_arr[num]
                // this.year_arr.splice(num, 1, status)
                // arr_index.splice(num, 1)
                // for (var i = 0; i < arr_index.length; i++) {
                //     let s = false
                //     this.year_arr.splice(arr_index[i], 1, s)
                // }
                // let index = this.year_arr.indexOf(true);
                // this.year_select = index
                // if (index != -1) {
                //     this.year_isSelect = true;
                //     this.isSearch = true;
                // }
                // else {
                this.year_isSelect = true;
                this.isSearch = false;
                // }

                // var data = []
                // for (var i = 0; i < this.tableData.length; i++) {
                //     if (this.tableData[i].year >= 2020 - (10 * num) && this.tableData[i].year < 2030 - (10 * num)) {
                //         if (!this.type_isSelect) {
                //             data.push(this.tableData[i])
                //         }
                //         else {
                //             if (this.tableData[i].movie_type.indexOf(this.type[this.type_select]) != -1) {
                //                 data.push(this.tableData[i])
                //             }
                //         }
                //         // console.log(this.tableData[i].year)
                //     }
                //     this.isSearch = true;
                //     this.result = data
                // }
                this.page_change(1)
            }
            else {
                for (var i = 0; i < this.year_arr.length; i++) {
                    // arr_index.push(i)

                    this.year_arr[i] = false
                }
                this.select_state = "0"
                this.isSearch = false;
                this.year_isSelect = !this.year_isSelect;
                this.page_change(1)
            }
        },
        convertessay(id) {
            this.$router.push({
                path: '/literature',
                query: {
                    id: id,
                },
            })
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        searchResults: function (Value) {
            // childValue就是子组件传过来的值
            this.result = Value
            if (this.result.length !== 0) {
                this.isSearch = true;
            }
            else {
                this.$alert('换个关键词试试', '找不到您想搜索的内容', {
                    confirmButtonText: '确定',
                    callback: action => {
                        this.$message({
                            type: 'info',
                            message: `action: ${action}`
                        });
                    }
                });
            }

        }

    },
    created() {
        this.page_change(1)
        for (var i = 0; i < this.year.length; i++) {
            this.year_arr.push(false)
        }
        this.axios
            .post("https://api.movie.yingshinet.com/figures/select", { f_name: this.$store.state.current_figure })
            .then((response) => {
                this.directorurl = response.data.f_data[0]
            });
    },
}
</script>
<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #e2a253 !important;
    color: #fff;
}
</style>
<style scoped>
.selectfont {
    color: #cccccc;
    font-size: 15px;
    margin-top: 10px;
}
.selectfont.active {
    color: #404040 !important;
}
.mouse_hover:hover {
    cursor: pointer;
}
.image {
    width: 120px;
    height: 140px;
    display: block;
}
.card {
    width: 120px;
    height: 160px;
    /* margin-top:10px; */
    margin-bottom: 20px;
}
.bottom {
    margin-top: 0px;
    line-height: 12px;
    padding-top: 5px;
    /* padding: 14px; */
}
.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both;
}
.mouse_hover:hover {
    cursor: pointer;
}
</style>