<template >
 
    <div class="hist-graph" >
     
 
      <h3 style=" text-align: center;font-size: 1.2vw;padding: 0; color: #F0EBE1;">按年代导航</h3>

        <svg
          id="histGraph"
          height="100%"></svg>
  </div>
</template>

<script>
import * as d3 from "d3";
import * as $ from "jQuery";

//import service from "../utils/request";
export default {
  data() {
    return {
      work: [],
      tableData: [],
      tableData_length:0,

      work2: [],
      tableData2: [],
      tableData2_length:0,

      work3: [],
      tableData3: [],
      tableData3_length:0,

      work4: [],
      tableData4: [],
      tableData4_length:0,

      work5: [],
      tableData5: [],
      tableData5_length:0,

      work6: [],
      tableData6: [],
      tableData6_length:0,
    };
  },


  methods: {

 getInfo() {
      const path1 = "https://api.movie.yingshinet.com/wmovieYears";
      const path2 = "https://api.movie.yingshinet.com/wMLSearch";

      this.axios
        .post(path1,{wanted:"年"})
        .then((res) => {
          this.tableData_length=res.data.searched_titles.length;
          this.work = res.data.searched_titles;
            for (var i = 0; i < this.tableData_length; i++) {
        this.tableData[i] = {};
        this.tableData[i].titles=this.work[i].titles;
        this.tableData[i].height=this.work[i].contents.length;
        this.tableData[i].id=i;
        this.$set(this.tableData);
      }
      console.log(this.tableData);
          this.initHist();

        })
        .catch((error) => {
        });
       this.axios
        .post(path1,{wanted:"月"})
        .then((res) => {
          this.tableData2_length=res.data.searched_titles.length;
          this.work2 = res.data.searched_titles;
           for (var i = 0; i < this.tableData2_length; i++) {
        this.tableData2[i] = {};
        this.tableData2[i].titles=this.work2[i].titles;
        this.tableData2[i].height=this.work2[i].contents.length;
        this.tableData2[i].id=i;
        this.$set(this.tableData2);
              }
          this.initHist();

        })
        .catch((error) => {
        });

         this.axios
        .post(path2,{wanted:"年出生",})
        .then((res) => {
           this.tableData4_length=res.data.searched_titles.length;
          this.work4 = res.data.searched_titles;
           for (var i = 0; i < this.tableData4_length; i++) {
        this.tableData4[i] = {};
        this.tableData4[i].titles=this.work4[i].titles;
        this.tableData4[i].contents=this.work4[i].contents;
        this.tableData4[i].id=i;
        this.tableData4[i].height=this.work4[i].contents;
        this.$set(this.tableData4);
              }
          this.initHist();


        })
        .catch((error) => {
        });
         this.axios
        .post(path2,{wanted:"年逝世",})
        .then((res) => {
           this.tableData5_length=res.data.searched_titles.length;
          this.work5 = res.data.searched_titles;
           for (var i = 0; i < this.tableData5_length; i++) {
        this.tableData5[i] = {};
        this.tableData5[i].titles=this.work5[i].titles;
        this.tableData5[i].contents=this.work5[i].contents;
        this.tableData5[i].id=i;
        this.tableData5[i].height=this.work5[i].contents;
        this.$set(this.tableData5);
              }
          this.initHist();
        })
        .catch((error) => {
        });
         this.axios
        .post(path2,{wanted:"年出品",})
        .then((res) => {
           this.tableData6_length=res.data.searched_titles.length;
          this.work6 = res.data.searched_titles;
           for (var i = 0; i < this.tableData6_length; i++) {
        this.tableData6[i] = {};
        this.tableData6[i].titles=this.work6[i].titles;
        this.tableData6[i].contents=this.work6[i].contents;
        this.tableData6[i].id=i;
        this.tableData6[i].height=this.work6[i].contents;
        this.$set(this.tableData6);
              }
          this.initHist();


        })
        .catch((error) => {
        });
    },



initHist(){
      this.drawHist();
      this.$nextTick(function () {
        document.querySelectorAll(".rect1").forEach((item) => {
          item.addEventListener("click", () => {
            let keyword = item.id
            this.$router.push("/PostTitle?wanted=" +keyword);
          });
        });
      });
            this.$nextTick(function () {
        document.querySelectorAll(".rect2").forEach((item) => {
          item.addEventListener("click", () => {
            let keyword = item.id
            this.$router.push("/PostTitle?wanted=" +keyword);
          });
        });
      });
        document.querySelectorAll(".rect4").forEach((item) => {
          item.addEventListener("click", () => {
            let keyword = item.id
            this.$router.push("/post?name=" +keyword);
          });
        });
         document.querySelectorAll(".rect5").forEach((item) => {
          item.addEventListener("click", () => {
            let keyword = item.id
            this.$router.push("/post?name=" +keyword);
          });
        });
         document.querySelectorAll(".rect6").forEach((item) => {
          item.addEventListener("click", () => {
            let keyword = item.id
            this.$router.push("/post?name=" +keyword);
          });
        });
    },

drawHist () {
      let bodywidth = document.body.clientWidth;
      let width = bodywidth;
      let leftw = bodywidth*0.01;
      let rightw = bodywidth*0.88;
      let bandwidth=(rightw-leftw)/(this.tableData_length);
      let bandwidth2=(rightw-leftw)/(this.tableData2_length);
      let bandwidth4=(rightw-leftw)/(this.tableData4_length);
      let bandwidth5=(rightw-leftw)/(this.tableData5_length);
      let bandwidth6=(rightw-leftw)/(this.tableData6_length);


      
      let height = $(".hist-graph").height()/5.2;
      let height1 = $(".hist-graph").height();

      let svg = d3.select("#histGraph")
                   .attr("width", width)
                   .attr("height", height1);
      // 每次调用前删除之前部分
      svg.selectAll("*").remove();
      let xScale = d3.scaleLinear().range([0, rightw]).domain([0,this.tableData_length])
      let xScale2 = d3.scaleLinear().range([0, rightw]).domain([0,this.tableData2_length])
      let xScale4 = d3.scaleLinear().range([0, rightw]).domain([0,this.tableData4_length])
      let xScale5 = d3.scaleLinear().range([0, rightw]).domain([0,this.tableData5_length])
      let xScale6 = d3.scaleLinear().range([0, rightw]).domain([0,this.tableData6_length])

      // x轴                                 
      
      let yScale=d3.scaleLinear()
                   .domain([0,33])
                   .range([0,height*0.5]);
      let yScale1=d3.scaleLinear()
                   .domain([0,12000])
                   .range([0,height*0.5]);
      let yScale2=d3.scaleLinear()
                   .domain([0,8000])
                   .range([0,height*0.5]);
      
      
      let yAxis = svg.append('g')
                    .attr('class', 'yAxis')
                    .attr('transform', `translate(0, ${height-60})`)
                   // .call(d3.axisBottom(yScale).ticks(20).tickFormat(x => `${x.toFixed(0)}`))
      const color = d3.scaleOrdinal(d3.schemeCategory10);
      


      let rect = svg.append('g')
                      .attr("class","rect")
                      .selectAll(".rect")
                      .data(this.tableData)
                      .enter()
                      .append("rect")
                      .attr("class","rect1")
                      .attr("id",function(d,i)
                      {
                        return d.titles;
                      })
                      .attr("titles",function(d,i)
                      {
                        return d.titles;
                      })
                      .attr("x", function(d,i){
                        return xScale(i);
                    
                       })
                      .attr("y", function(d,i){
                        return height*4-yScale1(parseFloat(d.height));
                         })
                      .attr("width",bandwidth)
                      .attr("height",function(d,i)
                      { return yScale1(parseFloat(d.height))+10;
                      })   
                      .attr("fill",function(d,i)
                      {return color(i);})
                    
       let text = svg.append('g')
                     .selectAll(".text")
                     .data(this.tableData)
                     .enter()
                     .append("text")
                     .attr("fill",function(d,i)
                     {
                       return color(i);
                     })
                     .attr("font-size","10px")
                     .attr("text-anchor","middle")
                     .attr("width",10)
                     .attr("x",function(d,i)
                     {
                          return xScale(i);
                      })
                     .attr("y", function(d){
                        return  height*4-yScale1(parseFloat(d.height))-20;
                               })
                     .attr("dx",10)
                     .attr("dy",10)
                     .attr(
          "transform",
          (d, i) =>
            `rotate(-90,${xScale(i)},${height*4-yScale1(parseFloat(d.height))-20})`
        )
                     .text(function(d,i){
                         return d.titles;
                         })



     let rect2 = svg.append('g')
                      .attr("class","rect")
                      .selectAll(".rect")
                      .data(this.tableData2)
                      .enter()
                      .append("rect")
                      .attr("class","rect2")
                      .attr("id",function(d,i)
                      {
                        return d.titles;
                      })
                      .attr("titles",function(d,i)
                      {
                        return d.titles;
                      })
                      .attr("x", function(d,i){
                        return xScale2(i);
                    
                       })
                      .attr("y", function(d,i){
                        return height*5-yScale2(parseFloat(d.height)/2);
                         })
                      .attr("width",bandwidth2)
                      .attr("height",function(d,i)
                      { return yScale2(parseFloat(d.height))/2;
                      })   
                      .attr("fill",function(d,i)
                      {return color(i);})
                     
       let text2 = svg.append('g')
                     .selectAll(".text2")
                     .data(this.tableData2)
                     .enter()
                     .append("text")
                     .attr("fill",function(d,i)
                     {
                       return color(i);
                     })
                     .attr("font-size","16px")
                     .attr("text-anchor","middle")
                     .attr("width",10)
                     .attr("x",function(d,i)
                     {
                          return xScale2(i);
                      })
                     .attr("y", function(d){
                        return  5*height-yScale2(parseFloat(d.height)/2)+bandwidth2/4;
                               })
                     .attr("dx",10)
                     .attr("dy",10)
                     .attr(
          "transform",
          (d, i) =>
            `rotate(-90,${xScale2(i)},${5*height-yScale2(parseFloat(d.height)/2)-20})`
        )
                     .text(function(d,i){
                         return d.titles;
                         })
let rect4 = svg.append('g')
                      .attr("class","rect4")
                      .selectAll(".rect")
                      .data(this.tableData4)
                      .enter()
                      .append("rect")
                      .attr("class","rect4")
                      .attr("id",function(d,i)
                      {
                        return d.titles;
                      })
                      .attr("titles",function(d,i)
                      {
                        return d.titles;
                      })
                      .attr("x", function(d,i){
                        return xScale4(i);
                       })
                      .attr("y", function(d,i){
                        return height-yScale(parseFloat(d.height));
                         })
                      .attr("width",bandwidth4)
                      .attr("height",function(d,i)
                      { return yScale(parseFloat(d.height))+10;
                      })   
                      .attr("fill",function(d,i)
                      {return color(i);})
                     
       let text4 = svg.append('g')
                     .selectAll(".text4")
                     .data(this.tableData4)
                     .enter()
                     .append("text")
                     .attr("fill",function(d,i)
                     {
                       return color(i);
                     })
                     .attr("font-size","10px")
                     .attr("text-anchor","middle")
                     .attr("width",10)
                     .attr("x",function(d,i)
                     {
                          return xScale4(i)+30;
                      })
                     .attr("y", function(d){
                        return  height-yScale(parseFloat(d.height));
                               })
                     .attr("dx",10)
                     .attr("dy",10)
                     .attr(
          "transform",
          (d, i) =>
            `rotate(-90,${xScale4(i)},${height-yScale(parseFloat(d.height))})`
        )
                     .text(function(d,i){
                         return d.titles;
                         }) 
      let rect5 = svg.append('g')
                      .attr("class","rect5")
                      .selectAll(".rect")
                      .data(this.tableData5)
                      .enter()
                      .append("rect")
                      .attr("class","rect5")
                      .attr("id",function(d,i)
                      {
                        return d.titles;
                      })
                      .attr("titles",function(d,i)
                      {
                        return d.titles;
                      })
                      .attr("x", function(d,i){
                        return xScale5(i);
                       })
                      .attr("y", function(d,i){
                        return height*3-yScale(parseFloat(d.height))*3;
                         })
                      .attr("width",bandwidth5)
                      .attr("height",function(d,i)
                      { return yScale(parseFloat(d.height))*3;
                      })   
                      .attr("fill",function(d,i)
                      {return color(i);})
                     
       let text5 = svg.append('g')
                     .selectAll(".text5")
                     .data(this.tableData5)
                     .enter()
                     .append("text")
                     .attr("fill",function(d,i)
                     {
                       return color(i);
                     })
                     .attr("font-size","10px")
                     .attr("text-anchor","middle")
                     .attr("width",10)
                     .attr("x",function(d,i)
                     {
                          return xScale5(i);
                      })
                     .attr("y", function(d){
                        return  3*height-yScale(parseFloat(d.height))-bandwidth5*1.8;
                               })
                     .attr("dx",10)
                     .attr("dy",10)
                     .attr(
          "transform",
          (d, i) =>
            `rotate(-90,${xScale5(i)},${3*height-yScale(parseFloat(d.height))-bandwidth5*1.8})`
        )
                     .text(function(d,i){
                         return d.titles;
                         })  
      let rect6 = svg.append('g')
                      .attr("class","rect6")
                      .selectAll(".rect")
                      .data(this.tableData6)
                      .enter()
                      .append("rect")
                      .attr("class","rect6")
                      .attr("id",function(d,i)
                      {
                        return d.titles;
                      })
                      .attr("titles",function(d,i)
                      {
                        return d.titles;
                      })
                      .attr("x", function(d,i){
                        return xScale6(i);
                       })
                      .attr("y", function(d,i){
                        return height*2-yScale(parseFloat(d.height));
                         })
                      .attr("width",bandwidth6)
                      .attr("height",function(d,i)
                      { return yScale(parseFloat(d.height))+10;
                      })   
                      .attr("fill",function(d,i)
                      {return color(i);})
                    
       let text6 = svg.append('g')
                     .selectAll(".text6")
                     .data(this.tableData6)
                     .enter()
                     .append("text")
                     .attr("fill",function(d,i)
                     {
                       return color(i);
                     })
                     .attr("font-size","10px")
                     .attr("text-anchor","middle")
                     .attr("width",10)
                     .attr("x",function(d,i)
                     {
                          return xScale6(i)+10;
                      })
                     .attr("y", function(d){
                        return  2*height-yScale(parseFloat(d.height))-bandwidth6*1.8;
                               })
                     .attr("dx",10)
                     .attr("dy",10)
                     .attr(
          "transform",
          (d, i) =>
            `rotate(-90,${xScale6(i)},${2*height-yScale(parseFloat(d.height))-bandwidth6*1.8})`
        )
                     .text(function(d,i){
                         return d.titles;
                         })       
    }    
  },
  mounted () {
    this.getInfo()
  },
};
</script>

<style>
.el-slider__bar {
  background-color: #e2a253 !important;
}
.el-slider__button {
  border: 2px solid #e2a253 !important;
}
</style>

<style scoped>
  .hist-graph {
    height: 200vh; 
    width: 90vw;
    margin: 0 auto; 
    margin-top: 0vh; 
  }
 .el-footer {
  color: gray;
  font-size: 16px;
  text-align: center;
  top: 150vh!important;;
} 
.el-container {
  height: 200vh;
  justify-content:space-between;
}

</style>
