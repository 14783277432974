<template>
    <!-- 左布局 -->
    <div style="width:100%; height: 77vh;float: left; padding:0 3px">
        <!-- 影人信息 -->
        <el-card class="card_shadow" style="height: 77vh;overflow-y: scroll;background-color: rgba(255, 255, 255, 0.1); color: #F0EBE1">
            <el-row>
                <el-col>
                    <div style="padding: 10px 0">
                        <span v-if="$store.state.login_state"
                            style="float:right; margin-right:35px; font-size:28px; margin-top:20px"
                            class="el-icon-edit mouse_over_head text_color" @click="dialogEditVisible = true"></span>
                    </div>
                    <div style="text-indent: 2em;
                    margin-right: 1.5vw;
                    margin-top: 5px;
                    line-height: 26px;
                  " v-html="post_content"></div>
                    <br />
                    <div></div>
                </el-col>
            </el-row>
        </el-card>

        <!-- 影人编辑页面 -->
        <el-dialog class="el-dia" title="影人编辑" :visible.sync="dialogEditVisible" width="50%" append-to-body>
            <el-dialog title="提示" :visible.sync="make_sure" width="30%" append-to-body>
                <span>确定要修改信息？</span>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="make_sure = false">取 消</el-button>
                    <el-button type="warning" @click="make_sure_click">确 定</el-button>
                </span>
            </el-dialog>
            <el-upload class="upload-demo" ref="upload" list-type="picture" action :limit="limit_num"
                :on-preview="handlePreview" :on-remove="handleRemove" :on-change="handleChange" :auto-upload="false"
                style="margin: 15px 0">
                <el-button slot="trigger" size="medium" type="warning">点击上传影人图片</el-button>
                <div slot="tip" class="el-upload__tip" style="margin-left:3px">支持上传jpg/jpeg/png文件，且不超过500kb</div>
            </el-upload>
            <!-- <el-form>
                <el-form-item label="影人" width="100px" style="margin-top:15px">
                    <el-input v-model="edit_dir_name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="诞辰" width="100px" style="margin-top:5px; width:30%; display:inline-block">
                    <el-date-picker v-model="edit_birth_time" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
                        value-format="yyyy年MM月dd日" style="width:100%"></el-date-picker>
                </el-form-item>
                <el-form-item label="配偶" width="100px" style="margin:5px 0 0 30px; width:30%; display:inline-block">
                    <el-input v-model="edit_spouse" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="性别" width="100px" style="margin:5px 0 0 30px; width:20%; display:inline-block">
                    <el-radio-group v-model="edit_dir_sex">
                        <el-radio label="男"></el-radio>
                        <el-radio label="女"></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="简介" width="100px" style="margin-top:15px">
                    <el-input v-model="edit_brief" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="详述" width="100px" style="margin-top:15px">
                    <el-input type="textarea" :rows="10" v-model="edit_direction" autocomplete="off"></el-input>
                </el-form-item>
            </el-form> -->
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogEditVisible = false">取 消</el-button>
                <el-button type="warning" @click="edit_director()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>import service from "../../utils/request";

export default {
    name: 'DirectorInfo',
    props: {
        'entity_direction': String,
        'current_id': undefined
    },
    data() {
        return {
            dialogEditVisible: false,
            edit_dir_name: '',
            edit_birth_time: '',
            edit_brief: '',
            edit_dir_sex: '',
            edit_direction: '',
            edit_spouse: '',
            fileList: [],
            limit_num: 1,
            img_file: [],
            make_sure: false,
            post_content: '',
            now_id: '',
            pic_id: "",
        };
    },
    watch: {
        entity_direction: function (val) {
            this.post_content = val
            // this.post_content = unescape(this.post_content.replace(/\u/g, "%u"));
            this.post_content = this.post_content.replace(/\[\[([^\]]*)\]\]/g, "<a style='text-decoration: none;color:#c45c18' href='/#/PostTitle?wanted=$1'>$1</a>");
        },
        current_id: function (val) {
            this.now_id = val
            // console.log("当前影人id：" + this.now_id)
        }
    },
    methods: {
        //影人编辑
        edit_director() {
            this.make_sure = true
            // this.dialogEditVisible = true
            // this.edit_dir_name = this.dir_name
            // this.edit_birth_time = this.birth_time
            // this.edit_dir_sex = this.dir_sex
            // this.edit_brief = this.brief
            // this.edit_direction = this.direction
            // this.edit_spouse = this.spouse
        },
        //影人编辑最终确认
        make_sure_click() {
            this.make_sure = false
            this.dialogEditVisible = false

            //信息上传
            // service({
            //     url: '/figures/update',
            //     method: 'post',
            //     data: {
            //         'fid': this.dir_id,
            //         'now_birth_time': this.edit_birth_time,
            //         'now_sex': this.edit_dir_sex,
            //         'now_f_name': this.edit_dir_name,
            //         'now_f_work': this.edit_direction,
            //         'now_f_brief': this.edit_brief,
            //         'now_spouse': this.edit_spouse,
            //         'isfigure': this.isfigure
            //     }
            // }).then((response) => {
            //     if (response.status == '200') {
            //         this.dir_name = this.edit_dir_name
            //         this.brief = this.edit_brief
            //         this.direction = this.edit_direction
            //         this.birth_time = this.edit_birth_time
            //         this.spouse = this.edit_spouse
            //         this.$message({
            //             message: '基本信息修改成功！',
            //             type: 'success'
            //         });
            //     } else {
            //         this.$message({
            //             message: '基本信息修改好像出问题了！',
            //             type: 'warning'
            //         });
            //     }
            // })
            //图片上传
            if (this.img_file.length == 1) {
                let file = this.img_file[0].raw
                let param = new FormData() //form对象
                let config = {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }
                param.append('file', file)
                this.axios
                    .post('https://api.movie.yingshinet.com/post/pic/' + this.now_id, param, config)
                    .then((response) => {
                        if (response.status == '200') {
                            this.$message({
                                message: '影人照片修改成功！',
                                type: 'success',
                            });
                            location.reload();
                        } else {
                            this.$message({
                                message: '影人照片好像出了点问题',
                                type: 'warning'
                            });
                        }
                        location.reload()
                    })
            }
        },
        //手动上传
        submitUpload() {
            this.$refs.upload.submit();
        },
        //上传图片钩子函数
        handleRemove(file, fileList) {
            this.img_file.length = 0
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        handleChange(file, fileList) {
            this.img_file.push(file)
            console.log(this.img_file, fileList);
        },
    },
};
</script>

<style>
::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
}

.text_color {
    font-size: 18px;
    color: #b8860b;
}

.mouse_over_head:hover {
    color: #b8860b;
    cursor: pointer;
}

.mouse_over_work:hover {
    cursor: pointer;
}

.title_style {
    list-style-type: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
}

.el-dia .el-dialog__body {
    padding: 15px 40px 10px 40px;
}

.el-dia .el-form-item {
    margin-bottom: 10px;
}

.card_shadow {
    box-shadow: 3px 4px 1px -2px rgb(0 0 0 /16%), 0 2px 2px 0 rgb(0 0 0 / 14%),
        0 1px 5px 0 rgb(0 0 0 / 12%);
}
</style>