<template>
  <div>
    <el-card :class="!this.$store.state.isCollapse ? 'container-border' : 'container-border-mobile'">
      <el-button type="success" size="mini" @click="monthsortchart(barchartdata)" plain>时间排序</el-button>
      <el-button type="primary" size="mini" @click="numsortchart(barchartdata)" plain>频次排序</el-button>
      <el-button type="warning" size="mini" @click="clearLimit()" plain>清空限制</el-button>
      <div style="overflow-y: hidden; overflow-x: scroll; width: 100%;">
        <svg id="barchart" class="barchart-border"></svg>
      </div>
    </el-card>
  </div>
</template>

<script>
import * as d3 from "d3";
import * as $ from "jQuery";
import store from "../../store/index";

export default {
  name: "ChronicleBarchart",
  data() {
    return {
       path: "https://api.movie.yingshinet.com/chronicles",
      barchartdata: [],
      director: "",
      // 四十位影人名单
      directors: [],
      path_currency: "https://api.movie.yingshinet.com",
    };
  },
  watch: {
    "$store.state.current_figure": {
      async handler(val, oldVal) {
        // console.log(val, oldVal, "watchfigure监听事件");
        if (this.directors.indexOf(`${val}`) != -1) {
          await this.getalldata();
          setTimeout(() => {
            this.clearLimit();
            this.drawbarchart();
          }, 0);
        }
      },
    },
  },

  methods: {

    clearLimit() {
      this.$store.commit("setkeywordSearch", "");
      this.$store.commit("setMonthSearch", "");
      this.$store.commit("setYearSearch", "");
    },

    async update(data) {
      function drawrect(data) {
        let svg = d3.select("#barchart").selectAll("*").remove();

        const color = d3.scaleOrdinal(d3.schemeCategory10);
        let w;
        let h;
        if(!store.state.isCollapse) {
          w = $(".container-border").width();
          h = $(".container-border").height();
        }
        else {
          w = $(".container-border-mobile").width()*2;
          h = $(".container-border-mobile").height();
        }

        const margin = { top: 30, right: 50, bottom: 80, left: 30 },
          width = w - margin.left - margin.right,
          height = h - margin.top - margin.bottom;

        svg = d3.select("#barchart")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr(
                  "transform",
                  "translate(" + margin.left + "," + margin.top + ")"
                );

        const x = d3.scaleBand().range([0, width]).padding(0.2);
        const xAxis = svg.append("g")
                         .attr("transform", `translate(0, ${height})`).style("color", "#F0EBE1");
        // x.domain(data.map((d) => d.month));
        x.domain(data.map((d) => d.year));
        xAxis.call(d3.axisBottom(x));
        xAxis.call(g => {
                g.selectAll("text")
                 .attr("transform", "rotate(-45)")
                 .style("text-anchor", "end")
                 .style("color", "#F0EBE1");
             });

        const y = d3.scaleLinear().range([height, 0]);
        const yAxis = svg.append("g").attr("class", "myYaxis").style("color", "#F0EBE1");
        y.domain([0, d3.max(data, (d) => d.value)]);
        yAxis.transition().duration(1000).call(d3.axisLeft(y));
        var u = svg.selectAll("rect").data(data);

        u.join("rect")
          .transition()
          .duration(1000)
          // .attr("x", (d) => x(d.month))
          .attr("x", (d) => x(d.year))
          .attr("y", (d) => y(d.value))
          .attr("width", x.bandwidth())
          .attr("height", (d) => height - y(d.value))
          .attr("fill", (_, i) => color(i))
          .attr("opacity", 0.7)
          .attr("class", function (d) {
            return "myRect";
          })
          .attr("id", function (d) {
            return d.group;
          });

        u.enter()
          .append("g")
          .append("text")
          .transition()
          .duration(1000)
          // .attr("x", (d) => x(d.month))
          .attr("x", function (d) {
            return x(d.year);
          })
          .attr("y", function (d) {
            return y(d.value);
          })
          .attr("font-size", 12)
          .attr("dx", x.bandwidth() / 2 - 6)
          .attr("dy", -3)
          .attr("fill", "#F0EBE1")
          .attr("class", function (d) {
            return "myText";
          })
          .text(function (d) {
            return d.value;
          });
      }
      await drawrect(data);
      setTimeout(() => {
        var that = this;
        d3.selectAll(".myRect")
          .on("mouseover", function (d, i) {
            d3.select(this).transition().attr("opacity", 1);
          })
          .on("mouseleave", function () {
            d3.select(this).transition().attr("opacity", 0.7);
          })
          .on("click", function () {
            // 点击时间跳转
            // let month = this.getAttribute("id");
            //   that.$store.commit("setMonthSearch", month);
            let year = this.getAttribute("id");
            that.$store.commit("setYearSearch", year);
          });
      }, 1000);
    },

    addFunc() { },

    async monthsortchart(data) {
      let datas = this.monthsort(data);
      await this.update(datas);
      this.addFunc();
    },

    async numsortchart(data) {
      let datas = this.numsort(data);
      await this.update(datas);
      this.addFunc();
    },

    async drawbarchart(data = this.barchartdata) {
      await this.update(data);
      this.addFunc();
    },

    // 搁置的月份查询
    async getmonthdata(figure, month = 1) {
      const keyJson = {
        // searchData: "山东",
        // month: 1,
        // figure: "费穆",
      };
      if (month) {
        keyJson.month = month;
      }
      keyJson.figure = figure;
      await this.axios
        // .post(this.path + "/month", {
        .post(this.path + "/keyword", keyJson)
        .then((response) => {
          // console.log("getmonthdata", response);
          let monthdata = {
            group: Number.parseInt(`${month}`),
            month: `${month}月`,
            value: Number.parseInt(response.data.searchShow.length),
          };
          this.barchartdata.push(monthdata);
        });
    },

    async getalldata2() {
      let figure = (this.director = this.$store.state.current_figure);
      this.barchartdata = [];
      for (let i = 1; i < 13; i++) {
        await this.getmonthdata(figure, i);
      }
    },

    //新的年份查询 --------------------------------------------------
    async getalldata() {
      let figure = (this.director = this.$store.state.current_figure);
      this.barchartdata = [];
      await this.getyearcount(figure);
    },
    async getyearcount(figure, related) {
      const keyJson = { figure_name: "费穆", related: "" };
      if (figure) {
        keyJson.figure_name = figure;
      }
      if (related) keyJson.related = related;
      var that = this;
      await this.axios.post(this.path + "/count", keyJson).then(async( response) => {
        let yearsData = response.data.data
        if (yearsData[0]) {//存在事件才继续，否则报错
          let startyear = yearsData[0].year;
          let endyear = yearsData[response.data.count - 1].year;
          for (let year = startyear, curindex = 0, repeat = 0; year <= endyear; year++) {
            //year为当前循环年，curindex表示有数据的第几个年，repeat控制每两个空年，展示一次。
            if (year == yearsData[curindex].year) {
              let yearcountdata = {
                group: Number.parseInt(year),
                year: `${year}`,
                value: Number.parseInt(yearsData[curindex].count),
              };
              that.barchartdata.push(yearcountdata);
              curindex++;
              repeat = 0
            } else {
              if (this.isPowerOfTwo(repeat)) {
                let yearcountdata = {
                  group: Number.parseInt(year),
                  year: `${year}`,
                  value: Number.parseInt(0),
                };
                that.barchartdata.push(yearcountdata);
              }
              repeat++
            }
          }
        }
        //如果主要人物没有数据，就再次查询相关人物的数据
        else {
          // console.log("主要人物没有数据")
          const keyJson2 = {related: this.$store.state.current_figure };
          await this.axios.post(this.path + "/count", keyJson2).then((response) => {
            let yearsData = response.data.data
            if (yearsData[0]) {//存在事件才继续，否则报错
              let startyear = yearsData[0].year;
              let endyear = yearsData[response.data.count - 1].year;
              for (let year = startyear, curindex = 0, repeat = 0; year <= endyear; year++) {
                //year为当前循环年，curindex表示有数据的第几个年，repeat控制每两个空年，展示一次。
                if (year == yearsData[curindex].year) {
                  let yearcountdata = {
                    group: Number.parseInt(year),
                    year: `${year}`,
                    value: Number.parseInt(yearsData[curindex].count),
                  };
                  that.barchartdata.push(yearcountdata);
                  curindex++;
                  repeat = 0
                } else {
                  if (this.isPowerOfTwo(repeat)) {
                    let yearcountdata = {
                      group: Number.parseInt(year),
                      year: `${year}`,
                      value: Number.parseInt(0),
                    };
                    that.barchartdata.push(yearcountdata);
                  }
                  repeat++
                }
              }
            }

          });
        }
      });
    },

    monthsort(obj) {
      let arr = [...obj];
      return arr.sort(function (a, b) {
        return a.group > b.group ? 1 : -1;
      });
    },

    numsort(obj) {
      let arr = [...obj];
      return arr.sort(function (a, b) {
        return a.value > b.value ? -1 : 1;
      });
    },

    isPowerOfTwo(n) {
      return n > 0 && (n & (n - 1)) === 0;
    }
  },

  async mounted() {
    this.axios.get(this.path_currency + "/figures").then((response) => {
      for (var i = 0; i < response.data.f_data.length; i++) {
        this.directors.push(response.data.f_data[i].f_name);
      }
    });
    this.director = this.$store.state.current_figure;
    await this.getalldata();
    console.log(this.barchartdata)
    this.drawbarchart();

    //------------------------------------------
    // this.getalldata2();
  },
};
</script>

<style>
.myRect:hover {
  cursor: pointer;
}
.mytext {
  text-align: center;
}
.container-border {
  height: 60vh; 
  width: 65vw; 
  margin: 1vh 3vw 0 0.5vw;
}
.container-border-mobile {
  height: 35vh; 
  width: 99vw;
  margin: 1vh 3vw 0 0.5vw;
}
</style>

<style scoped>
.el-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(0, 0, 0, 0.5);
  border-width: 2px;
  border-style: solid;
}
</style>