<template>
  <div>
    <el-table :data="allData" class="table" border>
      <el-table-column prop="s_name" label="影人名称" align="center"></el-table-column>
      <el-table-column prop="s_count" label="互动次数" align="center"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import service from "../../utils/request";

export default {
  props: ["director", "directors"],
  name: "ChronicleNet",
  inject: ["reload"],

  data() {
    return {
      path: "https://api.movie.yingshinet.com/chronicles",
      allData: [],
      social: [],
      itemLength: 0,
      socialCache: {}, // 用于存储缓存数据的对象
    };
  },

  watch: {
    director(curVal, oldVal) {
      this.director = curVal;
      this.getEvents();
    },
  },

  methods: {
    getEvents() {
      const cachedData = this.socialCache[this.director];
      if (cachedData) {
        // 如果有缓存数据，直接使用缓存数据
        this.allData = cachedData;
        console.log("这是缓存数据");
      } else {
        console.log("这是实时响应数据");
        // 如果没有缓存数据，调用后端接口获取数据。获取当前影人的社交网络
        this.axios.post(this.path + "/select_social", { content_figure: this.director })
          .then((response) => {
            this.social = response.data.c_data;
            this.itemLength = response.data.c_count;
            this.allData = [];
            for (var i = 0; i < this.itemLength; i++) {
              if (this.social[i].s_name !== this.director) {
                this.allData.push({
                  s_name: this.social[i].s_name,
                  s_count: this.social[i].s_count,
                })
              }
            }
            // 将数据存储到缓存对象中
            this.socialCache[this.director] = this.allData;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    clearCache() {
      // 清空缓存数据
      this.socialCache = {};
    },
  },

  mounted() {
    // 每隔一天清空一次缓存
    setInterval(this.clearCache, 24 * 60 * 60 * 1000);
    this.getEvents();
  },
};
</script>

<style scoped>
.table {
  max-height: 73vh;
  width: 90%;
  margin: auto;
  overflow: scroll;
}
:deep(.table .el-table__body tr > td) {
  background-color: #606266 !important;
  color: #F0EBE1 !important;
}
:deep(.table .el-table__body tr:hover>td) {
  background-color: #606266cf !important;
}
:deep(.table .el-table__header-wrapper tr th) {
  background-color: #606266 !important;
  color: #F0EBE1 !important;
}
:deep(.table .el-table__empty-block) {
  background-color: #606266 !important;
}
:deep(.table .el-table__empty-text) {
  color: #F0EBE1 !important;
}
</style>