<template>
  <!-- 左布局 -->
  <div style="width: 100%; height: auto; float: left; padding: 0 0.23vw">
    <!-- 影人信息 -->
    <el-card class="card_shadow" style="height: auto; background-color: rgba(255, 255, 255, 0.1);
      box-shadow: 0px 0px 1px -2px rgb(0 0 0 /16%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);">
      <el-row :gutter="20">
        <!-- 照片 -->
        <el-col :span="7" >
          <div v-show="window_width>768" style="padding: 1.95vw 0vw 1.95vw 2.73vw">
            <el-image style="height: 22.5vw; border-radius: 0.6vw" :src="url" :preview-src-list="srcList"></el-image>
          </div>
        </el-col>
        <!-- 简介 -->
        <el-col :span="window_width>768? 17:24">
          <div style="padding: 0.8vw 0vw">
            <span v-if="$store.state.login_state"
              style="margin-right: 0.8vw; margin-top: 1.6vw"
              class="el-icon-edit mouse_over_head text_color" @click="dialogCheck = true">
            </span>
          </div>
          <el-scrollbar>
            <div class="intro" style="text-indent: 2em; margin-right: 1.5vw; 
              margin-top: 0.5vw;" v-html="entity_direction">
            </div>
          </el-scrollbar>
        </el-col>
      </el-row>
    </el-card>

    <!--相关年谱-->
    <el-card class="content_block" v-show="showworks" style="margin: 0.78vw 0 0.4vw 0;  
      box-shadow: 0px 0px 1px -2px rgb(0 0 0 /16%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);">
      <div>
        <div style="padding: 0vw 0vw">
          <span class="el-icon-more mouse_over_head text_color" @click="tochroniclelist" 
            style="margin-right: 0.8vw">
          </span>
        </div>
        <el-scrollbar class="list_content" :native="true">
          <el-item v-for="(eventItem, index) in allData" :key="eventItem.cid">
            <el-card class="content" v-show="window_width>768">
              <div style="margin-top: 0vw">
                <!-- 内容 -->
                <span class="itemdata">{{ allData[index].content }}</span>
                <span class="itemfont">
                  --创建人：{{ allData[index].c_create_man }} --创建日期：{{ allData[index].c_create_time }}
                </span>
              </div>
            </el-card>
            <el-card class="content" v-show="window_width<=768" v-if="index<3">
              <div style="margin-top: 0vw">
                <!-- 内容 -->
                <span class="itemdata">{{ allData[index].content }}</span>
                <span class="itemfont">
                  --创建人：{{ allData[index].c_create_man }} --创建日期：{{ allData[index].c_create_time }}
                </span>
              </div>
            </el-card>
          </el-item>
        </el-scrollbar>
      </div>
    </el-card>

    <!-- 影片代表作 -->
    <el-card class="content_block" v-show="showworks" style="margin-top: 0.78vw;
      box-shadow: 0px 0px 1px -2px rgb(0 0 0 /16%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);">
      <div style="overflow-x: hidden">
        <div style="display: inline-block; margin:1.3vw 1.2vw" v-for="(movie_pic, index) in movie_pics" :key="index">
          <div>
            <img class="mouse_over_work" :src="movie_pic.m_pic" @click="movie_click(movie_pic.mid)" />
            <div style="margin-top: 0.5vw" align="center">
              <span class="mouse_over" @click="movie_click(movie_pic.mid)" style="font-size: 1vw">
                {{ movie_pic.m_name }}
              </span>
            </div>
          </div>
        </div>
        <div v-show="this.movie_pics.length >= 8"
          style="display: inline-block; vertical-align: top; line-height: 3vw">
          <el-tooltip content="更多" placement="bottom">
            <i class="el-icon-more mouse_over_head text_color"
              style="margin-right: -13vw" @click="more_movie">
            </i>
          </el-tooltip>
        </div>
      </div>
    </el-card>

    <!-- 文献代表作 -->
    <el-card class="literature_block" v-show="showworks" style="margin: 0.78vw 0vw;
      box-shadow: 0px 0px 1px -2px rgb(0 0 0 /16%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);">
      <div style="overflow-x: hidden; white-space: nowrap">
          <div style="display: inline-block; margin: 1.3vw 1.2vw" align="left" 
            v-for="(essay, index) in (currentPage==1? essays : essays_next)" :key="index">
          <div>
            <img class="mouse_over_work" :src="essay.thumbnail" @click="essay_click(essay.wid)" />
            <div style="margin-top: 0.5vw; margin-left: -1vw;" align="center">
              <li class="mouse_over title_style" style="font-size: 1vw" @click="essay_click(essay.wid)">
                {{ essay.title }}
              </li>
            </div>
          </div>
        </div>
        <div v-show="window_width>768" style="display: inline-block; vertical-align: top; margin: 0.8vw 2.5vw;">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" 
            :current-page="currentPage" :page-size="pagesize" layout="prev, next">
          </el-pagination>
        </div>
      </div>
    </el-card>

    <!--判断是否为影人界面-->
    <el-dialog title="提示" :visible.sync="dialogCheck" width="50%" append-to-body>
      <span>修改的是否为影人信息？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="edit_movie_click()">否</el-button>
        <el-button type="warning" @click="edit_click()">是</el-button>
      </span>
    </el-dialog>
    <!-- 非影人编辑页面 -->
    <el-dialog class="el-dia" title="非影人编辑" :visible.sync="nonpeopleEdit" fullscreen>
      <el-dialog title="提示" :visible.sync="make_sure" width="30%" append-to-body>
        <span>确定要修改信息？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="make_sure = false">取 消</el-button>
          <el-button type="warning" @click="make_sure_click">确 定</el-button>
        </span>
      </el-dialog>
      <el-upload class="upload-demo" ref="upload" list-type="picture" action :limit="limit_num"
        :on-preview="handlePreview" :on-remove="handleRemove" :on-change="handleChange" :auto-upload="false"
        style="margin: 15px 0">
        <el-button slot="trigger" size="medium" type="warning">点击上传影片图片</el-button>
        <div slot="tip" class="el-upload__tip" style="margin-left:3px">支持上传jpg/jpeg/png文件，且不超过1mb</div>
      </el-upload>
      <el-form>
        <el-form-item label="影片" width="100px" style="margin-top:15px">
          <el-input v-model="edit_dir_name" autocomplete="off" :readonly="true"></el-input>
        </el-form-item>
        <el-form-item label="时间" width="100px" style="margin-top:5px; width:30%; display:inline-block">
          <el-date-picker v-model="edit_birth_time" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
            value-format="yyyy年MM月dd日" style="width:100%"></el-date-picker>
        </el-form-item>
        <el-form-item label="简介" width="100px" style="margin-top:15px">
          <el-input v-model="edit_brief" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="详述" width="100px" style="margin-top:15px">
          <el-input type="textarea" :rows="10" v-model="edit_direction" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="nonpeopleEdit = false">取 消</el-button>
        <el-button type="warning" @click="make_sure_click()">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 影人编辑页面 -->
    <el-dialog class="el-dia" title="影人编辑" :visible.sync="dialogEditVisible" fullscreen>
      <el-dialog title="提示" :visible.sync="make_sure" width="30%" append-to-body>
        <span>确定要修改信息？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="make_sure = false">取 消</el-button>
          <el-button type="warning" @click="make_sure_click">确 定</el-button>
        </span>
      </el-dialog>
      <el-upload class="upload-demo" ref="upload" list-type="picture" action :limit="limit_num"
        :on-preview="handlePreview" :on-remove="handleRemove" :on-change="handleChange" :auto-upload="false"
        style="margin: 15px 0">
        <el-button slot="trigger" size="medium" type="warning">点击上传影人图片</el-button>
        <div slot="tip" class="el-upload__tip" style="margin-left:3px">支持上传jpg/jpeg/png文件，且不超过1mb</div>
      </el-upload>
      <el-form>
        <el-form-item label="影人" width="100px" style="margin-top:15px">
          <el-input v-model="edit_dir_name" :readonly="true" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="诞辰" width="100px" style="margin-top:5px; width:30%; display:inline-block">
          <el-date-picker v-model="edit_birth_time" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
            value-format="yyyy年MM月dd日" style="width:100%"></el-date-picker>
        </el-form-item>
        <el-form-item label="配偶" width="100px" style="margin:5px 0 0 30px; width:30%; display:inline-block">
          <el-input v-model="edit_spouse" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="性别" width="100px" style="margin:5px 0 0 30px; width:20%; display:inline-block">
          <el-radio-group v-model="edit_dir_sex">
            <el-radio label="男"></el-radio>
            <el-radio label="女"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="简介" width="100px" style="margin-top:15px">
          <el-input v-model="edit_brief" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="详述" width="100px" style="margin-top:15px">
          <el-input type="textarea" :rows="10" v-model="edit_direction" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="auth == 1" label="是否推荐" width="100px" 
            style="margin-top:15px; width:20%; display:inline-flex">
            <el-radio-group v-model="is_recommend">
                <el-radio label="是"></el-radio>
                <el-radio label="否"></el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item v-if="auth == 1" label="推荐指数" width="100px" 
            style="margin:15px 0 0 30px; width:30%; display:inline-flex">
            <el-input v-model="edit_recommend" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogEditVisible = false">取 消</el-button>
        <el-button type="warning" @click="edit_director()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>import service from "../../utils/request";

export default {
  name: 'DirectorInfo',
  props: {
    
    "showworks": Boolean,
    'movie_pics': Array,
    'essays': Array,
    'url': String,
    'srcList': Array,
    'dir_name': String,
    'direction': String,
    'brief': String,
    'birth_time': String,
    'dir_id': Number,
    'dir_sex': String,
    'entity_direction': String,
    'spouse': String,
    'recommend': Number,
  },
  watch:{
    essays(newName){
      this.essays_next = newName
    }
  },
  data() {
    return {
      path: "https://api.movie.yingshinet.com",
      //翻页
      currentPage: 1, //初始页
      pagesize: 10, //    每页的数据
      essays_next:[],
      dialogEditVisible: false,
      //判断是否是影人信息
      dialogCheck: false,
      //非影人信息的编辑按钮
      nonpeopleEdit: false,
      edit_dir_name: '',
      edit_birth_time: '',
      edit_brief: '',
      edit_dir_sex: '',
      edit_direction: '',
      edit_spouse: '',
      fileList: [],
      limit_num: 1,
      allData:[],
      img_file: [],
      make_sure: false,
      //是否是影人信息字段
      isfigure: '',
      window_width: window.screen.width,
      // 是否是推荐影人
      is_recommend: '否',
      edit_recommend: 0,
      auth: window.sessionStorage.getItem("auth"),
    };
  },
  watch:{
    '$store.state.current_figure':function()
    {
      this.getEvents()
    }
  },
  methods: {
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange(size) {
      this.pagesize = size;
    },
    tochroniclelist()
    {
      this.$router.push('/ChronicleList')
    },
    handleCurrentChange(currentPage) {
      // console.log(this.$store.state.current_figure)
      this.essays_next=[]
      this.currentPage = currentPage;
      this.axios
        .post(this.path + "/essays/select", { writer: this.$store.state.current_figure,divide_page:1,page: this.currentPage,offset:8})
        .then((response) => {
          for (var i = 0; i < response.data.e_data.length; i++) {
            this.essays_next.push(response.data.e_data[i]);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    more_movie() {
      this.$router.push('/video_index')
    },
    director_click(name) {
    },
    //跳转传参
    movie_click(movie_id) {
      if(this.window_width<768){
        this.$message({
          showClose:true,
          message:'更多信息请见电脑端页面',
          type:'warning'
        });
      }
      this.$router.push('/video?id=' + movie_id)
    },
    essay_click(essay_id) {
      if(this.window_width<768){
        this.$message({
          showClose:true,
          message:'更多信息请见电脑端页面',
          type:'warning'
        });
      }
      this.$router.push('/essays?id=' + essay_id);
    },
    //非影人编辑
    edit_movie_click() {
      this.isfigure = 0
      this.dialogCheck = false
      this.nonpeopleEdit = true
      this.edit_dir_name = this.dir_name
      this.edit_birth_time = this.birth_time
      this.edit_brief = this.brief
      this.edit_direction = this.direction
      this.edit_spouse = this.spouse
    },
    //影人编辑
    edit_click() {
      this.isfigure = 1
      this.dialogCheck = false
      this.dialogEditVisible = true
      this.edit_dir_name = this.dir_name
      this.edit_birth_time = this.birth_time
      this.edit_dir_sex = this.dir_sex
      this.edit_brief = this.brief
      this.edit_direction = this.direction
      this.edit_spouse = this.spouse
      this.is_recommend = this.recommend==0? "否": "是"
      this.edit_recommend = this.recommend
    },
    //影人编辑
    edit_director() {
      this.make_sure = true
    },
    //影人编辑最终确认
    make_sure_click() {
      this.make_sure = false
      this.dialogEditVisible = false
      this.nonpeopleEdit = false
      //信息上传
      service({
        url: '/figures/update',
        method: 'post',
        data: {
          'fid': this.dir_id,
          'now_birth_time': this.edit_birth_time,
          'now_sex': this.edit_dir_sex,
          'now_f_name': this.dir_name,
          'now_f_work': this.edit_direction,
          'now_f_brief': this.edit_brief,
          'now_spouse': this.edit_spouse,
          'isfigure': this.isfigure,
          'now_f_delete': this.edit_recommend,
        }
      }).then((response) => {
        if (response.status == '200') {
          this.dir_name = this.dir_name
          this.brief = this.edit_brief
          this.direction = this.edit_direction
          this.birth_time = this.edit_birth_time
          this.spouse = this.edit_spouse
          this.recommend = this.edit_recommend
          this.$message({
            message: '基本信息修改成功！',
            type: 'success'
          });
        } else {
          this.$message({
            message: '基本信息修改好像出问题了！',
            type: 'warning'
          });
        }
      })
      //图片上传
      if (this.img_file.length == 1) {
        let file = this.img_file[0].raw
        let param = new FormData() //form对象
        let config = {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
        param.append('file', file)
        this.axios
          .post('https://api.movie.yingshinet.com/figures/pic/' + this.dir_id, param, config)
          .then((response) => {
            if (response.status == '200') {
              this.$message({
                message: '影人照片修改成功！',
                type: 'success',
              });
              location.reload();
            } else {
              this.$message({
                message: '影人照片好像出了点问题',
                type: 'warning'
              });
            }
          })
      }
    },
    //手动上传
    submitUpload() {
      this.$refs.upload.submit();
    },
    //上传图片钩子函数
    handleRemove(file, fileList) {
      this.img_file.length = 0
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleChange(file, fileList) {
      this.img_file.push(file)
      console.log(this.img_file, fileList);
    },
  
       sortYear(obj) {
      return obj.sort(function (a, b) {
        let x = a["c_create_time"]
        let y = b["c_create_time"]
        if(x<y) return 1
        else return -1
      })
    },
    getEvents () {
      const key = {
        'c_figure':this.$store.state.current_figure
      }
      this.axios.post(this.path + "/chronicles/select",key).then((response) => {
        console.log(response.data)
        this.movies = response.data.c_data;
        //年谱数据movies.c_content
        this.itemLength = this.movies.length;
        this.allData = [];
        let j=this.itemLength-1
        for (var i = 0; i < this.itemLength; i++) {
          //获取年谱所有数据
          this.allData[i] = {};
          this.allData[i].content = this.movies[j].c_content;
          this.allData[i].c_year = this.movies[j].c_year;
          this.allData[i].c_month = this.movies[j].c_month;
          this.allData[i].c_id = this.movies[j].cid;
          this.allData[i].c_date = this.movies[j].c_date;
          this.allData[i].c_create_man = this.movies[j].c_create_man;
          this.allData[i].c_source = this.movies[j].c_source;
          this.allData[i].c_create_time = this.movies[j].c_create_time;
          this.allData[i].c_figure=this.movies[j].c_figure;
          j--;
        }
        this.allData = this.sortYear(this.allData)
      });
      this.$set(this.allData);
    },},
  
    created() {
        this.getEvents()
        console.log("auth：", this.auth)
    }
  }
</script>

<style scoped>
.intro {
  height: 22.5vw;
  font-size: 1.2vw;
  line-height: 2.05vw;
}
.mouse_over_head:hover {
  color: #E2A253;
  cursor: pointer;
}
.text_color {
  font-size: 2.2vw;
  color: #b8860b;
  float: right;
}
.content_block {
  height: 9.4vw;
  background-color: rgba(255, 255, 255, 0.1);
}
.list_content {
  height: 40vw;
  width: 86vw;
  margin-top: -3.5vw;
  margin-left: 1vw;
}
.content {
  margin: 0.4vw 15vw 0vw 0vw;
}
.itemdata {
  font-size: 0.8vw;
  line-height: 1.3vw;
  color: #F0EBE1;
}
.itemfont {
  font-size: 0.8vw;
  line-height: 1.3vw;
  color: #a1a3a8;
}
.mouse_over_work {
  width: 100%; 
  height: 4.2vw; 
  width: 4.8vw; 
  display: block; 
  border-radius: 0.2vw;
}
.mouse_over_work:hover {
  cursor: pointer;
}
.literature_block {
  height: 9.4vw;
  background-color: rgba(255, 255, 255, 0.1);
}
.title_style {
  list-style-type: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 6.5vw;
}

@media screen and (max-width: 768px) {
  .intro {
    height: 40vh;
    font-size: 2vh;
    line-height: 3vh;
    margin: 0vh 2.5vh;
    padding-top: 1vh;
  }
  .text_color {
    font-size: 2vh;
  }
  .content_block {
    height: 20vh;
  }
  .list_content {
    height: 15vh;
    width: 100vw;
    margin-left: 1vh;
  }
  .content {
    margin: 0.3vh 3vh 0vh 0vh;
  }
  .itemdata {
    font-size: 1.5vh;
    line-height: 0.5vh;
  }
  .itemfont {
    font-size: 1.5vh;
    line-height: 0.5vh;
  }
  .mouse_over_work {
    height: 5vh; 
    width: 4.5vh;
    margin-left: 1vh;
    border-radius: 0.5vh;
  }
  .literature_block {
    height: 10vh;
  }
}

.el-dia .el-dialog__body {
  padding: 15px 40px 10px 40px;
}

.el-dia .el-form-item {
  margin-bottom: 10px;
}
.el-card {
  color: #cccccc;
}

.list_content .el-card {
  background-color: #606266;
  border: 1px solid #a1a3a8;
}
</style>
