<template>
    <div style="margin:2vh 20vw 20vh 20vw;">
         <p style="font-size:25px; color: #F0EBE1; font-family:STsong; font-weight:bold;" align="center">
           影人 影片 影事
        </p>
        <div style="height:4px; background-color:#E2A253"></div>
        <div style="height:2px; background-color:#E2A253; margin-top:2px"></div>
        <el-card style="width:60vw; color: #F0EBE1">
            <div slot="header" style="height:5vh">
                <h3 style="margin-top:0.5vh">简介</h3> </div> 
             
      <video-player
            class="video-player vjs-custom-skin"
            ref="VideoPlayer"
            :playsinline="true"
            :options="playerOptions"
            @play="onPlayerPlay($event)"
            @pause="onPlayerPause($event)"
            @ended="onPlayerEnded($event)"
            @waiting="onPlayerWaiting($event)"
            @playing="onPlayerPlaying($event)"
            @loadeddata="onPlayerLoadeddata($event)"
            @timeupdate="onPlayerTimeupdate($event)"
            @canplay="onPlayerCanplay($event)"
            @canplaythrough="onPlayerCanplaythrough($event)"
            @statechanged="playerStateChanged($event)"
            @ready="playerReadied"
          ></video-player>
                  
            <div class="text item" style="margin-top:1.5vh;font-size:1.3vw;text-indent:2em;line-height:2.4vw">中国电影知识体系平台（movie.yingshinet.com）由北京大学艺术学院与中国传媒大学文化大数据实验室合作开发，主要依托国家社科基金艺术学课题“影人年谱与中国电影史研究”以及国家社科基金艺术学重大课题“中国特色电影知识体系研究”，是在数字人文的基础上，在知识论与知识管理学的框架里搭建的一个众包群智、开源共享的，具有思辨性、成长性的中国电影数字基础设施，将以“影人年谱”“电影计量”“文献索引”与“知识生成”4个特色数据库为主并关联“相关平台”，力图与中国电影的史论研究、学术创新以及公益服务互动共生，为反思中国电影理论、推进中国电影学派并构建中国电影知识体系奠定必要的基础。</div>
            
            <br>
             <div class="text item" style="font-size:1.3vw;text-indent:2em;line-height:2.4vw">《墨子·经下》：影不徙，说在改为。</div>
             <div class="text item" style="font-size:1.3vw;text-indent:2em;line-height:2.4vw">《礼记．大学》：欲诚其意者，先致其知，致知在格物。</div>
            
            <div style="font-size:1.4vw;line-height:2.5vw;float:right; margin-bottom:1.6vw">——李道新</div>
        </el-card>
    </div>
</template>

<script>
export default {
    data(){
return{
    videoSrc:"https://assets.yingshinet.com/chronicles/videos/LSDXJ_FM.mp4",
    playStatus: '',
      muteStatus: '',
      isMute: true,
      isPlay: false,
      width: '820', // 设置视频播放器的显示宽度（以像素为单位）
      height: '500', // 设置视频播放器的显示高度（以像素为单位）
      preload: 'auto', //  建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
      controls: true, // 确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
      autoplay: '',
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0, 3.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 类型
            // src:require( '../assets/XCZC.mp4' )// url地址
            src: this.$store.state.login_state?"https://assets.yingshinet.com/chronicles/videos/introduction.mp4":"https://assets.yingshinet.com/chronicles/videos/introduction_720.mp4",
          },
        ],
        poster: "", // 封面地址
        notSupportedMessage: "暂时没有此视频资源，请观看《人生》、《联华交响曲》、《城市之夜》、《狼山喋血记》、《天伦》、《香雪海》、《生死恨》", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
}
    },

methods:
{
     // 播放回调
     onPlayerPlay(player) {
      // console.log("player play!", player);
    },
    // 暂停回调
    onPlayerPause(player) {
      // console.log("player pause!", player);
    },
    // 视频播完回调
    onPlayerEnded(player) {
      // console.log(player);
    },
    // DOM元素上的readyState更改导致播放停止
    onPlayerWaiting(player) {
      // console.log(player);
    },
    // 已开始播放回调
    onPlayerPlaying(player) {
      // console.log(player);
    },
    // 当播放器在当前播放位置下载数据时触发
    onPlayerLoadeddata(player) {
      // console.log(player);
    },
    // 当前播放位置发生变化时触发。
    onPlayerTimeupdate(player) {
      // console.log(player);
    },
    //媒体的readyState为HAVE_FUTURE_DATA或更高
    onPlayerCanplay(player) {
      // console.log('player Canplay!', player)
    },
    //媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
    onPlayerCanplaythrough(player) {
      // console.log('player Canplaythrough!', player)
    },
    //播放状态改变回调
    playerStateChanged(playerCurrentState) {
      // console.log("player current update state", playerCurrentState);
    },
    //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
    playerReadied(player) {
      // console.log("example player 1 readied", player);
}
}
};
</script>
<style scoped>
.el-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(0, 0, 0, 0.5);
  border-width: 2px;
  border-style: solid;
}
</style>