<template>
  <div style="margin-bottom: 3vw">
    <!-- 纵向年谱列表 -->
    <div style="width: 97.5vw;" class="chronicleList">
      <!-- 年谱列表搜索框 -->
      <el-card class="listSearch" style="width: 97.5vw">
        <!-- 年谱名字随选择的影人director变化  -->
        <img :src="currentFigureImage" class="director_img1" />
        <el-select class="director_select" v-model="director_select" filterable placeholder="选择影人">
          <el-option class="director_option" v-for="(director, index) in directors" :key="index" :label="director" :value="director">
          </el-option>
        </el-select>
        <el-input class="keywordSearchInput" v-model="keywordSearch" placeholder="年谱内容搜索">
          <el-button slot="append" icon="el-icon-search" class="search" @click="searchEvents">
          </el-button>
        </el-input>
        <el-button style="color: #b8860b; margin-left: 4vw; padding: 2.2vh 1vw; font-size: 1.1vw; 
          flex-wrap: wrap; margin-top: 0.3vh" @click="showAddListDrawer = true" v-if="this.$store.state.login_state">
          批量上传
        </el-button>
        <el-button v-if="this.$store.state.login_state" style="color: #b8860b; z-index: 1; flex-wrap: wrap; 
          margin-left: 1vw; padding: 2.2vh 1vw; font-size:1.1vw; margin-top: 0.3vh" @click="downloadchronicles">
          下载年谱
          <i class="el-icon-download el-icon--right"></i>
        </el-button>
        <el-button style="color: #b8860b; margin-left: 1vw; padding: 2.2vh 1vw; font-size: 1.1vw; 
          margin-top: 0.3vh; flex-wrap: wrap" @click="showMap = !showMap">
          地图切换
        </el-button>
        <el-dialog :visible.sync="showAddListDrawer" title="批量上传年谱列表">
          <el-upload action="alert" style="width: 50%; margin: 20px auto 0 auto" :on-preview="handlePreview"
            :on-remove="handleRemove" :on-change="handleChange" :auto-upload="false">
            <el-icon class="el-icon--upload">
              <upload-filled />
            </el-icon>
            <div class="el-upload__text">点击上传</div>
            <template #tip>
              <div class="el-upload__tip" style="margin-top: 50px; color: #409eff">
                文件格式限制为csv, xls, xlsx文件
              </div>
              <div class="el-upload__tip" style="color: #409eff">
                请确保excel文件中只有一个sheet
              </div>
              <div class="el-upload__tip" style="color: #409eff">
                文件中的time请设置单元格格式为“xxxx年x月x日”的常规格式
              </div>
            </template>
          </el-upload>
          <template #footer>
            <span class="dialog-footer">
              <el-button type="text" @click="downloadList">请先下载示例</el-button>
              <el-button style="padding: 12px 20px; font-size: 14px" type="success" @click="ListSubmit()">
                上传
              </el-button>
            </span>
          </template>
        </el-dialog>

        <el-dialog :visible.sync="isshowimage" style="width: 80%; margin: 20px auto 0 auto">
          <img :src="imgsrc" style="width: 80%" />
        </el-dialog>
        <span class="elebutton">
          <el-button style="color: #b8860b; box-shadow: 3px 3px 10px #aaaaaa" class="el-backtop" 
          icon="el-icon-plus" @click="dialog = true" circle v-if="this.$store.state.login_state">
          </el-button>
        </span>

        <el-dialog title="添加新的事件：时间、地点、人物、事件，四个最重要的元数据。" width="90%" height="100%" 
          :visible.sync="dialog" style="font-size: 1.4vw; text-align: left" class="base_color">
          <div style="margin: 20px">
            <el-form>
              <el-form-item label="日期" :label-width="formLabelWidth">
                <el-input style="width: 20%" v-model="c_year"></el-input>年
                <el-input style="width: 20%" v-model="c_month"></el-input>月
                <el-input style="width: 20%" v-model="c_date"></el-input>日
              </el-form-item>
              <span style="font-size: 10px; color: firebrick; margin-left: 50px; margin-bottom: 0px;">
                提示：如果没有月日，请输入当年的1月1日
              </span>
              <el-form-item style="margin-top: 20px" label="影人" :label-width="formLabelWidth">
                <el-select v-model="director_select" filterable placeholder="选择影人">
                  <el-option v-for="(director, index) in directors" :key="index" :label="director" :value="director">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item style="margin: 20px 0" label="内容 " :label-width="formLabelWidth">
                <el-input v-model="event_content" type="textarea" :autosize="{ minRows: 6, maxRows: 16 }"></el-input>
              </el-form-item>
              <el-form-item style="margin: 20px 0" label="来源" :label-width="formLabelWidth">
                <el-input v-model="event_source" type="textarea" :autosize="{ minRows: 3, maxRows: 8 }"></el-input>
                <span style="font-size: 10px; color: firebrick; margin-bottom: 0px">
                  提示：为保证学术严谨性，请您注明词条的文献出处。
                </span>
              </el-form-item>
              <el-form-item>
                <el-upload class="upload-demo" ref="upload" list-type="picture" action="alert" 
                  :limit="limit_num" :on-preview="handlePreview" :on-remove="handleRemove" 
                  :on-change="handleChange" :auto-upload="false" style="margin: 15px 0">
                  <el-button type="warning">点击上传事件图片</el-button>
                  <span style="font-size: 10px; color: firebrick; margin-bottom: 0px;">
                    支持上传jpg/jpeg/png文件，且不超过1mb
                  </span>
                </el-upload>
              </el-form-item>
            </el-form>
            <div style="width: 100%">
              <div style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                ">
                <el-button style="
                    transition: 0.1s;
                    font-weight: 500;
                    padding: 12px 20px;
                    font-size: 14px;
                    border-radius: 4p;
                  " @click="cancelForm">取 消</el-button>
                <el-button style="
                    transition: 0.1s;
                    font-weight: 500;
                    padding: 12px 20px;
                    font-size: 14px;
                    border-radius: 4p;
                  " type="warning" @click="handleClose" :loading="loading">{{ loading ? "提交中 ..." : "确 定"
                  }}</el-button>
              </div>
            </div>
          </div>
        </el-dialog>
      </el-card>
      
      <DirectorMap v-show="showMap == true" :cities="cities"></DirectorMap>
      <el-row :gutter="5" style="margin: 2% 1% 2% 1%">
        <el-col v-show="window_width > 768" :span="1.5" style="height: 7vh; margin-left: 3vw; padding: 0vh 1vw; border-radius: 0.5vw;" 
          :style="{ backgroundColor: year_arr[i] ? '#FFC78E' : '' }" v-for="(data, i) in all_year" :key="i">
          <p class="selectfont1" @click="year_fun(i)">{{ data }}年</p>
        </el-col>
        <el-col v-show="window_width <= 768" :span="5" style="height: 4.5vh; margin-left: 3vw; padding: 0.5vh 4vw; border-radius: 0.5vw;" 
          :style="{ backgroundColor: year_arr[i] ? '#FFC78E' : '' }" v-for="(data, i) in all_year" :key="i">
          <p class="selectfont2" @click="year_fun(i)">{{ data }}年</p>
        </el-col>
      </el-row>
      <el-scrollbar style="margin-top: 1%">
        <div class="box">
          <!-- 时间轴列表 -->
          <el-timeline style="width: 97.5vw">
            <!-- 时间列表的每一项 -->
            <el-timeline-item v-for="(eventItem, index) in replaceArr" :key="eventItem.cid">
              <el-card width="90vw !important">
                <div>
                  <span class="itemdata" v-html="allData[index].c_date + '，' + eventItem.content"></span>
                  <span class="itemfont">
                    --创建人:{{ allData[index].c_create_man }}&nbsp;--来源:{{ allData[index].c_source }}
                  </span>
                  <div v-show="allData[index].c_pic != null" style="float: right; margin-right: 0.5vw;
                      font-size: 1vw; margin-top: 0.2vw; cursor: pointer;" @click="showImage(allData[index].c_pic)">
                    <el-image :src="allData[index].c_pic" style="width: 4vw" />
                  </div>
                  <span v-if="$store.state.login_state" style="float: right; margin-right: 1.5vw; font-size: 1vw;
                      margin-top: 0.2vw;" class="el-icon-edit mouse_over_head" @click="editEventIem(allData[index])">
                  </span>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-scrollbar>
    </div>
    <!-- 编辑页面遮罩层 -->
    <el-dialog class="el-dia" width="90%" height="100%" title="年谱信息编辑：时间、地点、人物、事件，四个最重要的元数据。"
      :visible.sync="dialogEditVisible">
      <!-- 影人信息表单 -->
      <el-form>
        <el-form-item label="日期" width="10vw">
          <el-input style="width: 20%" v-model="update_year"></el-input>年
          <el-input style="width: 20%" v-model="update_month"></el-input>月
          <el-input style="width: 20%" v-model="update_date"></el-input>日
          <span style="
              font-size: 10px;
              color: firebrick;
              margin-left: 20px;
              margin-bottom: 0px;
            ">提示：如果没有月日，请输入当年的1月1日</span>
        </el-form-item>
        <!-- 原文不能修改做参考 -->
        <el-form-item label="原文" width="10vw">
          <el-popover placement="bottom-end" width="190" trigger="hover" content="请将修改结果填入下方输入框" style="font-size: 1vw">
            <el-input type="textarea" :rows="6" v-model="eventItemContent" autocomplete="off" :readonly="true"
              :disabled="true" slot="reference"></el-input>
          </el-popover>
        </el-form-item>
        <el-form-item label="修改为" width="10vw">
          <el-input type="textarea" :rows="6" v-model="tmpEventItemContent" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="来源" width="10vw">
          <el-input type="textarea" :rows="6" v-model="new_source" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-upload class="upload-demo" ref="upload" list-type="picture" action="alert" :limit="limit_num"
            :on-preview="handlePreview" :on-remove="handleRemove" :on-change="handleChange" :auto-upload="false"
            style="margin: 15px 0">
            <el-button type="warning">点击上传事件图片</el-button>
            <span style="font-size: 10px; color: firebrick; margin-bottom: 0px">
              支持上传jpg/jpeg/png文件，且不超过1mb
            </span>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="
            transition: 0.1s;
            font-weight: 500;
            padding: 12px 20px;
            font-size: 14px;
            border-radius: 4p;
          " @click="dialogEditVisible = false">取 消</el-button>
        <el-button style="
            transition: 0.1s;
            font-weight: 500;
            padding: 12px 20px;
            font-size: 14px;
            border-radius: 4p;
          " type="warning" @click="make_sure_click">确 定</el-button>
      </div>

      <!-- 编辑页面确认弹窗 -->
      <el-dialog title="提示" :visible.sync="make_sure" width="30%" append-to-body>
        <span>确定要修改信息？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="make_sure = false">取 消</el-button>
          <el-button type="warning" @click="make_sure_click">确 定</el-button>
        </span>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import service from "../utils/request";
import DirectorMap from "../components/director/director_map.vue";
export default {
  name: "chronicle_list",
  components: {
    DirectorMap,
  },
  data() {
    return {
      window_width: window.screen.width,
      // 编辑模版
      //遮罩层是否显示
      dialogEditVisible: false,
      make_sure: false,
      colors: ["#b8860b", "#778899"],
      director: "",
      dirctors_pic: [
        //40位影人照片
      ],
      year_arr: [],
      directors: [
        //40位影人名单
      ],
      download_data: [],
      allData: [], //所有年谱数据，数组['','','',...]
      // director_search: "",
      keywordSearch: "",
      MonthSearch: "",
      path: "https://api.movie.yingshinet.com/chronicles",
      path_currency: "https://api.movie.yingshinet.com",
      dialog: false,
      loading: false,
      currentFigureImage: "",
      edit_event_item: "",
      eventItemContent: "",
      tmpEventItemContent: "",
      movie: [],
      othermovie: [],
      otheritemLength: 0,
      itemLength: 0,
      formLabelWidth: "50px",
      event_content: "",
      update_date_value: new Date(1937, 0, 1),
      chronicle_id: "",
      chronicle_pic: "",
      update_date_string: "",
      //批量上传年谱列表
      showAddListDrawer: false,
      //年谱文件
      chronicle_file: [],
      update_year: "",
      update_month: "",
      update_date: "",
      director_select: "",
      current_figure: "",
      tmpdirector: "", //添加事件时产生bug,矫正vuex中的director
      event_source: "",
      c_year: "",
      c_month: "",
      c_date: "",
      str: "",
      showMap: false,
      new_source: "",
      new_pic_id: "",
      image_file: [],
      limit_num: 1,
      isshowimage: false,
      imgsrc: "",
      cities: [],
      allData_by_update: [], //用户修改数据
      //记录所有的年份
      all_year: [],
      year_isSelect: false,
    };
  },
  watch: {
    "$store.state.keywordSearch": {
      handler(val, oldVal) {
        this.keywordSearch = val;
      },
    },
    "$store.state.MonthSearch": {
      handler(val, oldVal) {
        this.MonthSearch = val;
      },
      "$store.state.current_figure": {
        handler(val, oldVal) {
          this.searchEvents();
        },
      },
    },
    keywordSearch: {
      handler(val, oldVal) {
        this.searchEvents();
      },
    },
    // MonthSearch: {
    //   handler(val, oldVal) {
    //     this.searchEvents();
    //   },
    // },
    director_select: {
      handler(val, oldVal) {
        const number = this.directors.indexOf(val);
        if (number != -1) {
          this.currentFigureImage = this.dirctors_pic[number];
        }
        this.director = this.director_select;
        this.$store.commit("set_current_figure", this.director_select);
        this.$store.commit("set_current_figure_pic", this.currentFigureImage);
        this.$store.commit("setkeywordSearch", "");
        // this.$store.commit("setMonthSearch", "");
        this.getAllYear();
      },
    },
  },
  methods: {
    year_fun(num) {
      const selected_year = this.all_year[num];
      const config = {
        figure: this.director,
        selected_year: selected_year,
      };
      for (var i = 0; i < this.year_arr.length; i++) {
        this.year_arr[i] = false;
      }
      this.year_arr[num] = true;
      this.axios
        .post("https://api.movie.yingshinet.com/chronicles/select_year", config)
        .then((response) => {
          this.movies = response.data.searchShow;
          this.itemLength = this.movies.length;
          this.allData = [];
          for (var i = 0; i < this.itemLength; i++) {
            //获取年谱所有数据
            this.allData[i] = {};
            this.allData[i].content = this.movies[i].c_content;
            this.allData[i].c_year = this.movies[i].c_year;
            this.allData[i].c_month = this.movies[i].c_month;
            this.allData[i].c_id = this.movies[i].cid;
            this.allData[i].c_date = this.movies[i].c_date;
            this.allData[i].c_create_man = this.movies[i].c_create_man;
            this.allData[i].c_create_time = this.movies[i].c_create_time;
            this.allData[i].c_update_time = this.movies[i].c_update_time;
            this.allData[i].c_source = this.movies[i].c_source;
            if (this.movies[i].c_pic != null) {
              this.allData[i].c_pic =
                "https://assets.yingshinet.com/chronicles/pictures/" +
                this.movies[i].c_pic;
            }
          }
          this.allData = this.sortYear(this.allData);
        });
      this.$set(this.allData);
    },
    //上传图片钩子函数
    handleRemove(file, fileList) {
      this.image_file.length = 0;
    },
    handlePreview(file) { },
    handleChange(file, fileList) {
      this.image_file.push(file);
    },
    //批量上传年谱列表
    ListSubmit() {
      if (this.chronicle_file.length == 1) {
        let file = this.chronicle_file[0].raw;
        let pic_param = new FormData(); //form对象
        let config = {
          headers: { "Content-Type": "multipart/form-data" },
        };
        pic_param.append("file", file);
        // pic_param.append("c_figure",JSON.stringify(this.$store.state.current_figure))
        this.axios
          .post(
            "https://api.movie.yingshinet.com/chronicles/insertall/" +
            this.$store.state.current_figure +
            "/" +
            this.$store.state.user_name,
            pic_param,
            config
          )
          .then((response) => {
            if (response.status == "200") {
              this.$message({
                message: "年谱批量上传成功！",
                type: "success",
              });
              location.reload();
            } else {
              this.$message({
                message: "年谱批量上传出了点问题",
                type: "warning",
              });
            }
          });
      }
    },
    //下载示例
    downloadList() {
      window.location.href =
        "https://assets.yingshinet.com/chronicles/example.xlsx";
    },

    downloadchronicles() {
      var config = { c_create_man: this.$store.state.user_name };
      this.axios
        .post("https://api.movie.yingshinet.com/chronicles/select", config)
        .then((response) => {
          let data = response.data.c_data;
          this.getDowloadData = [];
          let obj = {};
          let str = "";
          if (data) {
            data.forEach((val, index) => {
              obj = {
                zySort: index + 1,
                figure: val.c_figure,
                content: val.c_content.replace(/\r|\n|\s/g, ""),
                source: val.c_source,
                date: val.c_date,
                creator: val.c_create_man,
                create_time: val.c_create_time,
              };
              this.getDowloadData.push(obj);
              //列标题，逗号隔开，每一个逗号就是隔开一个单元格
              str = `编号,人物,内容,来源,时间,创建人,创建时间\n`;
              //增加\t为了不让表格显示科学计数法或者其他格式
              for (let i = 0; i < this.getDowloadData.length; i++) {
                for (let item in this.getDowloadData[i]) {
                  str += `${this.getDowloadData[i][item] + "\t"},`;
                }
                str += "\n";
              }
            });
            this.downloadZyInfo = "";
            //encodeURIComponent解决中文乱码
            let uri =
              "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
            //通过创建a标签实现
            var link = document.createElement("a");
            link.href = uri;
            //对下载的文件命名
            link.download = "年谱信息.csv";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            this.$message({
              message: "您尚未录入年谱",
              type: "warning",
              duration: 1500,
              offset: 300,
            });
          }
        });
    },
    //按年份和月份时间顺序排序
    sortYear(obj) {
      return obj.sort(function (a, b) {
        let x = a["c_year"];
        let y = b["c_year"];
        if (x < y) return -1;
        else if (x > y) return 1;
        else {
          let c = a["c_month"];
          let d = b["c_month"];
          return c < d ? -1 : c > d ? 1 : 0;
        }
      });
    },
    //按修改时间排序
    sortUpdateTime(obj) {
      return obj.sort(function (a, b) {
        let x = a["c_create_time"];
        let y = b["c_create_time"];
        if (x < y) return -1;
        else return 1;
      });
    },
    async handleClose(done) {
      if (
        this.director_select != "" &&
        this.c_year != null &&
        this.c_month != null &&
        this.c_date != null &&
        this.event_content.trim() != "" &&
        this.event_source.trim() != ""
      ) {
        if (this.loading) {
          return;
        }
        this.$confirm("确定要添加事件吗？")
          .then((_) => {
            const tmpdirector = this.$store.state.current_figure;
            this.loading = true;
            this.data_string =
              this.c_year + "年" + this.c_month + "月" + this.c_date + "日";
            this.director_select = service({
              url: "https://api.movie.yingshinet.com/chronicles/insert",
              method: "post",
              data: {
                // c_year: this.date_value_two.getFullYear(),
                // c_month: this.date_value_two.getMonth() + 1,
                c_year: this.c_year,
                c_month: this.c_month,
                c_date: this.data_string,
                //传影人参数
                c_figure: this.director_select,
                c_source: this.event_source,
                c_content: this.event_content,
                c_create_man: this.director_select,
              },
            }).then((response) => {
              this.new_pic_id = response.data.id;
              if (this.image_file.length == 1) {
                let file = this.image_file[0].raw;
                let pic_param = new FormData(); //form对象
                let config = {
                  headers: { "Content-Type": "multipart/form-data" },
                };
                pic_param.append("file", file);
                this.axios
                  .post(
                    "https://api.movie.yingshinet.com/chronicles/pic/" +
                    this.new_pic_id,
                    pic_param,
                    config
                  )
                  .then((response) => {
                    if (response.data.msg == "success") {
                      this.$message({
                        message: "影人照片上传成功！",
                        type: "success",
                      });
                      location.reload();
                    } else {
                      this.$message({
                        message: "影人照片好像出了点问题",
                        type: "warning",
                      });
                    }
                  });
              } else {
                this.$message({
                  message: "添加有点问题！",
                  type: "warning",
                });
              }
              if (response.data.msg == "Success") {
                this.$store.commit("set_current_figure", tmpdirector);
                this.director = tmpdirector;
                this.director_select = tmpdirector;
                setTimeout(() => {
                  this.$message({
                    message: "添加成功！",
                    type: "success",
                  });
                }, 1000);
              } else {
                this.$message({
                  message: "添加失败！",
                  type: "warning",
                });
              }
            });
            this.timer = setTimeout(() => {
              // 动画关闭需要一定的时间
              setTimeout(() => {
                this.loading = false;
                this.dialog = false;
                this.getEvents();
              }, 400);
            }, 2000);
          })
          .catch((_) => {
            this.dialog = false;
          });
      } else {
        this.$message({
          message: "请输入时间或内容！",
          type: "warning",
        });
      }
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      clearTimeout(this.timer);
    },
    showImage(pic) {
      this.isshowimage = true;
      this.imgsrc = pic;
    },
    // 修改具体年谱的某一条
    editEventIem(eventItem) {
      this.chronicle_id = eventItem.c_id;
      this.chronicle_pic = eventItem.c_pic;
      this.eventItemContent = eventItem.content;
      this.tmpEventItemContent = eventItem.content;
      // this.update_date_string = eventItem.c_date;
      this.update_year = eventItem.c_year;
      this.update_month = eventItem.c_month;
      this.new_source = eventItem.c_source;
      this.str = eventItem.c_date.split("月")[1];
      this.str = this.str.split("日")[0];
      this.update_date = this.str;
      this.dialogEditVisible = true;
    },
    //影人编辑
    makeSure() {
      this.make_sure = true;
    },
    //编辑最终确认
    make_sure_click() {
        this.make_sure = false;
      this.dialogEditVisible = false;
      this.update_date_string =
        this.update_year +
        "年" +
        this.update_month +
        "月" +
        this.update_date +
        "日";
      //逻辑
      service({
        url: "/chronicles/update",
        method: "post",
        data: {
          cid: this.chronicle_id,
          c_content: this.tmpEventItemContent,
          c_date: this.update_date_string,
          c_year: this.update_year,
          c_month: this.update_month,
          c_figure: this.director,
          c_refer: "",
          c_source: this.new_source,
          // c_pic:this.c_pic,
        },
      })
        .then((response) => {
          if (response.data.msg == "Success") {
            this.new_pic_id = this.chronicle_id;
            if (this.image_file.length == 1) {
              let file = this.image_file[0].raw;
              let pic_param = new FormData(); //form对象
              let config = {
                headers: { "Content-Type": "multipart/form-data" },
              };
              pic_param.append("file", file);
              this.axios
                .post(
                  "https://api.movie.yingshinet.com/chronicles/pic/" +
                  this.new_pic_id,
                  pic_param,
                  config
                )
                .then((response) => {
                  if (response.data.msg == "success") {
                    this.$message({
                      message: "影人照片上传成功！",
                      type: "success",
                    });
                    location.reload();
                  } else {
                    this.$message({
                      message: "影人照片好像出了点问题",
                      type: "warning",
                    });
                  }
                });
              this.$message({
                message: "修改成功！",
                type: "success",
              });
            } else {
              this.$message({
                message: "修改成功！",
                type: "success",
              });
            }
            location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      clearTimeout(this.timer);
    },
    getAllYear() {
      this.cities = [];
      this.cities.push(this.director);
      const keyJson = {};
      keyJson.figure = this.director;
      this.axios.post(this.path + "/all_year", keyJson).then((response) => {
        this.all_year = [];
        for (let j = 0; j < response.data.all_year.length; j++) {
          this.all_year[j] = response.data.all_year[j].year;
        }
        this.year_arr = [];
        for (var i = 0; i < this.all_year.length; i++) {
          this.year_arr.push(false);
        }
        this.year_arr[0] = true;
        //获取第一个年份的所有年谱
        this.movies = response.data.searchShow;
        this.itemLength = this.movies.length;
        this.allData = [];
        for (var i = 0; i < this.itemLength; i++) {
          //获取年谱所有数据
          this.allData[i] = {};
          this.allData[i].content = this.movies[i].c_content;
          this.allData[i].c_year = this.movies[i].c_year;
          this.allData[i].c_month = this.movies[i].c_month;
          this.allData[i].c_id = this.movies[i].cid;
          this.allData[i].c_date = this.movies[i].c_date;
          this.allData[i].c_create_man = this.movies[i].c_create_man;
          this.allData[i].c_create_time = this.movies[i].c_create_time;
          this.allData[i].c_update_time = this.movies[i].c_update_time;
          this.allData[i].c_source = this.movies[i].c_source;
          if (this.movies[i].c_pic != null) {
            this.allData[i].c_pic =
              "https://assets.yingshinet.com/chronicles/pictures/" +
              this.movies[i].c_pic;
          }
        }
        this.allData = this.sortYear(this.allData);
      });
      if (this.itemLength <= 1) {
        this.axios
          .post("this.path" + "/select", { content_figure: this.director })
          .then((response) => {
            this.othermovies = response.data.c_data;
            this.otheritemLength = this.othermovies.length;
            for (
              var i = this.itemLength;
              i < this.itemLength + this.otheritemLength;
              i++
            ) {
              //获取年谱所有数据
              this.allData[i] = {};
              this.allData[i].content = this.othermovies[i].c_content;
              this.allData[i].c_year = this.othermovies[i].c_year;
              this.allData[i].c_month = this.othermovies[i].c_month;
              this.allData[i].c_id = this.othermovies[i].cid;
              this.allData[i].c_date = this.othermovies[i].c_date;
              this.allData[i].c_create_man = this.othermovies[i].c_create_man;
              this.allData[i].c_source = this.othermovies[i].c_source;
              if (this.movies[i].c_pic != null) {
                this.allData[i].c_pic =
                  "https://assets.yingshinet.com/chronicles/pictures/" +
                  this.movies[i].c_pic;
              }
            }
            this.allData = this.sortYear(this.allData);
            this.allData_by_update = JSON.parse(JSON.stringify(this.allData));
            this.allData_by_update = this.sortUpdateTime(
              this.allData_by_update
            );
          });
      }
      this.$set(this.allData);
    },
    getEvents() {
      const keyword = this.keywordSearch.trim();
      const keyJson = {
        // searchData: "山东",
        // month: 1,
        // figure: "费穆",
      };
      if (keyword) {
        keyJson.searchData = keyword;
      }
      keyJson.figure = this.director;
      this.axios.post(this.path + "/keyword", keyJson).then((response) => {
        this.movies = response.data.searchShow;
        this.cities = [];
        for (let j = 0; j < response.data.all_cities.length; j++) {
          this.cities[j] = response.data.all_cities[j].all_cities;
        }
        //年谱数据movies.c_content
        this.itemLength = this.movies.length;
        this.allData = [];
        for (var i = 0; i < this.itemLength; i++) {
          //获取年谱所有数据
          this.allData[i] = {};
          this.allData[i].content = this.movies[i].c_content;
          this.allData[i].c_year = this.movies[i].c_year;
          this.allData[i].c_month = this.movies[i].c_month;
          this.allData[i].c_id = this.movies[i].cid;
          this.allData[i].c_date = this.movies[i].c_date;
          this.allData[i].c_create_man = this.movies[i].c_create_man;
          this.allData[i].c_create_time = this.movies[i].c_create_time;
          this.allData[i].c_update_time = this.movies[i].c_update_time;
          this.allData[i].c_source = this.movies[i].c_source;
          if (this.movies[i].c_pic != null) {
            this.allData[i].c_pic =
              "https://assets.yingshinet.com/chronicles/pictures/" +
              this.movies[i].c_pic;
          }
        }
        this.allData = this.sortYear(this.allData);
        this.allData_by_update = JSON.parse(JSON.stringify(this.allData));
        this.allData_by_update = this.sortUpdateTime(this.allData_by_update);
        if (this.itemLength <= 1) {
          this.axios
            .post("this.path" + "/select", { content_figure: this.director })
            .then((response) => {
              this.othermovies = response.data.c_data;
              this.otheritemLength = this.othermovies.length;
              for (
                var i = this.itemLength;
                i < this.itemLength + this.otheritemLength;
                i++
              ) {
                //获取年谱所有数据
                this.allData[i] = {};
                this.allData[i].content = this.othermovies[i].c_content;
                this.allData[i].c_year = this.othermovies[i].c_year;
                this.allData[i].c_month = this.othermovies[i].c_month;
                this.allData[i].c_id = this.othermovies[i].cid;
                this.allData[i].c_date = this.othermovies[i].c_date;
                this.allData[i].c_create_man = this.othermovies[i].c_create_man;
                this.allData[i].c_source = this.othermovies[i].c_source;
                if (this.movies[i].c_pic != null) {
                  this.allData[i].c_pic =
                    "https://assets.yingshinet.com/chronicles/pictures/" +
                    this.movies[i].c_pic;
                }
              }
              this.allData = this.sortYear(this.allData);
              this.allData_by_update = JSON.parse(JSON.stringify(this.allData));
              this.allData_by_update = this.sortUpdateTime(
                this.allData_by_update
              );
            });
        }
      });
    },

    searchItem() {
      const keyword = this.keywordSearch.trim();
      const month = this.MonthSearch.trim();
      const keyJson = {};
      if (keyword) {
        keyJson.searchData = keyword;
      }
      if (month) {
        keyJson.month = month;
      }
      keyJson.figure = this.$store.state.current_figure;
      this.axios;
      this.axios.post(this.path + "/keyword", keyJson).then((response) => {
        this.movies = response.data.searchShow;
        //年谱数据movies.c_content
        this.cities = [];
        for (let j = 0; j < response.data.all_cities.length; j++) {
          this.cities[j] = response.data.all_cities[j].all_cities;
        }
        this.itemLength = this.movies.length;
        this.allData = [];
        for (var i = 0; i < this.itemLength; i++) {
          //获取年谱所有数据
          this.allData[i] = {};
          this.allData[i].content = this.movies[i].c_content;
          this.allData[i].c_year = this.movies[i].c_year;
          this.allData[i].c_month = this.movies[i].c_month;
          this.allData[i].c_id = this.movies[i].cid;
          this.allData[i].c_date = this.movies[i].c_date;
          this.allData[i].c_create_man = this.movies[i].c_create_man;
          this.allData[i].c_create_time = this.movies[i].c_create_time;
          this.allData[i].c_update_time = this.movies[i].c_update_time;
          this.allData[i].c_source = this.movies[i].c_source;
          if (this.movies[i].c_pic != null) {
            this.allData[i].c_pic =
              "https://assets.yingshinet.com/chronicles/pictures/" +
              this.movies[i].c_pic;
          }
        }
        this.allData = this.sortYear(this.allData);
        this.allData_by_update = JSON.parse(JSON.stringify(this.allData));
        this.allData_by_update = this.sortUpdateTime(this.allData_by_update);
        if (this.itemLength <= 1) {
          this.axios
            .post(this.path + "/select", {
              content_figure: this.$store.state.current_figure,
            })
            .then((response) => {
              this.othermovies = response.data.c_data;
              this.otheritemLength = this.othermovies.length;
              for (
                var i = this.itemLength;
                i < this.itemLength + this.otheritemLength;
                i++
              ) {
                //获取年谱所有数据
                this.allData[i] = {};
                this.allData[i].content = this.othermovies[i].c_content;
                this.allData[i].c_year = this.othermovies[i].c_year;
                this.allData[i].c_month = this.othermovies[i].c_month;
                this.allData[i].c_id = this.othermovies[i].cid;
                this.allData[i].c_date = this.othermovies[i].c_date;
                this.allData[i].c_create_man = this.othermovies[i].c_create_man;
                this.allData[i].c_source = this.othermovies[i].c_source;
              }
              this.allData = this.sortYear(this.allData);
              this.allData_by_update = JSON.parse(JSON.stringify(this.allData));
              this.allData_by_update = this.sortUpdateTime(
                this.allData_by_update
              );
            });
        }
      });
      this.$set(this.allData);
    },
    debounce(fn, wait = 1000) {
      let timer;
      return function () {
        let context = this;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          fn.apply(context);
        }, wait);
      };
    },

    searchEvents() {
      // let keyword = this.keywordSearch;
      // this.$store.commit("setkeywordSearch", keyword);
      this.debounce(this.searchItem, 300)();
    },
  },
  computed: {
    // 替换内容中的关键词为高亮形式;影人\影片实体超链接计算
    replaceArr() {
      const arr = JSON.parse(JSON.stringify(this.allData));
      const colors = this.colors;
      const keywords = this.directors;
      const keywords2 = this.movie;
      keywords.forEach((keywordItem, keywordIndex) => {
        // 匹配关键字正则
        const replaceReg = new RegExp(keywordItem, "g");
        // 高亮替换v-html值
        const replaceString = `<a  href="/#/directors?name=${keywordItem}"><font color=${colors[0]}>${keywordItem}</font></a>`;
        for (let index = 0; index < arr.length; index++) {
          if (
            // arr[index].content.search('color=') === -1 &&
            arr[index].content &&
            arr[index].content.length
          ) {
            // 开始替换
            arr[index].content = arr[index].content.replace(
              replaceReg,
              replaceString
            );
          }
        }
      });
      keywords2.forEach((keywordItem, keywordIndex) => {
        // i++;

        // 匹配关键字正则
        const replaceReg = new RegExp(keywordItem, "g");
        // 高亮替换v-html值
        // index_movie = this.movie.index(keywordItem)

        const replaceString = `<a  href="/#/video?id=${keywordIndex + 1
          }"><font color=${colors[0]}>${keywordItem}</font></a>`;
        for (let index = 0; index < arr.length; index++) {
          if (arr[index].content && arr[index].content.length) {
            // 开始替换
            arr[index].content = arr[index].content.replace(
              replaceReg,
              replaceString
            );
          }
        }
      });
      return arr;
    },
    // 替换内容中的关键词为高亮形式;影人\影片实体超链接计算
    replaceArrUpdate() {
      const arr = JSON.parse(JSON.stringify(this.allData_by_update));
      const colors = this.colors;
      const keywords = this.directors;
      const keywords2 = this.movie;
      keywords.forEach((keywordItem, keywordIndex) => {
        // 匹配关键字正则
        const replaceReg = new RegExp(keywordItem, "g");
        // 高亮替换v-html值
        const replaceString = `<a  href="/#/directors?name=${keywordItem}"><font color=${colors[0]}>${keywordItem}</font></a>`;
        for (let index = 0; index < arr.length; index++) {
          if (
            // arr[index].content.search('color=') === -1 &&
            arr[index].content &&
            arr[index].content.length
          ) {
            // 开始替换
            arr[index].content = arr[index].content.replace(
              replaceReg,
              replaceString
            );
          }
        }
      });
      keywords2.forEach((keywordItem, keywordIndex) => {
        // i++;

        // 匹配关键字正则
        const replaceReg = new RegExp(keywordItem, "g");
        // 高亮替换v-html值
        // index_movie = this.movie.index(keywordItem)

        const replaceString = `<a  href="/#/video?id=${keywordIndex + 1
          }"><font color=${colors[0]}>${keywordItem}</font></a>`;
        for (let index = 0; index < arr.length; index++) {
          if (arr[index].content && arr[index].content.length) {
            // 开始替换
            arr[index].content = arr[index].content.replace(
              replaceReg,
              replaceString
            );
          }
        }
      });
      return arr;
    },
  },
  created() {
    // 获取影片名
    this.director = this.$store.state.current_figure;
    this.axios.get(this.path_currency + "/movies").then((response) => {
      for (var i = 0; i < response.data.m_data.length; i++) {
        this.movie.push(response.data.m_data[i].m_name);
      }
    });
    //获取人物名
    this.axios.get(this.path_currency + "/figures").then((response) => {
      for (var i = 0; i < response.data.f_data.length; i++) {
        this.directors.push(response.data.f_data[i].f_name);
        this.dirctors_pic.push(response.data.f_data[i].f_pic);
      }
    });
    this.axios
      .post(this.path_currency + "/figures/select", { f_name: this.director })
      .then((response) => {
        this.currentFigureImage = response.data.f_data[0].f_pic;
      });
  },
  mounted() {
    this.director = this.$store.state.current_figure;
    this.director_select = this.director;
    // this.getEvents();
    this.getAllYear();
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 0.7vw;
}
.chronicleList {
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.chronicleList a {
  text-decoration: none;
}
.chronicleList .listSearch {
  position: static;
  margin: 2vh 0 2vh 0;
  text-align: center;
  line-height: 3vh;
  height: 9.5vh;
}
.listSearch .director_img1 {
  width: 6vh !important;
  height: 6vh !important;
  left: 1.2vw !important;
  top: 2vh !important;
  bottom: 2vh !important;
  position: fixed;
  border-radius: 50%;
}
.director_select {
  width: 15vw;
  margin-left: 1vw;
  font-size: 1.1vw;
}
.director_option {
  font-size: 1.1vw;
}
.keywordSearchInput {
  width: 45vw;
  margin-left: 1vw;
  font-size: 1.1vw;
}
.keywordSearchInput .search {
  padding: 1vw 1.8vw;
  border-radius: 0%;
}
:deep(.el-input-group__append) {
  background-color: #ffc78e;
}
.elebutton .el-backtop {
  right: 36px;
  top: 200px;
  width: 50px;
  height: 50px;
  font-size: 28px;
}
.selectfont1 {
  color: gray;
  font-size: 1.1vw;
  margin-top: 0.8vw;
  margin-left: 0.2vw;
  cursor: pointer;
}
.selectfont2 {
  color: gray;
  font-size: 3.5vw;
  margin-top: 0.8vw;
  margin-left: 0vw;
  cursor: pointer;
}
.box {
  max-height: 60vh;
}
.itemdata {
  font-size: 1vw;
  line-height: 1.5vw;
  color: #cccccc;
}
.itemfont {
  font-size: 1vw;
  line-height: 1.5vw;
  color: #a1a3a8;
}
.mouse_over_head:hover {
  color: #b8860b;
  cursor: pointer;
}

/* 媒体查询 */
@media (max-width: 768px) {
  .chronicleList .listSearch {
    height: 7.2vh;
  }
  .listSearch .director_img1 {
    width: 5vh !important;
    height: 5vh !important;
    left: 1vh !important;
    top: 1vh !important;
    bottom: 1vh !important;
  }
  .director_select {
    width: 25vw;
    top: 0.5vw;
    font-size: 2vw;
  }
  .director_option {
    font-size: 4vw;
  }
  .keywordSearchInput {
    width: 35vw;
    margin-left: 1vw;
    font-size: 2vw;
  }
  .keywordSearchInput .search {
    padding: 4.5vw 4.5vw;
  }
  .box {
    max-height: 32vh;
  }
  .itemdata {
    font-size: 3.5vw;
    line-height: 5vw;
  }
  .itemfont {
    font-size: 3.5vw;
    line-height: 5vw;
  }
}

/* 似乎没有用到的 */
.chronicle_e_input {
  border-radius: 0.3vw;
  border: 0.07vw solid #b8860b;
  color: #606266;
  height: 5vw;
  line-height: 5vw;
  padding-left: 0.3vw;
  outline: none;
}
.el-col .chronicleList>.el-card {
  margin: 0.5vw;
}
.listSearch .el-card__body {
  padding: 0.2vw;
}
.el-timeline {
  width: 100%;
  padding-inline-start: 0vw;
}
.chronicleList .timeline {
  height: 77vh;
  overflow: scroll;
}
.el-timeline .el-timeline-item {
  padding-bottom: 0vw;
}
.infinite-list .yearformcard {
  height: 8vw;
  overflow: scroll;
}
.yearformcard .el-card__body {
  padding: 0.8vw;
}
.yearformcard p {
  margin: 0vw;
}
.chronicleList .el-card__body {
  padding: 0.5vw;
}
.chronicleList .el-timeline-item__tail,
.el-timeline-item__node {
  margin-left: 0.5vw;
}
.chronicleList .el-timeline-item__wrapper {
  padding-left: 1.3vw;
}

.director_img2 {
  width: 3vw !important;
  height: 3vh !important;
  right: 1vw !important;
  position: absolute;
  border-radius: 10%;
}
.el-scrollbar__thumb {
  background: rgb(26, 9, 9);
}
.timeline_order .el-tabs__item:hover {
  color: #b8860b;
}
.timeline_order .el-tabs__item.is-active {
  color: #b8860b;
}
.timeline_order .el-tabs__active-bar {
  background: #b8860b;
  width: 30px;
}
.el-card {
  background-color: #606266;
  border: 1px solid #a1a3a8;
}
::v-deep .el-button:focus, .el-button:hover {
    color: #e2a253 !important;
    border-color: #e2a253 !important;
    background-color: #fff6ec !important;
}
</style>