<template>
  <div style="padding: 0.4vw">
    <div align="center" style="margin-top: 0.78vw">
      <div style="width: fit-content">
        <span style="font-size: 4vw; color: #F0EBE1; font-family: STsong; font-weight: bold;" align="center">
          {{ this.dir_name }}
        </span>
        <div style="height: 0.47vw; background-color: #E2A253"></div>
        <div style="height: 0.16vw; background-color: #E2A253; margin-top: 0.16vw"></div>
        <div style="font-size: 1.72vw; color: #F0EBE1; font-family: STsong; font-weight: bold;">
          {{ this.brief }}
        </div>
      </div>
      <span class="elebutton">
        <el-button class="el-backtop" style="color: #b8860b; box-shadow: 0.23vw 0.23vw 0.78vw #aaaaaa" 
          icon="el-icon-plus" @click="dialog = true" circle v-if="this.$store.state.login_state">
        </el-button>
      </span>
    </div>

    <el-row :gutter="8" style="margin-top: 1.56vw">
      <el-col :span="(window_width>768? 18:24)">
        <!-- 影人信息子组件 -->
        <director-info v-bind:movie_pics="movie_pics" v-bind:url="url" v-bind:srcList="srcList"
          v-bind:dir_name="dir_name" v-bind:entity_direction="entity_link" v-bind:direction="direction"
          v-bind:brief="brief" v-bind:essays="essays" v-bind:dir_id="dir_id" v-bind:birth_time="birth_time"
          v-bind:dir_sex="dir_sex" v-bind:spouse="spouse" v-bind:recommend="recommend" v-bind:showworks="true">
        </director-info>
      </el-col>
      <!-- PC端 右布局 -->
      <el-col v-show="window_width>768" :span="6">
        <div>
          <!-- 影人列表 -->
          <el-card style="box-shadow: 0px 0px 1px -2px rgb(0 0 0 /16%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
            background-color: rgba(255, 255, 255, 0.1)">
            <div class="el_card" slot="header" style="height: 5.2vw">
              <el-input class="e-input" placeholder="搜索影人" v-model="director_search" style="width: 80%; margin: 0.78vw 0vw">
              </el-input>
            </div>
            <div style="height: 51.2vw ; overflow-y: auto;">
              <el-row>
                <el-col class="text_item" :span="8" v-for="(director, index) in search_data" :key="index">
                  <span class="mouse_over" @click="directorClick(director)">
                    {{ director }}
                  </span>
                </el-col>
              </el-row>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>

    <!-- 编辑页面 -->
    <el-dialog class="el-dia" title="请添加影人信息：" :visible.sync="dialog" width="100%" height="100%" fullscreen>
      <el-dialog title="提示" :visible.sync="make_sure_add" width="50%" append-to-body>
        <span>确定要添加信息？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="make_sure_add = false">取 消</el-button>
          <el-button type="warning" @click="make_sure_addclick">确 定</el-button>
        </span>
      </el-dialog>
      <el-upload class="upload-demo" ref="upload" list-type="picture" action="alert" :limit="limit_num"
        :on-preview="handlePreview" :on-remove="handleRemove" :on-change="handleChange" :auto-upload="false"
        style="margin: 15px 0">
        <el-button slot="trigger" size="medium" type="warning">点击上传影人图片</el-button>
        <div slot="tip" class="el-upload__tip" style="margin-left:3px">
          支持上传jpg/jpeg/png文件，且不超过1mb
        </div>
      </el-upload>
      <el-form>
        <el-form-item label="影人" width="100px" style="margin-top:5px">
          <el-input v-model="director_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="诞辰" width="100px" style="margin-top:5px; width:30%; display:inline-block">
          <el-date-picker v-model="director_brith_time" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
            value-format="yyyy年MM月dd日" style="width:100%"></el-date-picker>
        </el-form-item>

        <el-form-item label="配偶" width="100px" style="margin:5px 0 0 30px; width:30%; display:inline-block">
          <el-input v-model="director_spouse" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="性别" width="100px" style="margin:5px 0 0 30px; width:20%; display:inline-block">
          <el-radio-group v-model="director_sex">
            <el-radio label="男"></el-radio>
            <el-radio label="女"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="简介" width="100px" style="margin-top:5px">
          <el-input v-model="director_brief" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="详述" width="100px" style="margin-top:5px">
          <el-input type="textarea" :rows="8" v-model="dir_direction" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="warning" @click="add_director()">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 移动端 下布局 -->
    <el-col v-show="window_width < 768" :span="24">
      <div>
        <!-- 影人列表 -->
        <el-card style="box-shadow: 0px 0px 1px -2px rgb(0 0 0 /16%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%); 
          background-color: #b8870b10; margin-bottom: 2vw;">
          <div class="el_card" slot="header" style="height: 12vw">
            <el-input class="e-input" placeholder="搜索影人" v-model="director_search" style="width: 80%; margin: 0.2vw 0vw">
            </el-input>
          </div>
          <div style="height: 50vw ; overflow-y: auto;">
            <el-row>
              <el-col class="text_item" :span="4" v-for="(director, index) in search_data" :key="index">
                <span class="mouse_over" @click="directorClick(director)">
                  {{ director }}
                </span>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </div>
    </el-col>
  </div>
</template>

<script>
import DirectorInfo from "../components/director/director_info.vue";
import service from "../utils/request";
export default {
  components: {
    DirectorInfo,
  },
  data() {
    return {
window_width:window.screen.width,
      default_name: "费穆",
      director_sorted: [],
      directors: [],
      dir_name: "",
      direction: "",
      brief: "",
      essays: [],
      dir_id: 0,
      birth_time: "",
      dir_sex: "",
      spouse: "",
      recommend: "",
      director_search: "",
      movie_pics: [],
      movie_names: [],
      limit_num: 1,
      url: "",
      srcList: [],
      path: "https://api.movie.yingshinet.com",
      image_file: [],
      dialog: false,
      director_name: "",
      director_brith_time: "",
      director_spouse: "",
      director_sex: "",
      director_brief: "",
      dir_direction: "",
      make_sure_add: false,
      new_pic_id: "",
      color: "#b8860b",
      window_width:window.screen.width,
    };
  },
  computed: {
    // 搜索
    search_data: {
      get() {
        if (!this.director_search.trim()) {
          return this.director_sorted;
        }
        return this.director_sorted.filter(
          (data) =>
            data &&
            data
              .toLowerCase()
              .includes(this.director_search.trim().toLowerCase())
        );
      },
    },
    // 实体超链接
    entity_link() {
      let driection_link = this.direction;
      this.movie_names.forEach((movie_name) => {
        const replaceReg = new RegExp("《" + movie_name.mname + "》", "g");
        const replaceString = `<a href="/#/video?id=${movie_name.mid}" style="text-decoration:none"><font color=${this.color}>《${movie_name.mname}》</font></a>`;
        driection_link = driection_link.replace(replaceReg, replaceString);
      });
      // this.directors.forEach((director)=>{
      //   const replaceReg = new RegExp(director, "g");
      //   const replaceString = `<a href="/#/directors?name=${director}" style="text-decoration:none"><font @click="director_click(${director})" color=${this.color}>${director}</font></a>`;
      //   driection_link = driection_link.replace(replaceReg, replaceString)
      // })
      return driection_link;
    },
  },
  methods: {
    //按字母顺序排序
    sortChinese(obj) {
      obj.sort(function (item1, item2) {
        return item1.localeCompare(item2, 'zh-CN');// localeCompare为string内置函数
      })
    },
    // 显示确认添加弹窗
    add_director() {
      console.log(this.director_brith_time);
      this.make_sure_add = true;
    },
    // 确认添加
    make_sure_addclick() {
      this.make_sure_add = false;
      this.dialog = false;

      //信息上传
      service({
        url: "/figures/insert",
        method: "post",
        data: {
          f_name: this.director_name,
          birth_time: this.director_brith_time,
          f_work: this.dir_direction,
          f_brief: this.director_brief,
          sex: this.director_sex,
          spouse: this.director_spouse,
        },
      }).then((response) => {
        if (response.data.msg == "insert success") {
          this.new_pic_id = response.data.id;
          this.$message({
            message: "添加成功！",
            type: "success",
          });
          // 图片上传
          if (this.image_file.length == 1) {
            let file = this.image_file[0].raw;
            let pic_param = new FormData(); //form对象
            let config = {
              headers: { "Content-Type": "multipart/form-data" },
            };
            pic_param.append("file", file);
            this.axios
              .post(
                "https://api.movie.yingshinet.com/figures/pic/" +
                this.new_pic_id,
                pic_param,
                config
              )
              .then((response) => {
                if (response.data.msg == "success") {
                  this.$message({
                    message: "影人照片上传成功！",
                    type: "success",
                  });
                  location.reload();
                } else {
                  this.$message({
                    message: "影人照片好像出了点问题",
                    type: "warning",
                  });
                }
              });
          }
        } else {
          this.$message({
            message: "添加有点问题！",
            type: "warning",
          });
        }
      });
    },
    //上传图片钩子函数
    handleRemove(file, fileList) {
      this.image_file.length = 0;
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleChange(file, fileList) {
      this.image_file.push(file)
    },
    // 影人点击
    directorClick(director) {
      this.essays.length = 0;
      this.$store.commit('set_current_figure', director)
      history.pushState(null, null, 'https://movie.yingshinet.com/#/directors?=' + director)
      // history.pushState(null, null, 'http://localhost:8080/#/directors?=' + director)
      //逻辑操作
      this.axios
        .post(this.path + "/figures/select", { f_name: director })
        .then((response) => {
          this.dir_name = response.data.f_data[0].f_name;
          this.birth_time = response.data.f_data[0].birth_time;
          this.url = response.data.f_data[0].f_pic;
          this.direction = response.data.f_data[0].f_work;
          this.brief = response.data.f_data[0].f_brief;
          this.dir_id = response.data.f_data[0].fid;
          this.dir_sex = response.data.f_data[0].sex;
          this.spouse = response.data.f_data[0].spouse;
          this.recommend = response.data.f_data[0].f_delete;
        });
      this.movie_pics.length = 0;
      let count = 0;
      this.axios.get(this.path + "/movies").then((response) => {
        for (var i = 0; i < response.data.m_count; i++) {
          if (response.data.m_data[i].label == "推荐" && count < 8) {
            if (
              response.data.m_data[i].m_director.indexOf(director) != -1 ||
              response.data.m_data[i].m_actor.indexOf(director) != -1
            ) {
              this.movie_pics.push(response.data.m_data[i]);
              count++;
            }
          }
        }
      });
      this.axios
        .post(this.path + "/essays/select", { writer: this.$store.state.current_figure,divide_page:1,page: this.currentPage,offset:8})
        .then((response) => {
          for (var i = 0; i < response.data.e_data.length; i++) {
            if (response.data.e_data[i].label == "推荐") {
              this.essays.push(response.data.e_data[i]);
            }
          }
        });
    },
    // 获取传输数据
    GetName() {
      var url = window.location.href; //获取url中"?"符后的字串
      console.log(url);
      var param = [];
      if (url.split("?")[1] != null) {
        param = url.split("?")[1].split("=");
      }
      return decodeURI(param[1]);
    },
  },

  mounted() {
    console.log(this.$store.state.current_figure);
    if (this.GetName() != this.default_name && this.GetName() != "undefined") {
      this.default_name = this.GetName();
      this.$store.commit("set_current_figure", this.default_name);
    } else {
      this.default_name = this.$store.state.current_figure;
    }

    // 获取一系列数据
    this.axios.get(this.path + "/figures").then((response) => {
      for (var i = 0; i < response.data.f_data.length; i++) {
        this.directors.push(response.data.f_data[i].f_name);
      }
      this.director_sorted = JSON.parse(JSON.stringify(this.directors))
      this.sortChinese(this.director_sorted);
    });
    console.log("查询的人名")
    console.log(this.default_name)

    this.axios
      .post(this.path + "/figures/select", { f_name: this.default_name })
      .then((response) => {
        this.dir_name = response.data.f_data[0].f_name;
        this.birth_time = response.data.f_data[0].birth_time;
        this.url = response.data.f_data[0].f_pic;
        this.direction = response.data.f_data[0].f_work;
        this.brief = response.data.f_data[0].f_brief;
        this.dir_id = response.data.f_data[0].fid;
        this.dir_sex = response.data.f_data[0].sex;
        this.spouse = response.data.f_data[0].spouse;
        this.recommend = response.data.f_data[0].f_delete;
      });

    let count = 0;
    this.axios.get(this.path + "/movies").then((response) => {
      this.movie_names = [];
      for (var i = 0; i < response.data.m_count; i++) {
        this.movie_names[i] = {};
        this.movie_names[i].mid = response.data.m_data[i].mid;
        this.movie_names[i].mname = response.data.m_data[i].m_name;
        if (response.data.m_data[i].label == "推荐" && count < 8) {
          if (
            response.data.m_data[i].m_director.indexOf(this.default_name) !=
            -1 ||
            response.data.m_data[i].m_actor.indexOf(this.default_name) != -1
          ) {
            this.movie_pics.push(response.data.m_data[i]);
            count++;
          }
        }
      }
    });
    this.axios
      .post(this.path + "/essays/select", { writer: this.$store.state.current_figure,divide_page:1,page: this.currentPage,offset:8})
      .then((response) => {
        this.essays.length=0;
        for (var i = 0; i < response.data.e_data.length; i++) {
          this.essays.push(response.data.e_data[i]);
        }
      });
    // this.directorClick(this.director)
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}

.elebutton .el-backtop {
  position: absolute;
  bottom: 73vh !important;
  right: 3vw !important;
}
.e-input {
  text-align: left;
  border-radius: 0.4vw;
  color: #606266;
  height: 3.12vw;
  font-size: 1.25vw;
  line-height: 3.12vw;
  padding-left: 0.8vw;
  outline: none;
}
.text_item {
  font-size: 1.2vw;
  margin-bottom: 1vw;
  margin-right: 1vw;
  color: #cccccc;
}
.mouse_over:hover {
  color: #b8860b;
  cursor: pointer;
}


/* 媒体查询 */
@media (max-width: 768px) {
  /* .header .dir_name {
    font-size: 60vw;
  } */
  .e-input {
    font-size: 3vw;
    padding-left: 8vw;
  }
  .text_item {
    font-size: 3vw;
    padding-left: 3vw;
    margin-bottom: 1.5vw;
    margin-right: 2vw;
  }
}


/* 似乎没有用到 */
.el-card .el-card__header {
  padding: 8px 20px;
}
.back_image {
  background-image: url("/static/back1.jpg");
  background-size: cover;
}

:deep(.el-card__body) {
  padding: 0.5vw;
}
</style>
